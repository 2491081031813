import { FC, useEffect, useState } from "react";
import Button from "../forms/inputs/button";
import { useNavigate, useParams } from "react-router-dom";
import MeasurementDefinitionSelect from "./measurementDefinitionSelect";
import { useTranslation } from "react-i18next";
import DefaultForm from "../forms/defaultForm";
import styles from './createMeasurementDefinition.module.css';

const CreateMeasurementDefinition: FC = () => {
  const [selectInputCount, setSelectInputCount] = useState<any>([]);
  const [selectValues, setSelectValues] = useState<any>([]);
  const [predefinedTypeSelectValues, setPredefinedTypeSelectValues] =
    useState<any>([]);

  const [visible, setVisible] = useState<boolean>(false);
  const [templateUsage, setTemplateUsage] = useState<any>([]);

  const { measurementDefinitionId } = useParams() as {
    measurementDefinitionId: string;
  };
  const [isDisabled, setIsDisabled] = useState<any>(false);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const unitOptions = [
    { label: " - ", value: "" },
    { label: "Centimeter", value: "cm" },
    { label: "Kilogramm", value: "Kg" },
    { label: "Gramm", value: "g" },
    { label: "Grad Celsius", value: "c" },
    { label: "Prozent", value: "percent" },
  ];

  const typeOptions = [
    { label: "Ja/Nein", value: "bool" },
    {
      label: "Ja/Nein/Keine Angabe",
      value: "boolNoStatement",
    },
    { label: "Auswahl", value: "choice" },
    { label: "Mehrfachauswahl", value: "multiselect" },
    { label: "Datum", value: "date" },
    { label: "Kommazahl", value: "float" },
    { label: "Zahl", value: "int" },
    { label: "Text", value: "string" },
    { label: "mehrzeiliger Text", value: "text" },
  ];

  const tooltip = (
    <ul className={styles.tooltipList}>
      <li>{t("use_lowercase")}</li>
      <li>{t("use_no_umlauts")}</li>
      <li>
        {t("use_no_spaces")}
        <br /> {t("use_underscore")}
      </li>
    </ul>
  );

  // get Data from DefaultForm; If Edit/MeasurementDefinition setValues
  const prepareMeasurementDefinitionData = (data: any) => {
    if (data.length !== 0) {
      if (measurementDefinitionId) {
        let typeSelectOptions: any = [];
        const selectValues = data.value_map;

        if (Array.isArray(selectValues) && selectValues.length !== 0) {
          typeSelectOptions = selectValues.map((obj: any) => {
            const [label, value] = Object.entries(obj)[0];

            return { value: value, label: label };
          });

          setVisible(true);
        }
        setPredefinedTypeSelectValues(typeSelectOptions);
        setTemplateUsage(data.template_usage);
      }
    }
  };
  const preSubmitHandler = (data: any) => {
    const arrayToList = selectValues
      .sort((a: any, b: any) => a.id.localeCompare(b.id))
      .map(({ label, value }: any) =>
        value ? { [label]: value } : { [label]: label }
      );

    const reuseResult = () => {
      if (data.reuse === "yes") {
        return true;
      }

      return false;
    };

    const result = {
      name: data.name.toLocaleLowerCase(),
      label: data.label,
      short_label: data.short_label,
      type: data.type,
      unit:
        data?.unit !== undefined || data?.unit === 0 || data?.unit === "0"
          ? data?.unit
          : "",
      reuse: reuseResult(),
      value_map: arrayToList,
      sample_data: data?.sample_data,
      observation: data?.observation,
      assessment: data?.assessment,
      dangerous_answer: data?.dangerous_answer,
      possible_remedy: data?.possible_remedy,
      danger_potential: data?.danger_potential,
    };

    return result;
  };

  // add inputs if type === choice | multiselect
  const addSelectInput = () => {
    setSelectInputCount(
      selectInputCount.concat([
        {
          id: String(selectInputCount.length),
        },
      ])
    );
  };
  // delete inputs if type === choice | multiselect
  const deleteSelectedInput = (
    id: string | undefined,
    skipQuestion = false
  ) => {
    const item = selectValues.filter((item: any) => item.id === id);

    const newItems = selectInputCount.filter((item: any) => item.id !== id);
    const newValues = selectValues.filter((item: any) => item.id !== id);

    const label = item.length !== 0 ? item[0].label : "den Eintrag";

    if (!skipQuestion) {
      if (
        /*eslint-disable */
        !confirm(t("confirm_delete_label", { label }))
        /*eslint-enable */
      ) {
        return;
      }
    }
    setSelectInputCount(newItems);
    setSelectValues(newValues);
  };
  // handling of selectedInputs if chosen Type === choice|Multiselect
  const handleSelectValues = (
    id: string | undefined | any,
    value: string,
    label: string
  ) => {
    selectValues[id] = { value: value, label: label, id: id };
    setSelectValues(selectValues);
  };
  // set selectInputValues if edit and type === choice | multiselect
  useEffect(() => {
    if (predefinedTypeSelectValues !== undefined) {
      const items: any = [];
      for (const key in predefinedTypeSelectValues) {
        const index = key;
        const defaultLabel = predefinedTypeSelectValues[key].label;
        const defaultValue = predefinedTypeSelectValues[key].value;
        items.push({
          id: index,
          predefinedLabel: defaultLabel,
          predifinedValue: defaultValue,
        });
        setSelectInputCount(items);
      }
    }
  }, [predefinedTypeSelectValues]);

  const customVisibilityChange = (data: any) => {
    if (
      data.target.value.value === "choice" ||
      data.target.value.value === "multiselect"
    ) {
      setVisible(true);
    } else {
      setVisible(false);
    }
  };
  //visualize Templates in which the MD is already used
  const renderTemplates = () => {
    if (templateUsage.length !== 0) {
      return (
        <div className={styles.templateList}>
          <h5>Templates</h5>
          <ul>
            {templateUsage.map((item: any) => (
              <li key={item.name}>{item.name}</li>
            ))}
          </ul>
        </div>
      );
    }
  };
  const additionalUrlParameters: any = {};
  additionalUrlParameters["with_template_usage"] = "true";
  additionalUrlParameters["test_for_measurement_usage"] = "true";

  const isEditAllowed = (data: any) => {
    if (data.is_used_in_measurement) {
      setIsDisabled(true);
    }
  };

  return (
    <>
      <DefaultForm
        withPaper={true}
        entity="measurementDefinition"
        entityId={measurementDefinitionId}
        targetEntity="measurementDefinition"
        targetEntityId={measurementDefinitionId}
        dataCallback={prepareMeasurementDefinitionData}
        preSubmitHandler={preSubmitHandler}
        cancelButton={true}
        onSuccessHandler={() => navigate("/admin/measurementDefinitionList")}
        additionalUrlParameters={additionalUrlParameters}
        //--------------------------------
        onCancelFormHandler={() => navigate("/admin/measurementDefinitionList")}
        isEditAllowed={isEditAllowed}
        input={[
          {
            name: "name",
            label: t("name"),
            pattern: /^[a-z0-9_]*$/,
            autoComplete: "off",
            tooltip: true,
            tooltipMessage: tooltip,
            placement: "right",
            error: "error",
            // readOnly: true,
            disabled: isDisabled,
            required: true,
            errorMessageValidation: t(
              "error_msg_invalid_characters_change_your_input"
            ),
          },
          {
            name: "label",
            label: t("description"),
            autoComplete: "off",
            required: true,
            error: "error",
            errorMessageRequired: t("error_msg_please_add_description"),
          },
          {
            name: "short_label",
            label: t("short_label"),
            autoComplete: "off",
            required: false,
            error: "error",
            // errorMessageRequired: t("error_msg_please_add_description"),
          },
          {
            type: "choice",
            name: "type",
            label: t("type"),
            options: typeOptions,
            isClearable: true,
            required: true,
            onDropdownChange: customVisibilityChange,
            errorMessageRequired: t("error_msg_please_select_type"),
            error: "error",
          },
          {
            type: "custom",
            name: "add_choice",
            label: "add_choice",
            visible: visible,
            customElement: (
              <>
                <Button
                  title={t("add_choice")}
                  onClick={addSelectInput}
                  className={styles.addSelectInputButton}
                />
                <div className={styles.selectInputWrapper}>
                  {selectInputCount.map((item: any) => (
                    <MeasurementDefinitionSelect
                      id={item.id}
                      key={item.id}
                      onChange={handleSelectValues}
                      predefinedLabel={item.predefinedLabel}
                      predefinedValue={item.predifinedValue}
                      onDelete={deleteSelectedInput}
                    />
                  ))}
                </div>
              </>
            ),
          },
          {
            type: "choice",
            name: "unit",
            label: t("unit"),
            options: unitOptions,
            isClearable: true,
          },
          {
            type: "bool",
            name: "reuse",
            label: t("reuse"),
          },
          {
            name: "sample_data",
            label: t("sample_data"),
            autoComplete: "off",
            required: false,
            error: "error",
          },
          {
            type: "text",
            name: "observation",
            label: t("observation") + " (" + t("for_observations") + ")",
            autoComplete: "off",
            required: false,
            error: "error",
          },
          {
            type: "text",
            name: "assessment",
            label: t("assessment") + " (" + t("for_assessments") + ")",
            autoComplete: "off",
            required: false,
            error: "error",
          },
          {
            type: "choice",
            name: "dangerous_answer",
            label:
              t("dangerous_answer") +
              " (" +
              t("for_observations_and_assessments") +
              ")",
            required: false,
            error: "error",
            options: [
              { label: t("yes"), value: "yes" },
              { label: t("no"), value: "no" },
            ],
          },
          {
            type: "text",
            name: "possible_remedy",
            label:
              t("possible_remedy") +
              " (" +
              t("for_observations_and_assessments") +
              ")",
            autoComplete: "off",
            required: false,
            error: "error",
          },
          {
            type: "choice",
            name: "danger_potential",
            label:
              t("danger_potential") +
              " (" +
              t("for_observations_and_assessments") +
              ")",
            required: false,
            error: "error",
            options: [
              { label: t("low"), value: "low" },
              { label: t("mid"), value: "mid" },
              { label: t("high"), value: "high" },
            ],
          },
        ]}
      />
      {renderTemplates()}
    </>
  );
};

export default CreateMeasurementDefinition;
