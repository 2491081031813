import styles from "./dndDeleteModal.module.css";

interface Props {
  showModalConfirmation?: {
    id?: string | null;
    label?: string;
    show?: boolean;
  };
  onHandleDelete?: () => void;
  onHandleClose?: () => void;
}

export function DeleteModal(props: Props) {
  return (
    <div className={styles.deleteModalWrapper}>
      <p>
        Wollen Sie {props?.showModalConfirmation?.label} wirklich
        <span className={styles.spanStyle}> löschen</span>?
      </p>
      <div className={styles.buttonWrapper}>
        <button onClick={props.onHandleDelete}>Löschen</button>
        <button onClick={props.onHandleClose}>Abbrechen</button>
      </div>
    </div>
  );
}
