import { PayloadAction, createAction, createSlice } from "@reduxjs/toolkit";
const clearAction = createAction("clear");

export interface AppointmentDashboardState {
  selectedDashboardDate: any;
}
const initialState: AppointmentDashboardState = {
  selectedDashboardDate: new Date().toISOString(),
};

const appointmentDashboardSlice = createSlice({
  name: "appointmentDashboard",
  initialState,
  reducers: {
    setAppointmentDashboard: (
      state,
      action: PayloadAction<{
        selectedDashboardDate: any;
      }>
    ) => {
      state.selectedDashboardDate = action.payload.selectedDashboardDate;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(clearAction, () => initialState);
  },
});

export const { setAppointmentDashboard: setAppointmentDashboard } =
  appointmentDashboardSlice.actions;
export default appointmentDashboardSlice.reducer;
