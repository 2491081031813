import { Grid, IconButton } from "@mui/material";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import CreateNewFolderIcon from "@mui/icons-material/CreateNewFolder";
import FolderDeleteIcon from "@mui/icons-material/FolderDelete";
import DriveFileMoveIcon from "@mui/icons-material/DriveFileMove";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import CancelIcon from "@mui/icons-material/Cancel";
import "./documents.css";
import { IFolderProps } from "../../types/FolderItem";
import styles from "./folderButtons.module.css";

interface FolderButtonProps {
  item: IFolderProps;
  openEditModal?: (item: IFolderProps) => void;
  disabled?: boolean;
  openCreateSubFolderModal?: (item: IFolderProps) => void;
  deleteFolder?: (item: IFolderProps) => void;
  className?: string;
}

export function FolderButtons(props: FolderButtonProps) {

  let editButton = <></>;
  editButton = (
    <IconButton
      className={styles.editButton}
      onClick={() => props.openEditModal?.(props.item)}
    >
      <EditOutlinedIcon fontSize="small" />
    </IconButton>
  );

  let createFolderButton = <></>;
  createFolderButton = (
    <IconButton
      className={styles.createButton}
      color="success"
      type="button"
      disabled={props.disabled}
      onClick={() => props.openCreateSubFolderModal?.(props.item)}
    >
      <CreateNewFolderIcon fontSize="small" />
    </IconButton>
  );
  let deleteFolderButton = <></>;
  if (props.item.name !== "upload" && !props.item.children) {
    deleteFolderButton = (
      <IconButton
        className={styles.deleteButton}
        color="error"
        type="button"
        onClick={() => props.deleteFolder?.(props.item)}
      >
        <FolderDeleteIcon fontSize="small" />
      </IconButton>
    );
  }

  return (
    <Grid item className={props.className}>
      {editButton}
      {createFolderButton}
      {deleteFolderButton}
    </Grid>
  );
}

interface MoveFolderProps {
  adminMode?: boolean;
  subjectId?: string;
  moveFolderSource?: IFolderProps[] | any;
  source?: string;
  item: IFolderProps;
  subFolderIds?: string[];
  cancelFolderMove?: () => void;
  handleMoveFolderSource?: (item: any) => void;
  handleMoveFolderTarget?: (item: any) => void;
  className?: string;
}

export function MoveFolderButton(props: MoveFolderProps) {

  let moveFolderButton = <></>;
  if (props.adminMode || props.subjectId) {
    let icon = <DriveFileMoveIcon fontSize="small" />;
    if (props?.moveFolderSource?.length !== 0 || props.source === "document") {
      icon = <ArrowCircleLeftIcon fontSize="small" />;
    }

    if (props?.subFolderIds?.includes(props.item.id)) {
      moveFolderButton = <></>;
    } else if (props.moveFolderSource.id === props.item.id) {
      moveFolderButton = (
        <IconButton
          className={styles.moveButton}
          onClick={props.cancelFolderMove}
        >
          <CancelIcon fontSize="small" color="error" />
        </IconButton>
      );
    } else {
      moveFolderButton = (
        <IconButton
          onClick={function () {
            // if (props.source === "document") {
            //   if (props.moveFileSource !== 0) {
            //     return props.handleMoveFileTarget(props.item);
            //   }
            // }
            if (props.moveFolderSource.length === 0) {
              return props?.handleMoveFolderSource?.(props.item);
            } else {
              return props.handleMoveFolderTarget?.(props.item);
            }
          }}
          className={styles.moveButton}
        >
          {icon}
        </IconButton>
      );
    }
  }

  return (
    <Grid item className={props.className}>
      {moveFolderButton}
    </Grid>
  );
}
