import { Controller } from "react-hook-form";
import Select from "react-select";
import CustomErrorMessage from "./customErrorMessage";
import InputTooltip, { placement } from "../../general/tooltip";
import styles from './dropdownWithRef.module.css';

interface Props {
  tooltip?: boolean;
  placement?: placement;
  tooltipMessage?: any;
  options?: any;
  isMulti?: any;
  name?: any;
  control?: any;
  required?: any;
  label?: any;
  mandatory?: any;
  disabled?: any;
  isSearchable?: any;
  isClearable?: any;
  onChange?: any;
  onBlur?: any;
  error?: any;
  errorMessage?: any;
  id?: any;
  hiddenOption?: any;
  readOnly?: any;
}
export const DropdownWithRef = (props: Props) => {
  const findOptionForValue = (value: any) => {
    for (const key in props.options) {
      const option = props.options[key];
      if (value === option.value) {
        return option;
      }
    }
  };

  const getValues = (value: any) => {
    if (typeof value === "string") {
      return findOptionForValue(value);
    } else {
      const multiValues = [];
      if (props.isMulti) {
        for (const key in value) {
          const option = findOptionForValue(value[key]);
          if (option) {
            multiValues.push(option);
          }
        }
        return multiValues;
      } else if (!props.isMulti) {
        let option;
        for (const key in value) {
          option = findOptionForValue(value[key]);
        }

        return option;
      }
    }
  };

  const handleChange = (event: any, onChange: any) => {
    const valueArray = [];
    if (props.isMulti) {
      for (const key in event) {
        const value = event[key].value;
        valueArray.push(value);
      }
      onChange(valueArray);
    } else if (!props.isMulti) {
      onChange(event.value);
    }
  };

  return (
    <Controller
      name={props.name}
      control={props.control}
      rules={{ required: props.required }}
      render={({
        field: { onChange, value, ...rest },
        fieldState: { error },
      }) => (
        <div className={styles.dropdownContainer}>
          <label className={styles.dropDownWithRefLabel}>
            {props.label}
            {props.required === true && (
              <span className={styles.labelRequired}>{"\u{002A}"}</span>
            )}
            {props.tooltip && (
              <InputTooltip
                tooltipMessage={props.tooltipMessage}
                placement={props.placement}
              />
            )}
          </label>
          <Select
            {...rest}
            value={getValues(value)}
            // required={props.required}
            options={props.options}
            isMulti={props.isMulti}
            isDisabled={props.disabled || props.readOnly}
            isSearchable={props.isSearchable}
            isClearable={props.isClearable}
            onChange={(event) => {
              handleChange(event, onChange);

              props.onChange?.({
                target: { id: props.name, name: props.name, value: event },
              });
            }}
            id={props.name}
            onBlur={props.onBlur}
            menuPortalTarget={document.body}
            styles={{
              clearIndicator: (base) => ({
                ...base,
                color: "#ba78de",
                backgroundColor: "#f4f2f2",
                borderRadius: "0.75rem",
                marginRight: "0.3rem",
                "&:hover": {
                  color: "#8c1ec8",
                },
              }),
              dropdownIndicator: (base) => ({
                ...base,
                color: "#ba78de",
                backgroundColor: "#f4f2f2",
                borderRadius: "0.75rem",
                margin: "0 0.3rem",
                "&:hover": {
                  color: "#8c1ec8",
                },
              }),
              loadingIndicator: (base) => ({
                ...base,
                color: "#ba78de",
              }),
              input: (baseStyles) => ({
                ...baseStyles,
                margin: "0",
                padding: "0",
              }),
              valueContainer: (baseStyles) => ({
                ...baseStyles,
                padding: "0.75rem",
                borderRadius: "0.5rem",
              }),
              menuPortal: (base) => ({ ...base, zIndex: 9999 }),

              placeholder: (styles) => ({
                ...styles,
              }),
              option: (styles, { isDisabled, isFocused, isSelected }) => {
                return {
                  ...styles,
                  backgroundColor: isDisabled
                    ? undefined
                    : isSelected
                      ? "#f4f2f2"
                      : isFocused
                        ? "#a34bd3"
                        : undefined,
                  color: isDisabled
                    ? undefined
                    : isSelected
                      ? "black"
                      : isFocused
                        ? "white"
                        : undefined,

                  cursor: isDisabled ? "not-allowed" : "default",
                };
              },
            }}
          />
          {error && (
            <CustomErrorMessage
              error={props.error}
              errorMessage={props.errorMessage}
            />
          )}
        </div>
      )}
    />
  );
};
