import { useEffect, useState } from "react";
import { FolderButtons, MoveFolderButton } from "./folderButtons";
import "./documents.css";
import { IFolderProps } from "../../types/FolderItem";
import { IDropDownOption } from "../../types/DropDown";
import {
  Divider,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import FolderIcon from "@mui/icons-material/Folder";
import { api } from "../../helper/api";
import SimpleFolderCard from "./simpleFolderCard";
import React from "react";
import GenericErrorMessageModal from "../forms/errorHandling/genericErrorMessageModal";
import { t } from "i18next";
import stylings from "./folderTree.module.css";
// using "stylings" instead of "styles" to avoid conflict with the "styles" pre-defined variable

export interface IFolderTreeOptions extends IDropDownOption {
  treeId?: string;
}
interface Props {
  treeId?: string;
  count?: number;
  moveFolderSource?: IFolderProps[] | any;
  adminMode?: boolean;
  subjectId?: string;
  source?: string;
  onClickHandler: (id: string, name: string) => void;
  moveFileTarget?: string;
  folderId?: string;
  openEditModal?: (item: IFolderProps) => void;
  openCreateSubFolderModal?: (item: IFolderProps) => void;
  deleteFolder?: (item: IFolderProps) => void;
  isTreeSelected?: boolean;
  handleMoveFolderSource?: (item: IFolderProps) => void;
  handleMoveFolderTarget?: (item: IFolderProps) => void;
  handleMoveFileTarget?: () => void;
  cancelFolderMove?: () => void;
  style?: React.CSSProperties;
  heading?: string;
  handleTreeSelectChange?: (selectedOption: IFolderTreeOptions) => void;
  selected?: any;
  options?: any;
  subdomain?: string;
  openCreateRootFolderModal?: (item: IFolderProps) => void;
  openNewTreeModal?: () => void;
  handleMoveFolderRoot?: () => void;
  simpleFolderView?: boolean;
  updateFolder?: boolean;
}

export default function FolderTree(props: Props) {
  const [tree, setTree] = useState<IFolderProps[]>([]);
  const [folders, setFolders] = useState<any>({});
  const [subFolderIds, setSubfolderIds] = useState<string[]>([]);
  const [error, setError] = useState<any>();

  const hostname = window.location.hostname;
  const subdomain = hostname.split(".")[0];

  useEffect(() => {
    const additionalUrlParameters: any = {};
    if (subdomain === "admin" && props.source != "folder") {
      additionalUrlParameters["impersonate_subject"] = props.subjectId;
    }
    api.genericApiRequest({
      entity: "folder",
      method: "get",
      successHandler: (res: any) => {
        const folders: any = {};
        let tree = [];

        for (const key in res.data.results) {
          const sortedData = res.data.results.sort((a: any, b: any) =>
            a.name.localeCompare(b.name)
          );
          const item = sortedData[key];
          let parentKey = "root";
          if (item.parent !== null) {
            parentKey = item.parent.id;
          }
          if (!Object.prototype.hasOwnProperty.call(folders, parentKey)) {
            folders[parentKey] = [];
          }
          folders[parentKey].push(item);
        }
        tree = findChildrenForTreeItem(folders["root"], folders);

        setTree(tree);
        setFolders(folders);
      },
      failHandler: (error: any) => {
        setError(error);
      },
      parametersToRender: {
        depth: "0",
        additionalUrlParameters: additionalUrlParameters,
      },
    });
  }, [props.treeId, props.count, props.updateFolder, props.subjectId]);

  const findChildrenForTreeItem = (tree: any, folders: any) => {
    for (const key in tree) {
      if (folders[tree[key].id]) {
        tree[key]["children"] = findChildrenForTreeItem(
          folders[tree[key].id],
          folders
        );
      }
    }
    return tree;
  };
  const getSubfolderIds = (folderId: any) => {
    let subFolderIds: any = [];
    if (folders[folderId]) {
      const children: any[] = folders[folderId];
      for (const key in children) {
        const child = children[key];
        subFolderIds.push(child.id);
        subFolderIds = subFolderIds.concat(getSubfolderIds(child.id));
      }
    }
    return subFolderIds;
  };

  useEffect(() => {
    let ids = [];
    if (props.moveFolderSource) {
      ids = getSubfolderIds(props.moveFolderSource.id);
      setSubfolderIds(ids);
    }
  }, [props.moveFolderSource]);

  let renderedTree = <></>;
  const renderSubTree: any = (subtree: any, maxDepth: any) => {
    if (maxDepth < 0) {
      return <></>;
    }

    const items = [];
    const idKey: any = [];

    for (const key in subtree) {
      const subItem = subtree[key];
      const id = subItem.id;
      const name = subItem.name;
      let styles;
      if (props.adminMode || props.subjectId) {
        styles = `folderList_Item ${
          props?.moveFolderSource?.length !== 0 || props.source === "document"
            ? "folderList_Item-active"
            : "folderList_Item"
        }`;
      } else styles = "folderList_Item";

      idKey.push(id);
      if (!props.simpleFolderView) {
        items.push(
          <div key={subItem.id}>
            <Grid
              xs={12}
              item
              container
              component={ListItem}
              className={`${styles} {stylings.folderListItemContainer}`}
              onClick={() => props.onClickHandler(id, name)}
            >
              <Grid item component={ListItemIcon}>
                <FolderIcon />
              </Grid>
              <div className={stylings.flexContainer}>
                <Grid
                  item
                  component={ListItemText}
                  disableTypography={true}
                  classes={{ root: stylings.listItemTextRoot }}
                  primary={subItem.name}
                  className={
                    props.moveFileTarget === subItem.id ||
                    props.folderId === subItem.id
                      ? "listItemName-selected"
                      : "listItemName"
                  }
                />
                {props.source === "folder" && props.adminMode && (
                  <FolderButtons
                    className="buttonWrapper"
                    item={subItem}
                    openEditModal={props.openEditModal}
                    openCreateSubFolderModal={props.openCreateSubFolderModal}
                    deleteFolder={props.deleteFolder}
                    disabled={!props.isTreeSelected}
                  />
                )}
                {props.source === "folder" && (
                  <MoveFolderButton
                    className="moveButtonWrapper"
                    item={subItem}
                    subFolderIds={subFolderIds}
                    adminMode={props.adminMode}
                    subjectId={props.subjectId}
                    moveFolderSource={props.moveFolderSource}
                    source={props.source}
                    handleMoveFolderSource={props.handleMoveFolderSource}
                    handleMoveFolderTarget={props.handleMoveFolderTarget}
                    // handleMoveFileTarget={props.handleMoveFileTarget}
                    cancelFolderMove={props.cancelFolderMove}
                  />
                )}
              </div>
            </Grid>
            {props.source === "folder" && (
              <Divider component={ListItem} disablePadding />
            )}
          </div>
        );
        if (subItem.children) {
          items.push(renderSubTree(subItem.children, maxDepth - 1));
        }
      } else
        items.push(
          <SimpleFolderCard
            onClickHandler={props.onClickHandler}
            name={name}
            id={id}
            key={subItem.id}
            showCursor={true}
            folderId={props.folderId}
            fileCount={subItem.file_count}
          />
        );
    }
    if (!props.simpleFolderView) {
      return (
        <Grid container item key={idKey}>
          <Grid
            item
            xs={12}
            component={List}
            className={stylings.listItemContainer}
            key={idKey}
          >
            {items}
          </Grid>
        </Grid>
      );
    } else if (props.simpleFolderView) {
      return <React.Fragment key={idKey}>{items}</React.Fragment>;
    }
  };

  if (tree?.length > 0) {
    renderedTree = renderSubTree(tree, 50);
  }

  return (
    <>
      {renderedTree}
      {error && error !== "" && (
        <GenericErrorMessageModal
          title={t("error_occurred")}
          error={error}
          onClosehandler={() => {
            setError("");
          }}
        />
      )}
    </>
  );
}
