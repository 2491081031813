import { useState, useEffect } from "react";
import {
  useSignIn,
  useAuthUser,
  useIsAuthenticated,
  useSignOut,
} from "react-auth-kit";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "../styles/login.css";
import Button from "./inputs/button";
import { api } from "../../helper/api";
import { useDispatch } from "react-redux";
import { setLogin } from "../state/parent/loginSlice";
import { useForm } from "react-hook-form";
import PasswordInput from "./inputs/passwordInput";
import GenericErrorMessageModal from "../../components/forms/errorHandling/genericErrorMessageModal";

interface Props {
  errorHandler: () => void;
  errorReset: () => void;
  portalName: () => any;
  subdomain?: string;
}

export default function LoginForm(props: Props) {
  const [loginstate, setLoginstate] = useState<number>(0);
  const [error, setError] = useState<any>();

  const { t } = useTranslation();
  const navigate = useNavigate();
  const signIn = useSignIn();
  const signOut = useSignOut();

  const getAuth = useAuthUser();
  const isAuthenticated = useIsAuthenticated();
  const dispatch = useDispatch();

  const { register, handleSubmit, control } = useForm({
    mode: "onChange",
    defaultValues: {
      userName: "",
      userPassword: "",
    },
  });

  const combinedSignout = () => {
    dispatch({ type: "clear" });
    signOut();
  };

  useEffect(() => {
    if (isAuthenticated()) {
      const auth = getAuth();

      if (auth && auth.email) {
        setLoginstate(1);
      }
    }
  }, [getAuth, isAuthenticated]);

  useEffect(() => {
    if (loginstate === 1) {
      navigate("/");
    }
  }, [loginstate, navigate]);

  const onSubmit = (data: any) => {
    dispatch({ type: "clear" });

    const result = {
      username: data.userName,
      password: data.userPassword,
    };
    api.genericApiRequest({
      entity: "login",
      method: "post",
      successHandler: (res: any) => {
        const responseData = res.data;

        if (responseData) {
          const sessionExpiration = Math.floor(
            responseData.token_expiry_in / 60
          );
          if (
            signIn({
              token: responseData.email,
              expiresIn: sessionExpiration,
              tokenType: "Session",
              authState: {
                email: responseData.email,
                first_name: responseData.first_name,
                last_name: responseData.last_name,
                company_name: responseData.company_name,
              },
            })
          ) {
            setLoginstate(1);
            navigate("/");

            dispatch(
              setLogin({
                subjectId: responseData.subject_id,
                firstname: responseData.first_name,
                lastname: responseData.last_name,
                email: responseData.email,
                companyName: responseData.company_name,
                permissions: responseData.permissions,
              })
            );
          } else {
            console.log("login was not successful");
          }
        }
      },
      failHandler: (error: any) => {
        if (error === "logout") {
          combinedSignout();
        } else {
          props.errorHandler();
          setError(error);
        }
      },
      submitData: result,
    });
  };

  if (loginstate === 0) {
    return (
      <>
        <form
          className="login_form"
          onSubmit={handleSubmit(onSubmit)}
          noValidate
        >
          <h1 className="heading textAlignCenter">{props.portalName()}</h1>

          <label htmlFor="login_email" className="login_formLabel">
            {t("login_email")}
          </label>
          <input
            {...register("userName")}
            className="login_formInput"
            onFocus={props.errorReset}
            id={"login_email"}
          />
          <PasswordInput
            control={control}
            name={"userPassword"}
            id={t("password")}
            label={t("password")}
            title={t("password")}
            dynamicLabel={false}
          />

          <div className="login_passwordReminderWrapper">
            <a className="login_passwordReminderLink" href="/login/reminder/">
              {t("password_forgotten")}
            </a>
            <Button
              type="submit"
              title={t("login")}
              className="login_submitButton"
              variant="contained"
            />
          </div>
        </form>
        {error && error !== "" && (
          <GenericErrorMessageModal
            title={t("error_occurred")}
            error={error}
            onClosehandler={() => {
              setError("");
            }}
          />
        )}
      </>
    );
  } else {
    return <></>;
  }
}
//TODO CUSTOMERROR
