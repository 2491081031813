import { useEffect, useState } from "react";
import { IconButton, Tooltip } from "@mui/material";
import DropDown from "../forms/inputs/dropDown";
import TinyMceEditor from "../tinymce/tinymce";
import GenericCrudTableModal from "../tables/genericCrudTableModal";
import RadioButtons from "../forms/inputs/radioButtons";
import RefreshIcon from "@mui/icons-material/Refresh";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import LibraryAddIcon from "@mui/icons-material/LibraryAdd";
import { ITemplateData } from "../../types/TeamplateData";
import { IDropDownOption } from "../../types/DropDown";
import { useTranslation } from "react-i18next";
import { api } from "../../helper/api";
import { TextArea } from "../forms/inputs/textArea";
import Button from "../forms/inputs/button";
import GenericErrorMessageModal from "../forms/errorHandling/genericErrorMessageModal";
import GenericNotification from "../notification/genericNotification";
import styles from "./editor.module.css";

interface Props {
  id?: string;
  onRemoveEditor?: (id: any) => void;
  onDuplicateEditor?: (data: any) => void;
  // editorCount?: any;
  data?: any;
}

interface ITemplateOptions extends IDropDownOption {
  type?: string;
}

export function Editor(props: Props) {
  const [allTemplateData, setAllTemplateData] = useState<ITemplateData[]>([]);
  const [allSnippetOptions, setAllSnippetOptions] = useState<
    ITemplateOptions[]
  >([]);
  const [allTemplateOptions, setAllTemplateOptions] = useState<
    ITemplateOptions[]
  >([]);
  const [allOptions, setAllOptions] = useState<ITemplateOptions[]>([]);
  const [options, setOptions] = useState<ITemplateOptions[]>([]);
  const [selectedTemplate, setSelectedTemplate] = useState<
    ITemplateOptions | undefined | null
  >();
  const [modalPresetValues, setModalPresetValues] = useState<any>([]);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [initialLoading, setInitialLoading] = useState<boolean>(false);
  const [newTemplateCreated, setNewTemplateCreated] = useState<boolean>(false);
  const [templateEdited, setTemplateEdited] = useState<boolean>(false);
  const [deletedTemplate, setDeletedTemplate] = useState<boolean>(false);
  const [selectedDefault, setSelectedDefault] = useState<
    ITemplateOptions | undefined | null
  >();
  const [modalMode, setModalMode] = useState<string>("create");
  const [editedId, setEditedId] = useState<string>("");
  const [lastSaved, setLastSaved] = useState<any>("");
  const [error, setError] = useState<any>();
  const [editortype, setEditorType] = useState<any>("editor");
  const [templateType, setTemplateType] = useState<any>("all");
  const [textAreaValue, setTextareaValue] = useState<any>("");
  //eslint-disable-next-line
  const [editorValue, setEditorValue] = useState<any>("");
  const [showNotification, setShowNotification] = useState<boolean>(false);
  const [notificationMessage, setNotificationMessage] = useState<any>("");
  const [notificationVariant, setNotificationVariant] = useState<
    "success" | "error" | "warning" | "info" | undefined
  >();

  const { t } = useTranslation();
  const resetStateOnCloseNotification = () => {
    setShowNotification(false);
    setNotificationMessage("");
  };

  const refreshTable = () => {
    setAllTemplateData([]);
    setInitialLoading(false);
  };

  useEffect(() => {
    if (!initialLoading) {
      if (allTemplateData.length === 0) {
        api.genericApiRequest({
          entity: "template",
          getAllPages: true,
          method: "get",

          successHandler: (res: any) => {
            const newItem = res.data.results[res.data.results.length - 1].id;
            const selected = [];
            for (const key in res.data.results) {
              const item = res.data.results[key];
              const id = item.id;
              const name = item.name;
              const type = item.type;
              const text = item.text;
              const allSelected = {
                value: id,
                label: name,
                type: type,
                text: text,
              };
              selected.push(allSelected);
            }
            selected.sort(function (a, b) {
              return a.label.localeCompare(b.label);
            });
            const templates = selected.filter(
              (item: any) => item.type === "template"
            );
            const snippets = selected.filter(
              (item: any) => item.type === "snippet"
            );
            setAllSnippetOptions(snippets);
            setAllTemplateOptions(templates);
            setAllOptions(selected);
            setOptions(selected);
            setAllTemplateData(res.data.results);
            setInitialLoading(true);

            const findNewItem = selected.find((x: any) => x.value === editedId);
            const duplicatedItem = selected.find(
              (x: any) => x.value === newItem
            );
            if (newTemplateCreated === true) {
              setSelectedDefault(findNewItem?.value);
              setNewTemplateCreated(false);
            }
            if (templateEdited === true) {
              setSelectedDefault(findNewItem?.value);
              setTemplateEdited(false);
            }
            if (deletedTemplate === true) {
              setSelectedTemplate(undefined);
              setSelectedDefault({ value: "", label: "" });
              setDeletedTemplate(false);
            }

            if (
              props.data !== undefined &&
              !deletedTemplate &&
              !newTemplateCreated
            ) {
              setSelectedDefault(duplicatedItem?.value);
              setNewTemplateCreated(false);
            }
          },
          failHandler: (error: any) => {
            setError(error);
          },
        });
      }
    }
  }, [allTemplateData, initialLoading]);

  const showTemplate = allTemplateData?.find(
    (item: any) => item.id === selectedTemplate?.value
  );

  const handleChange = (selectedOption: any) => {
    setSelectedTemplate(selectedOption);
  };

  const handleOnTypeChange = (event: any) => {
    if (event.target.value === "all") {
      setOptions(allOptions);
    }
    if (event.target.value === "snippet") {
      setOptions(allSnippetOptions);
    }
    if (event.target.value === "template") {
      setOptions(allTemplateOptions);
    }
    setTemplateType(event.target.value);
  };
  const handleOnEditorTypeChange = (event: any) => {
    if (confirm(t("change_editor_type_confirm"))) {
      setEditorType(event.target.value);
    }
  };

  const handleOnSave = (content: any) => {
    const result = {
      name: selectedTemplate?.label,
      type: selectedTemplate?.type,
      text: content,
    };
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    api.genericApiRequest({
      entity: "template",
      method: "put",
      successHandler: (res: any) => {
        setNotificationMessage(t("save_successful"));
        setNotificationVariant("success");
        setShowNotification(true);
        const date = new Date(res.data.modified_on);
        setLastSaved(date.toLocaleString());
        refreshTable();
        setModalOpen(false);
        setTemplateEdited(true);
        setEditedId(res.data.id);
      },
      failHandler: (error: any) => {
        setError(error);
      },
      entityId: String(selectedTemplate?.value),
      submitData: result,
      config: config,
    });
  };

  const handleTextareaChange = (event: any) => {
    if (event) {
      setTextareaValue(event.target.value);
    }
  };
  const handleEditorChange = (value: any) => {
    setEditorValue(value);
  };

  const handleOnTextareaSave = () => {
    const result = {
      name: selectedTemplate?.label,
      type: selectedTemplate?.type,
      text: textAreaValue,
    };
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    api.genericApiRequest({
      entity: "template",
      method: "put",
      successHandler: (res: any) => {
        setNotificationMessage(t("save_successful"));
        setNotificationVariant("success");
        setShowNotification(true);
        const date = new Date(res.data.modified_on);
        setLastSaved(date.toLocaleString());
        refreshTable();
        setModalOpen(false);
        setTemplateEdited(true);
        setEditedId(res.data.id);
      },
      failHandler: (error: any) => {
        setError(error);
      },
      entityId: String(selectedTemplate?.value),
      submitData: result,
      config: config,
    });
  };
  const openCreateModal = () => {
    setModalMode("create");
    setModalPresetValues({
      status: "open",
    });
    setModalOpen(true);
  };

  const openEditModal = () => {
    setModalMode("edit");
    setModalPresetValues({
      name: selectedTemplate?.label,
      type: selectedTemplate?.type,
      text: showTemplate?.text,
    });
    setModalOpen(true);
  };
  const openDuplicateModal = () => {
    setModalMode("duplicate");
    setModalPresetValues({ name: selectedTemplate?.label + "_1" });
    setModalOpen(true);
  };

  const handleAddTemplate = (values: any) => {
    const prepared_values = {
      name: values.name,
      type: values.type,
      text: `New ${values.type}`,
    };
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    api.genericApiRequest({
      entity: "template",
      method: "post",
      successHandler: (res: any) => {
        setNewTemplateCreated(true);
        setEditedId(res.data.id);
        refreshTable();
        setModalOpen(false);
      },
      failHandler: (error: any) => {
        setError(error);
      },
      submitData: prepared_values,
      config: config,
    });
  };

  const handleEditTemplateName = (values: any) => {
    const result = {
      name: values.name,
      type: modalPresetValues.type,
      text: modalPresetValues.text,
    };
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    api.genericApiRequest({
      entity: "template",
      method: "put",
      successHandler: (res: any) => {
        setNotificationMessage(t("save_successful"));
        setNotificationVariant("success");
        setShowNotification(true);
        setTemplateEdited(true);
        setEditedId(res.data.id);
        refreshTable();
        setModalOpen(false);
      },
      failHandler: (error: any) => {
        setError(error);
      },
      entityId: String(selectedTemplate?.value),
      submitData: result,
      config: config,
    });
  };

  const copySelectedNameToClipboard = () => {
    if (selectedTemplate !== undefined && selectedTemplate !== null) {
      navigator.clipboard.writeText(selectedTemplate?.label);
    }
  };

  const deleteTemplate = (skipQuestion = false) => {
    if (selectedTemplate !== undefined) {
      if (!skipQuestion) {
        if (
          /*eslint-disable */
          !confirm(
            `${t(
              "delete_template_confirmation_before_selected_template_label"
            )} ${selectedTemplate?.label} ${t(
              "delete_template_confirmation_after_selected_template_label"
            )}`
          )
        ) {
          return;
        }
      }
      const fetchData = async () => {
        try {
          const res = await api.genericApiRequest({
            entity: "template",
            method: "delete",
            successHandler: () => {
              /*eslint-enable */
              setNotificationMessage(
                ` ${selectedTemplate?.label} t("delete_successful_all_lowercase")`
              );
              setNotificationVariant("success");
              setShowNotification(true);
              setDeletedTemplate(true);
              refreshTable();
            },
            entityId: String(selectedTemplate?.value),
          });
        } catch (error) {
          setError(error);
        }
      };
      fetchData();
    } else alert(t("choose_template_before_delete_operation"));
  };
  const duplicateTemplate = (values: any) => {
    const result = {
      name: values.name,
      type: selectedTemplate?.type,
      text: showTemplate?.text,
    };
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    api.genericApiRequest({
      entity: "template",
      method: "post",
      successHandler: (res: any) => {
        const data = {
          name: res.data.name,
          type: res.data.type,
          text: res.data.text,
          id: res.data.id,
        };
        props.onDuplicateEditor?.(data);
        refreshTable();
        setModalOpen(false);
      },
      failHandler: (error: any) => {
        setError(error);
      },
      submitData: result,
      config: config,
    });
  };
  const tooltipName = () => {
    if (selectedTemplate !== undefined) {
      if (selectedTemplate?.type === "snippet") {
        return "Snippetname";
      }
      if (selectedTemplate?.type === "template") {
        return "Templatename";
      } else return "";
    }
  };

  const columns = [
    {
      header: t("name"),
      accessorKey: "name",
      id: "name",
      enableForCreate: true,
      validate: true,
      rule: /^[a-z0-9_]*$/,
      helperText:
        `${t("use_lowercase")}, ${t("use_no_umlauts")}, ${t("use_no_spaces")}. ${t("use_underscore")}`,
      errorMessage: t("invalid_characters"),
      autoComplete: "off",
    },
    {
      header: t("type"),
      accessorKey: "type",
      id: "type",
      enableForCreate:
        modalMode === "edit" || modalMode === "duplicate" ? false : true,
      selectOptions: [
        {
          value: "template",
          label: "template",
          //  name: "template",
          id: "template",
        },
        {
          value: "snippet",
          label: "snippet",
          //  name: "snippet",
          id: "snippet",
        },
      ],
    },
  ];

  return (
    <div className={styles.container}>
      <RadioButtons
        name={`type_${props.id}`}
        label="Type"
        defaultValue={templateType}
        choices={[
          { name: "all", label: "Alle" },
          { name: "snippet", label: "Snippet" },
          { name: "template", label: "Template" },
        ]}
        onChange={(event: any) => handleOnTypeChange(event)}
      />
      <RadioButtons
        name={"editorType"}
        label={t("editortype")}
        defaultValue={editortype}
        choices={[
          { name: "textfield", label: "Textfeld" },
          { name: "editor", label: "Editor" },
        ]}
        onChange={(event: any) => {
          handleOnEditorTypeChange(event);
        }}
      />
      <div className={styles.dropdownWrapper}>
        <DropDown
          defaultValue={selectedDefault}
          label="Templates/Snippets"
          name={`templates_${props.id}`}
          options={options}
          onChange={handleChange}
          isSearchable={true}
          isClearable={true}
          labelClassName={`
            ${styles.dropDown__templates}
            ${selectedTemplate !== undefined ? styles.dropDown__selectedTemplate : ""}
          `}
          deleteItem={deletedTemplate}
          editedItem={templateEdited}
        />
        <Tooltip placement="top" title={t("refresh")}>
          <IconButton color="primary" onClick={() => refreshTable()}>
            <RefreshIcon />
          </IconButton>
        </Tooltip>
        <Tooltip placement="top" title={t("edit")}>
          <span>
            <IconButton
              color="primary"
              onClick={() => openEditModal()}
              disabled={selectedTemplate ? false : true}>
              <EditIcon />
            </IconButton>
          </span>
        </Tooltip>
        <Tooltip placement="top" title={t("copy", { title: tooltipName() })}>
          <span>
            <IconButton
              color="primary"
              onClick={copySelectedNameToClipboard}
              disabled={selectedTemplate ? false : true}>
              <ContentCopyIcon />
            </IconButton>
          </span>
        </Tooltip>
        <Tooltip placement="top" title={t("delete")}>
          <IconButton color="error" onClick={() => deleteTemplate()}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>

        <Tooltip placement="top" title={t("create")}>
          <IconButton color="primary" onClick={() => openCreateModal()}>
            <AddIcon />
          </IconButton>
        </Tooltip>
        <Tooltip
          placement="top"
          title={t("duplicate", { title: selectedTemplate?.type })}>
          <span>
            <IconButton
              color="primary"
              onClick={openDuplicateModal}
              disabled={selectedTemplate ? false : true}>
              <LibraryAddIcon />
            </IconButton>
          </span>
        </Tooltip>
      </div>
      {selectedTemplate !== undefined &&
        selectedTemplate !== null &&
        editortype === "editor" && (
          <TinyMceEditor
            value={showTemplate?.text}
            handleOnSave={handleOnSave}
            lastSaved={lastSaved}
            onChange={handleEditorChange}
          />
        )}
      {selectedTemplate !== undefined &&
        selectedTemplate !== null &&
        editortype === "textfield" && (
          <>
            <TextArea
              rows={15}
              fieldClassName={styles.textArea__field}
              value={showTemplate?.text}
              onChange={handleTextareaChange}
              name="editorTextarea"
            />
            <div className={styles.buttonWrapper}>
              <Button
                color="secondary"
                variant="contained"
                type="button"
                onClick={handleOnTextareaSave}
                style={{ fontSize: "inherit" }}
                title={t("save")}
              />

              {lastSaved && typeof (lastSaved === "string") && (
                <div className={styles.lastSaved}>
                  {t("last_saved")}: <span>{lastSaved}</span>
                </div>
              )}
            </div>
          </>
        )}

      <GenericCrudTableModal
        open={modalOpen}
        columns={columns}
        presetValues={modalPresetValues}
        onClose={() => setModalOpen(false)}
        title={(function () {
          if (modalMode === "edit" || modalMode === "duplicate") {
            return t("edit_name");
          }
          return t("add_new_element");
        })()}
        submitButtonText={(function () {
          if (modalMode === "edit") {
            return t("save");
          }
          return t("add");
        })()}
        onSubmit={(function () {
          if (modalMode === "edit") {
            return handleEditTemplateName;
          } else if (modalMode === "duplicate") {
            return duplicateTemplate;
          } else return handleAddTemplate;
        })()}
      />
      <>
        {error && error !== "" && (
          <GenericErrorMessageModal
            title={t("error_occurred")}
            error={error}
            onClosehandler={() => {
              setError("");
            }}
          />
        )}
        {showNotification && (
          <GenericNotification
            message={notificationMessage}
            variant={notificationVariant}
            handleCloseSnackbar={resetStateOnCloseNotification}
          />
        )}
      </>
    </div>
  );
}

//TODO: refactoring of API functions
//TODO: onchange on tiny? event needed for recognizing if any changes were made!
