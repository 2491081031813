import { useEffect, useState } from "react";
import FileList from "./fileList";
import FolderTree from "./folderTree";
import RoundedContentBox from "../general/roundedContentBox";
import { Grid, List } from "@mui/material";
import { t } from "i18next";
import styles from "./fileExplorer.module.css";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

interface Props {
  subjectId?: string;
  subjectTreeId?: string;
  query?: string;
  onResetQuery?: any;
}

export default function FileExplorer(props: Props) {
  const [folderId, setFolderId] = useState<string>("");
  const [folderName, setFolderName] = useState<string>("");
  const [query, setQuery] = useState<string>("");
  const [updateFolder, setUpdateFolder] = useState<boolean>(false);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  /*eslint-disable */
  const [fileId, setFileId] = useState<string>("");
  /*eslint-enable */

  useEffect(() => {
    setFolderId("11111111-1111-1111-1111-888888888888");
    setFolderName("Berichte von Kaer");
  }, [props.subjectId]);

  const handleFolderInfos = (id: string, name: string) => {
    setFolderId(id);
    setFolderName(name);
  };

  useEffect(() => {
    resetQuery();
  }, [folderId]);

  const fileListHeading = folderName
    ? t("documents") + ` - ${folderName}`
    : t("documents");

  const resetQuery = () => {
    setQuery("");
  };

  const folderUpdate = () => {
    setUpdateFolder(true);
  };
  return (
    <Grid container className={styles.container}>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Grid container component={List} className={styles.container}>
          {/* <List> */}
          <FolderTree
            simpleFolderView={true}
            subjectId={props.subjectId}
            folderId={folderId}
            // onClickHandler={(item: any) => setFolderId(item)}
            onClickHandler={(id: string, name: string) =>
              handleFolderInfos(id, name)
            }
            source="document"
            moveFolderSource={[]}
            treeId={props.subjectTreeId}
            style={folderTreeStyle}
            heading="Ordner"
            updateFolder={updateFolder}
          />
        </Grid>
      </Grid>

      <Grid item xs={12} sm={12} md={12} className={styles.fileListWrapper}>
        {folderId && (
          <Grid
            container
            component={(props) => (
              <RoundedContentBox {...props} padding={isMobile ? "0.5rem" : undefined} />
            )}
          >
            <Grid item component={"h3"} className={styles.heading}>
              {fileListHeading}
            </Grid>
            {folderId && (
              <FileList
                subjectId={props.subjectId}
                folderId={folderId}
                folderName={folderName}
                subjectTreeId={props.subjectTreeId}
                onClickHandler={(id: any) => setFileId(id)}
                query={query}
                folderUpdate={folderUpdate}
              />
            )}
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}

const folderTreeStyle = {
  width: "40%",
} as React.CSSProperties;
