export enum DEFAULTPICKERDATE {
  "UNSET" = "UNSET",
  "TODAY" = "TODAY",
}
export interface IAppointmentRequest {
  title?: string;
  department_set?: [];
  subject_set?: [];
  company?: string;
  category?: string;
  examination_set?: [];
  status?: string;
  day_set?: [];
  parallel_slots?: number;
  slot_duration?: number;
  id?: string;
  start_day?: string;
}

export interface IAppointment {
  id?: string;
  created_by?: string;
  modified_by?: string;
  created_on?: string;
  modified_on?: string;
  datetime?: string;
  status?: string;
  subject?: ISubject;
  team?: ITeam;
}
export interface ICompany {
  id?: string;
  created_by?: string;
  modified_by?: string;
  created_on?: string;
  modified_on?: string;
  name?: string;
  phone?: string;
}

export interface IExamination {
  id?: string;
  created_by?: string;
  modified_by?: string;
  created_on?: string;
  modified_on?: string;
  description?: string;
  category_set?: [];
  version?: number;
  next_version?: number;
  trace_id?: string;
  name?: string;
}
export interface IFinding {
  id?: string;
  created_by?: string;
  modified_by?: string;
  created_on?: string;
  modified_on?: string;
  name?: string | null;
  status?: string;
  approved_by?: string | undefined;
  executed_by?: string;
  probe?: IProbe;
  appointment?: IAppointment;
  probe_name?: any;
}

export interface IForm {
  id?: string;
  created_by?: string;
  modified_by?: string;
  created_on?: string;
  modified_on?: string;
  version?: number;
  trace_id?: string;
  name?: string;
  editable_by_subject?: boolean;
  next_version?: number;
  probe?: IProbe;
  measurement_definition?: [];
  measurementdefinitiontoform_set?: [];
}

export interface IMeasurementDefinition {
  id?: string;
  created_by?: string;
  created_on?: string;
  modified_by?: string;
  modified_on?: string;
  version?: number;
  trace_id?: string;
  name?: string;
  label?: string;
  type?: string;
  unit?: string;
  reuse?: boolean;
  conditions?: string;
  value_map?: any;
  sample_data?: any;
  next_version?: number;
}
export interface IProbe {
  id?: string;
  created_by?: string;
  created_on?: string;
  modified_by?: string;
  modified_on?: string;
  version?: number;
  trace_id?: string;
  name?: string;
  description?: string;
  next_version: number;
  examination_set: any[];
}

export interface ISubject {
  id?: string;
  created_by?: string;
  modified_by?: string;
  created_on?: string;
  modified_on?: string;
  folder_tree?: string;
  company?: string;
  person?: string;
  department_set?: [];
  name?: string;
  email?: string;
}
export interface ITeam {
  id?: string;
  created_on?: string;
  modified_on?: string;
  name?: string;
  description?: string;
  created_by?: string;
  modified_by?: string;
  subject_set?: [];
  member_set?: [];
  approved_by?: string;
}
