import { FC } from "react";

const HeaderBackground: FC = () => {
  return (
    <svg
      version="1.1"
      id="Ebene_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 1920 290"
      xmlSpace="preserve"
      preserveAspectRatio="none"
    >
      <style type="text/css"></style>
      <g>
        <rect style={{ fill: "#A34BD3" }} width="1920" height="290" />
        <path
          style={{ fill: "#FF6428" }}
          d="M932.8,290H0V0h494.6c2.8,41.1,13.2,81.3,47.4,120.1c113.8,128.7,315.1,42.3,386.5,162.2
      C930.1,284.8,931.5,287.4,932.8,290z"
        />
        <path
          style={{ fill: "#008C7D" }}
          d="M1920,0v119.9c-30.5,14-64.5,21.8-100.4,21.8c-42.7,0-82.8-11.1-117.5-30.5c-105-56.2-136.6-63-283.5,0.8
      c-41.3,19.1-87.4,29.8-136.1,29.8c-111.9,0-210.4-56.4-267.4-141.7H1920z"
        />
      </g>
    </svg>
  );
};

export default HeaderBackground;
