import React, { FC } from "react";
import Restricted from "../../consumer/restricted";
import GroupCrudTable from "../../components/permissions/groupCrudTable";

interface Props {
  title?: string;
}

const PermissionGroupList: FC<Props> = () => {
  return (
    <Restricted permissionRequired="frontend_view_permission_group">
      <GroupCrudTable />
    </Restricted>
  );
};

export default PermissionGroupList;
