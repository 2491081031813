import React, { FC } from "react";
import CoreData from "../../components/coreData/coreData";
import Restricted from "../../consumer/restricted";

const CoreDataView: FC = () => {
  return (
    <Restricted permissionRequired="frontend_view_core_data">
      <CoreData />
    </Restricted>
  );
};

export default CoreDataView;
