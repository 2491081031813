import { FC } from "react";
import { Collapse, IconButton, ListItem } from "@mui/material";
import "./navigationBar.css";
import { adminSubroutes } from "./adminSubRoutes";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import {
  NavigationItemButton,
  NavigationListItemContent,
  NavigationNavLink,
} from "./navigationItems";
import { t } from "i18next";
import Restricted from "../../consumer/restricted";
import styles from "./adminRoutes.module.css";

interface Props {
  drawerOpen: boolean;
  subRouteOpen?: boolean;
  handleSubrouteOpen: any;
  selectedIndex: number;
  handleListItemClick?: any;
  handleListSoubrouteItemClick?: any;
  subdomain: string;
}

const AdminRoutes: FC<Props> = ({
  subRouteOpen,
  handleSubrouteOpen,
  drawerOpen,
  subdomain,
  selectedIndex,
  handleListItemClick,
  handleListSoubrouteItemClick,
}) => {
  if (subdomain === "admin") {
    return (
      <>
        <Restricted permissionRequired="frontend_view_appointment_dashboard">
          <ListItem
            disablePadding
            className={drawerOpen ? "navigationItemOpen" : "navigationItem"}
          >
            <NavigationNavLink to="/appointmentDashboard">
              <NavigationItemButton
                drawerOpen={drawerOpen}
                onClick={(event: any) => handleListItemClick(event, 20)}
                selected={selectedIndex === 20}
              >
                <NavigationListItemContent
                  drawerOpen={drawerOpen}
                  primaryText="Termin Dashboard"
                  icon="appointment"
                />
              </NavigationItemButton>
            </NavigationNavLink>
          </ListItem>
        </Restricted>
        <Restricted permissionRequired="frontend_view_appointment_request">
          <ListItem
            disablePadding
            className={drawerOpen ? "navigationItemOpen" : "navigationItem"}
          >
            <NavigationNavLink to="/appointmentrequest">
              <NavigationItemButton
                drawerOpen={drawerOpen}
                onClick={(event: any) => handleListItemClick(event, 21)}
                selected={selectedIndex === 21}
              >
                <NavigationListItemContent
                  drawerOpen={drawerOpen}
                  primaryText={t("appointmentrequest_single")}
                  icon="newAppointment"
                />
              </NavigationItemButton>
            </NavigationNavLink>
          </ListItem>
        </Restricted>
        <Restricted permissionRequired="frontend_view_appointment">
          <ListItem
            disablePadding
            className={drawerOpen ? "navigationItemOpen" : "navigationItem"}
          >
            <NavigationNavLink to="/appointmentList">
              <NavigationItemButton
                drawerOpen={drawerOpen}
                onClick={(event: any) => handleListItemClick(event, 26)}
                selected={selectedIndex === 26}
              >
                <NavigationListItemContent
                  drawerOpen={drawerOpen}
                  primaryText={t("appointments_booked")}
                  icon="appointment"
                />
              </NavigationItemButton>
            </NavigationNavLink>
          </ListItem>
        </Restricted>
        <Restricted permissionRequired="frontend_view_subject_company_overview">
          <ListItem
            disablePadding
            className={drawerOpen ? "navigationItemOpen" : "navigationItem"}
          >
            <NavigationNavLink to="/subjectOverview">
              <NavigationItemButton
                drawerOpen={drawerOpen}
                onClick={(event: any) => handleListItemClick(event, 42)}
                selected={selectedIndex === 42}
              >
                <NavigationListItemContent
                  drawerOpen={drawerOpen}
                  primaryText={t("subject_company_overview")}
                  icon="employee"
                />
              </NavigationItemButton>
            </NavigationNavLink>
          </ListItem>
        </Restricted>

        <ListItem
          disablePadding
          className={drawerOpen ? "navigationItemOpen" : "navigationItem"}
        >
          <NavigationItemButton
            drawerOpen={drawerOpen}
            onClick={handleSubrouteOpen}
            pr={drawerOpen ? 1 : 0}
          >
            <NavigationListItemContent
              drawerOpen={drawerOpen}
              primaryText="Admin"
              icon="admin"
            />
            {drawerOpen && !subRouteOpen && (
              <IconButton className={styles.iconButtonStyles}>
                <ExpandMore />
              </IconButton>
            )}
            {drawerOpen && subRouteOpen && (
              <IconButton className={styles.iconButtonStyles}>
                <ExpandLess />
              </IconButton>
            )}
          </NavigationItemButton>
        </ListItem>

        <Collapse
          in={subRouteOpen}
          timeout="auto"
          unmountOnExit
          className={styles.collapse}
          component={"ul"}
        >
          {adminSubroutes.map((subroute: any) => (
            <Restricted
              key={subroute.name + "_restriction"}
              permissionRequired={subroute.permission}
            >
              <ListItem
                disablePadding
                className={drawerOpen ? "navigationItemOpen" : "navigationItem"}
                key={subroute.name}
              >
                <NavigationNavLink to={"/admin/" + subroute.path}>
                  <NavigationItemButton
                    drawerOpen={drawerOpen}
                    onClick={(event: any) =>
                      handleListSoubrouteItemClick(event, subroute.index)
                    }
                    selected={selectedIndex === subroute.index}
                    pl={1}
                  >
                    <NavigationListItemContent
                      drawerOpen={drawerOpen}
                      primaryText={subroute.name}
                      icon={subroute.icon}
                    />
                  </NavigationItemButton>
                </NavigationNavLink>
              </ListItem>
            </Restricted>
          ))}
        </Collapse>
      </>
    );
  } else return <></>;
};

export default AdminRoutes;
