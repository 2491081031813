import React, { FC } from "react";
import Restricted from "../../consumer/restricted";
import ProbeTable from "../../components/probe/probeTable";

const ProbeList: FC = () => {
  return (
    <Restricted permissionRequired="frontend_view_probe">
      <ProbeTable />
    </Restricted>
  );
};

export default ProbeList;
