import styles from './radioButtons.module.css';

interface Props {
  onChange?: (target: any) => void;
  name?: string;
  choices?: any;
  required?: boolean;
  mandatory?: boolean;
  label?: string;
  defaultValue?: any;
  readonly?: boolean;
  boolOption?: boolean;
}
export default function RadioButtons(props: Props) {
  let items: any = [];
  const itemsRendered = [];

  const onChangeHandler = function (event: any) {
    props.onChange?.({ target: { id: props.name, value: event.target.value } });
  };

  if (Object.prototype.hasOwnProperty.call(props, "choices")) {
    items = props.choices;
  } else if (Object.prototype.hasOwnProperty.call(props, "boolOption")) {
    items.push({ name: "yes", label: "Ja" });
    items.push({ name: "no", label: "Nein" });
    items.push({ name: "noStatement", label: "Keine Angabe" });
  } else {
    items.push({ name: "yes", label: "Ja" });
    items.push({ name: "no", label: "Nein" });
  }

  for (const key in items) {
    let checked = false;
    if (items[key].name === props.defaultValue) {
      checked = true;
    }
    itemsRendered.push(
      <div key={items[key].name} className={styles.inputWrapper}>
        <input
          type="radio"
          readOnly={props.readonly}
          id={props.name + items[key].name}
          value={items[key].name}
          name={props.name}
          required={props.required}
          onChange={onChangeHandler}
          // defaultChecked={checked}
          checked={checked}
        />
        <label htmlFor={props.name + items[key].name}>{items[key].label}</label>
      </div>
    );
  }

  return (
    <>
      <label>
        {props.mandatory === true ? `${props.label + "\u{002A}"}` : props.label}
      </label>
      <div className={styles.radioButtonsContainer}>{itemsRendered}</div>
    </>
  );
}
