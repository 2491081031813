import React, { FC, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import GenericErrorMessageModal from "../forms/errorHandling/genericErrorMessageModal";
import { t } from "i18next";
import GenericCrudTable from "../tables/genericCrudTable";
import { IconButton, Tooltip } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { api } from "../../helper/api";
import styles from "./buildingTable.module.css";

interface Props {
  title?: string;
}

const BuildingTable: FC<Props> = () => {
  const [error, setError] = useState<any>();
  const [refreshTable, setRefreshTable] = useState<any>(false);
  const [companyName, setCompanyName] = useState<string>("");

  const { subjectId } = useParams() as {
    subjectId: string;
  };
  const navigate = useNavigate();

  const additionalUrlParameters: any = {};
  if (subjectId) {
    additionalUrlParameters["subject"] = subjectId;
  }

  useEffect(() => {
    if (subjectId) {
      api.genericApiRequest({
        entity: "subject",
        entityId: subjectId,
        method: "get",
        parametersToRender: { depth: "0" },
        successHandler: (res: any) => {
          setCompanyName(res.data.name);
        },
        failHandler: (error: any) => {
          setError(error);
        },
      });
    }
  }, [subjectId]);

  const handleNavigateBack = () => {
    navigate("/admin/companyList");
  };

  const setMainBuildingOnManager = (
    data: any,
    skipQuestion: false | undefined
  ) => {
    if (!skipQuestion) {
      if (
        /*eslint-disable */
        !confirm(
          t("building_set_main_building_question", { building: data.name })
        )
        /*eslint-enable */
      ) {
        return;
      }
    }
    const result = { main_building: data.id };
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    api.genericApiRequest({
      method: "put",
      entity: "subject",
      entityId: data.subject.id,
      config: config,
      submitData: result,
      successHandler: () => {
        setRefreshTable(true);
      },
      failHandler: (error: any) => {
        setError(error);
      },
    });
  };

  let heading = t("buildings");
  if (companyName !== "") {
    heading = t("buildings") + ` - ${companyName}`;
  }

  return (
    <>
      {subjectId && (
        <Tooltip placement="right" title={t("back_to", { name: t("company") })}>
          <IconButton
            onClick={handleNavigateBack}
            className={styles.backButton}
          >
            <ArrowBackIcon />
          </IconButton>
        </Tooltip>
      )}
      <GenericCrudTable
        allowColumnFiltering={true}
        showColumnFilterbyDefault={true}
        heading={heading}
        companySubjectId={subjectId}
        entity="building"
        additionalUrlParameter={additionalUrlParameters}
        setMainBuildingOnManager={setMainBuildingOnManager}
        refreshTable={refreshTable}
        columns={[
          {
            header: "ID",
            accessorKey: "id",
            id: "id",
            hideField: true,
            enableHiding: false,
            enableGlobalFilter: false,
          },
          {
            header: t("building_name"),
            accessorKey: "name",
            id: "name",
            enableForCreate: true,
            required: true,
          },
          {
            header: t("building_street_house"),
            accessorKey: "street_house",
            id: "street_house",
            enableForCreate: true,
            required: true,
          },
          {
            header: t("building_zip"),
            accessorKey: "zip",
            id: "zip",
            enableForCreate: true,
            required: true,
          },
          {
            header: t("building_city"),
            accessorKey: "city",
            id: "city",
            enableForCreate: true,
            required: true,
          },
          {
            header: t("building_contact_name"),
            accessorKey: "contact_name",
            id: "contact_name",
            enableForCreate: true,
          },
          {
            header: t("building_contact_email"),
            accessorKey: "contact_email",
            customColumnFilterId: "contact_email",
            customFilterParameter: "_icontains",
            id: "contact_email",
            enableForCreate: true,
          },
          {
            header: t("building_contact_phone"),
            accessorKey: "contact_phone",
            id: "contact_phone",
            enableForCreate: true,
          },
          {
            header: t("building_parking_situation"),
            accessorKey: "parking_situation",
            id: "parking_situation",
            enableForCreate: true,
          },
          {
            header: t("building_additional_information"),
            accessorKey: "additional_information",
            id: "additional_information",
            enableForCreate: true,
            textarea: true,
          },
        ]}
      />
      {error && error !== "" && (
        <GenericErrorMessageModal
          title={t("error_occurred")}
          error={error}
          onClosehandler={() => {
            setError("");
          }}
        />
      )}
    </>
  );
};

export default BuildingTable;
