import { Route, Routes } from "react-router-dom";
import { adminSubrouteContent } from "../components/navigation/adminSubRoutes";

export default function Admin() {
  return (
    <>
      <Routes>
        {adminSubrouteContent.map((content: any) => (
          <Route
            path={content.path}
            element={content.element}
            key={content.path}
          />
        ))}
      </Routes>
    </>
  );
}
