import React, { FC, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import Button from "./inputs/button";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Backdrop } from "@mui/material";
import { renderToStaticMarkup } from "react-dom/server";
import Background from "../../icons/background";
import "../styles/login.css";
import { api } from "../../helper/api";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import PasswordInput from "./inputs/passwordInput";
import { useForm } from "react-hook-form";
import CheckboxWithControl from "./inputs/checkboxWithControl";
import GenericErrorMessageModal from "./errorHandling/genericErrorMessageModal";
import { CircleLoader } from "react-spinners";
import styles from './registerForm.module.css';

interface Props {
  title?: string;
}

const RegisterForm: FC<Props> = () => {
  const [error, setError] = useState<any>("");
  const [registrationSuccessful, setRegistrationSuccessful] =
    useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { t } = useTranslation();
  const { userId, requestToken } = useParams() as {
    userId: string;
    requestToken: string;
  };
  const navigate = useNavigate();
  const theme = useTheme();
  const { handleSubmit, control, watch, getValues } = useForm({
    mode: "onChange",
    defaultValues: {
      password: "",
      confirmedPassword: "",
      confirmNewsletter: false,
      confirmPrivacyPolicy: false,
      confirmTelemetry: false,
    },
  });

  const svgString = encodeURIComponent(renderToStaticMarkup(<Background />));
  const passwordRegex = new RegExp(
    /^(?=.*[0-9])(?=.*[!=?\-_+/&$#*.,:;]).{10,}$/
  );
  const newPassword = watch("password");

  const loginWrapperStyle = {
    background: `url('data:image/svg+xml;utf8, ${svgString}')`
  };

  const onSubmit = (data: any) => {
    setIsLoading(true);
    const result = {
      password: data.password,
      token: requestToken,
      agree_to_data_collection: data.confirmPrivacyPolicy,
      newsletter_accepted: data.confirmNewsletter,
      telemetry_accepted: data.confirmTelemetry,
    };
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const additionalRouteParts: any = {};
    additionalRouteParts["reset_password"] = "/";
    api.genericApiRequest({
      entity: "user",
      method: "put",
      successHandler: () => {
        setIsLoading(false);
        setRegistrationSuccessful(true);
      },
      failHandler: (error: any, status: any) => {
        if (status === 401) {
          setError(t("registration_expired_link"));
        } else setError(error);
      },
      entityId: userId,
      additionalRouteParts: additionalRouteParts,
      submitData: result,
      config: config,
    });
  };

  const formWrapperClass = useMediaQuery(theme.breakpoints.up("sm"))
  ? `${styles.registerFormWrapper} ${styles.registerFormWrapperWide}`
  : styles.registerFormWrapper;

  const additionalPasswordCheckWithAlert = (
    pwd: any,
    confirmedPwd: any,
    regEx: RegExp
  ) => {
    if (pwd !== confirmedPwd) {
      alert(t("password_dont_match"));
    } else if (!regEx.test(pwd)) {
      alert(t("password_requirements"));
    }
  };

  return (
    <>
      <div className={`${styles.loginWrapper}`} style={loginWrapperStyle}>
        {isLoading && (
          <Backdrop
            className={styles.backdrop}
            open={true}
          >
            <div className={styles.loaderContainer}>
              <CircleLoader color="#ff6428" size={150} />
            </div>
          </Backdrop>
        )}
        <div className={formWrapperClass}>
          {registrationSuccessful ? (
            <section className="successNotification">
              <h2>
                {t("thank_you")}
                <br /> {t("registration_successful")}
              </h2>
              <p>{t("click_here_to_login")}</p>
              <Button
                type="button"
                title={t("login")}
                variant="contained"
                className="register_navigateButton"
                onClick={() => navigate("/login/")}
              />
            </section>
          ) : (
            <form
              onSubmit={handleSubmit(onSubmit)}
              className="registerForm"
              noValidate
            >
              <h1 className="heading">{t("register")}</h1>
              <PasswordInput
                label={
                  <div>
                    <span className={styles.displayBlock}>
                      {t("password_registration")}
                    </span>
                    <span>{t("password_requirements")}</span>
                  </div>
                }
                id={t("password_registration")}
                title={t("password_registration")}
                dynamicLabel={false}
                control={control}
                name={"password"}
                required={true}
                requiredErrorMessage={t("field_required")}
                pattern={passwordRegex}
                patternErrorMessage={t("password_requirements")}
                errorFontColor={"#FFF"}
              />

              <PasswordInput
                id={t("password_registration_confirm")}
                title={t("password_registration_confirm")}
                label={t("password_registration_confirm")}
                dynamicLabel={false}
                control={control}
                name={"confirmedPassword"}
                required={true}
                requiredErrorMessage={t("field_required")}
                errorFontColor={"#FFF"}
                validationRule={newPassword}
                validationRuleErrorMessage={t("password_dont_match")}
              />

              <section className="checkboxWrapper">
                <CheckboxWithControl
                  control={control}
                  id={t("confirm_privacy_policy")}
                  required={true}
                  requiredErrorMessage={t("confirm_privacy_policy_warning")}
                  className={styles.checkboxStyle}
                  name={"confirmPrivacyPolicy"}
                  errorFontColor={"#FFF"}
                  label={
                    <>
                      <span className={styles.checkboxWithControl_labelSpan}>
                        <Trans i18nKey="confirm_privacy_policy">
                          confirm_privacy_policy
                          <Link
                            to="/datenschutz"
                            target="_blank"
                            className="privacyPolicyLink"
                          />
                        </Trans>
                      </span>
                    </>
                  }
                />
                <CheckboxWithControl
                  control={control}
                  id={t("confirm_newsletter")}
                  name={"confirmNewsletter"}
                  className={styles.checkboxStyle}
                  label={
                    <>
                      <span className={styles.checkboxWithControl_labelSpan}>
                        <Trans i18nKey="confirm_newsletter" />
                      </span>
                    </>
                  }
                />
                <CheckboxWithControl
                  id={t("confirm_telemetry")}
                  name={"confirmTelemetry"}
                  className={styles.checkboxStyle}
                  control={control}
                  label={
                    <>
                      <span className={styles.checkboxWithControl_labelSpan}>
                        <Trans i18nKey="confirm_telemetry" />
                      </span>
                    </>
                  }
                />
              </section>
              <div className={styles.centerStyle}>
                <Button
                  type="submit"
                  title={t("save")}
                  variant="contained"
                  className="register_submitButton"
                  onClick={() => {
                    additionalPasswordCheckWithAlert(
                      getValues("password"),
                      getValues("confirmedPassword"),
                      passwordRegex
                    );
                  }}
                />
              </div>
              <div className={styles.contactUsContainer}>
                <span>
                  {t("contact_if_problems_with_registration") + " "}
                  <a className={styles.emailLink} href="mailto:service@kaerhealth.com">
                    {t("email_service_kaer")}
                  </a>
                </span>
              </div>
            </form>
          )}
        </div>

        {error && error !== "" && (
          <GenericErrorMessageModal
            title={t("error_occurred")}
            error={error}
            onClosehandler={() => {
              setError("");
              setIsLoading(false);
            }}
          />
        )}
      </div>
    </>
  );
};

export default RegisterForm;
