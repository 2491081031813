import { createSlice } from "@reduxjs/toolkit";

export interface ChildrenState {
  value: string | number;
  label: string;
  subjectId: string | number;
}

const initialState: any = [];

const logoutSlice = createSlice({
  name: "logout",
  initialState,
  reducers: {
    logout: () => {
      localStorage.removeItem("persist:setParent");
    },
  },
});
export const { logout } = logoutSlice.actions;
export default logoutSlice.reducer;
