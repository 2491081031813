import { FC, useEffect, useState } from "react";
import { DropDownAsync } from "./dropDownAsync";
import { api } from "../../../helper/api";
import { useDispatch, useSelector } from "react-redux";
import { setSubjectSearch } from "../../state/subjectSearch/subjectSearchSlice";
import { RootState } from "../../state/store";
import GenericErrorMessageModal from "../errorHandling/genericErrorMessageModal";
import { t } from "i18next";

interface Props {
  subdomain?: any;
  options?: any;
  wrapperStyle?: any;
  labelStyle?: any;
  wrapperClassName?: string;
  labelClassName?: string;
  onChange?: any;
  preExistingValue?: any;
  disabled?: any;
  name?: any;
  error?: any;
  customRef?: any;
  resetInput?: any;
  isClearable?: boolean;
  customLabel?: string;
  queryParameter?: "users" | "companies";
  companyIdforParent?: string;
  autofocus?: boolean;
  useGlobalState?: boolean;
  placeholder?: any;
  companyId?: any;
  isMulti?: boolean;
  additionalSearchParameters?: any;
}

export const DropDownSubjectSearch: FC<Props> = ({
  subdomain,
  options,
  wrapperStyle,
  labelStyle,
  wrapperClassName,
  labelClassName,
  onChange,
  preExistingValue,
  disabled,
  name,
  error,
  customRef,
  resetInput,
  isClearable,
  customLabel,
  queryParameter,
  companyIdforParent,
  autofocus,
  useGlobalState,
  placeholder,
  companyId,
  isMulti,
  additionalSearchParameters,
}) => {
  const [localPreExistingValue, setLocalPreExistingValue] =
    useState<any>(preExistingValue);
  const [apiError, setApiError] = useState<any>();

  const dispatch = useDispatch();

  const onChangeHandler = (selectedOption: any) => {
    if (useGlobalState) {
      if (
        selectedOption &&
        typeof selectedOption === "object" &&
        selectedOption.value !== persistedSubjectSearchValue.value
      ) {
        dispatch(setSubjectSearch(selectedOption));
      }
    } else {
      onChange(selectedOption);
    }
  };

  const persistedSubjectSearchValue = useSelector(
    (state: RootState) => state.subjectSearch
  );
  useEffect(() => {
    if (
      Object.keys(persistedSubjectSearchValue).length !== 0 &&
      useGlobalState
    ) {
      setLocalPreExistingValue(persistedSubjectSearchValue);
      onChange(persistedSubjectSearchValue);
    }
    if (
      preExistingValue &&
      preExistingValue !== undefined &&
      preExistingValue !== null
    ) {
      if (Array.isArray(preExistingValue) && preExistingValue.length !== 0) {
        if (
          preExistingValue?.[0].value !== localPreExistingValue.value &&
          preExistingValue?.[0].label !== localPreExistingValue.label
        ) {
          setLocalPreExistingValue(preExistingValue);
          onChange(preExistingValue);
        }
      } else if (
        preExistingValue.value !== localPreExistingValue.value &&
        preExistingValue.label !== localPreExistingValue.label
      ) {
        setLocalPreExistingValue(preExistingValue);
        onChange(preExistingValue);
      }
    }
  }, [persistedSubjectSearchValue, preExistingValue]);

  let label = "Subject";
  if (customLabel || customLabel === "") {
    label = customLabel;
  }

  return (
    <>
      <DropDownAsync
        dataCallback={(inputValue: string, finishCallback: any) => {
          const additionalUrlParameters: any = {};

          additionalUrlParameters["search"] = inputValue;
          if (queryParameter) {
            additionalUrlParameters["show"] = queryParameter;
          }
          if (additionalSearchParameters) {
            for (const key in additionalSearchParameters) {
              additionalUrlParameters[key] = additionalSearchParameters[key];
            }
          }
          if (subdomain === "admin" && inputValue.length > 2) {
            api.genericApiRequest({
              method: "get",
              entity: "subject/quicksearch",
              parametersToRender: {
                additionalUrlParameters: additionalUrlParameters,
                depth: "0",
              },
              successHandler: (res: any) => {
                const subjects = [];
                let users;
                const companies = res.data["companies"];

                if (companyId && companyId !== undefined) {
                  users = res.data["users"].filter(
                    (x: any) => x.company_id === companyId
                  );
                } else users = res.data["users"];

                const userArray = [];
                const companyArray = [];
                for (const key in users) {
                  const label = users[key].name;
                  const eMail = users[key].email;
                  const value = users[key].subject_id;
                  const treeId = users[key].tree_Id;
                  const companyName = users[key].company_name;

                  let userLabel = label;
                  if (eMail !== "") {
                    userLabel = label + " " + `(${eMail} - ${companyName}) `;
                  }
                  userArray.push({
                    value: value,
                    label: userLabel,
                    treeId: treeId,
                  });
                }
                for (const key in companies) {
                  const label = companies[key].name;
                  const eMail = companies[key].email;
                  const value = companies[key].subject_id;
                  const treeId = companies[key].tree_Id;
                  const companyId = companies[key].id;
                  const isParent = companies[key].is_parent;

                  let companyLabel = label;
                  if (eMail !== "") {
                    companyLabel =
                      label + " (Firma) " + `(${eMail} - ${label})`;
                    if (isParent) {
                      companyLabel += " (Parent)";
                    }
                  }
                  companyArray.push({
                    value: value,
                    label: companyLabel,
                    treeId: treeId,
                    companyId: companyId,
                    isParent: isParent,
                  });
                }
                if (companyIdforParent && companyIdforParent !== "") {
                  const filtered = companyArray.filter((company: any) => {
                    return company.companyId !== companyIdforParent;
                  });
                  subjects.push(...filtered, ...userArray);
                } else subjects.push(...companyArray, ...userArray);

                finishCallback(subjects);
              },
              failHandler: (error: any) => {
                setApiError(error);
              },
            });
          } else {
            finishCallback([]);
          }
        }}
        wrapperStyle={wrapperStyle}
        labelStyle={labelStyle}
        wrapperClassName={wrapperClassName}
        labelClassName={labelClassName}
        label={label}
        options={options}
        onChange={onChangeHandler}
        isSearchable={true}
        preExistingValue={localPreExistingValue}
        disabled={disabled}
        name={name}
        error={error}
        ref={customRef}
        isClearable={isClearable}
        resetInput={resetInput}
        autofocus={autofocus}
        placeholder={placeholder}
        isMulti={isMulti}
      />
      {apiError && apiError !== "" && (
        <GenericErrorMessageModal
          title={t("error_occurred")}
          error={error}
          onClosehandler={() => {
            setApiError("");
          }}
        />
      )}
    </>
  );
};

export default DropDownSubjectSearch;
