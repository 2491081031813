import React, { FC } from "react";
import DropDown from "../forms/inputs/dropDown";
import { t } from "i18next";
import DefaultInput from "../forms/inputs/defaultInput";
import { Grid } from "@mui/material";
import { TextArea } from "../forms/inputs/textArea";
import styles from "./appointmentBuildingInformation.module.css";

interface props {
  appointment?: any;
  formValues?: any;
  handleInputChange?: any;
  buildingOptions?: any;
  selectedBuilding?: any;
  handleBuildingChange?: any;
  withGrid?: boolean;
}

const AppointmentBuildingInformation: FC<props> = ({
  appointment,
  formValues,
  handleInputChange,
  buildingOptions,
  selectedBuilding,
  handleBuildingChange,
  withGrid,
}) => {
  const readOnly =
    appointment && appointment?.appointmentslot_set?.length !== 0
      ? true
      : false;

  return (
    <>
      {withGrid ? (
        <>
          <Grid item xs={12}>
            <DropDown
              options={buildingOptions}
              label={t("buildings")}
              name="building"
              onChange={handleBuildingChange}
              defaultValue={selectedBuilding}
              disabled={readOnly}
            />
          </Grid>
          <Grid item xs={12}>
            <DefaultInput
              name="contact_name"
              label={t("building_contact_name")}
              value={formValues.contact_name}
              onChange={(e: any) => handleInputChange(e)}
              autoComplete="off"
              readonly={readOnly}
              inputClassName={styles.defaultInput}
            />
          </Grid>
          <Grid item xs={12}>
            <DefaultInput
              name="contact_email"
              label={t("building_contact_email")}
              value={formValues.contact_email}
              onChange={(e: any) => handleInputChange(e)}
              autoComplete="off"
              readonly={readOnly}
              inputClassName={styles.defaultInput}
            />
          </Grid>
          <Grid item xs={12}>
            <DefaultInput
              name="contact_phone"
              label={t("building_contact_phone")}
              value={formValues.contact_phone}
              onChange={(e: any) => handleInputChange(e)}
              autoComplete="off"
              readonly={readOnly}
              inputClassName={styles.defaultInput}
            />
          </Grid>
          <Grid item xs={12}>
            <TextArea
              withLabel={true}
              name="parking_situation"
              label={t("building_parking_situation")}
              value={formValues.parking_situation}
              onChange={(e: any) => handleInputChange(e)}
              readonly={readOnly}
            />
          </Grid>
          <Grid item xs={12}>
            <TextArea
              withLabel={true}
              name="additional_information"
              label={t("building_additional_information")}
              value={formValues.additional_information}
              onChange={(e: any) => handleInputChange(e)}
              readonly={readOnly}
            />
          </Grid>
        </>
      ) : (
        <>
          <DropDown
            options={buildingOptions}
            label={t("buildings")}
            name="building"
            onChange={handleBuildingChange}
            defaultValue={selectedBuilding}
            disabled={readOnly}
          />
          <DefaultInput
            name="contact_name"
            label={t("building_contact_name")}
            value={formValues.contact_name}
            onChange={(e: any) => handleInputChange(e)}
            autoComplete="off"
            readonly={readOnly}
            inputClassName={styles.defaultInput}
          />
          <DefaultInput
            name="contact_email"
            label={t("building_contact_email")}
            value={formValues.contact_email}
            onChange={(e: any) => handleInputChange(e)}
            autoComplete="off"
            readonly={readOnly}
            inputClassName={styles.defaultInput}
          />
          <DefaultInput
            name="contact_phone"
            label={t("building_contact_phone")}
            value={formValues.contact_phone}
            onChange={(e: any) => handleInputChange(e)}
            autoComplete="off"
            readonly={readOnly}
            inputClassName={styles.defaultInput}
          />
          <TextArea
            withLabel={true}
            name="parking_situation"
            label={t("building_parking_situation")}
            value={formValues.parking_situation}
            onChange={(e: any) => handleInputChange(e)}
            readonly={readOnly}
          />
          <TextArea
            withLabel={true}
            name="additional_information"
            label={t("building_additional_information")}
            value={formValues.additional_information}
            onChange={(e: any) => handleInputChange(e)}
            readonly={readOnly}
          />
        </>
      )}
    </>
  );
};

export default AppointmentBuildingInformation;
