import React from "react";
import InputTooltip, { placement } from "../../general/tooltip";
import styles from './dateFieldWithRef.module.css';

interface Props {
  mandatory?: boolean;
  label?: string;
  readonly?: boolean;
  name?: string;
  required?: boolean;
  onChange?: (event: any) => void;
  defaultValue?: any;
  tooltip?: boolean;
  placement?: placement;
  tooltipMessage?: any;
}

export const DateFieldWithRef = React.forwardRef<HTMLInputElement, Props>(
  function DateFieldWithRef(props, ref) {

    return (
      <div>
        <label className={styles.label}>
          {props.required === true
            ? `${props.label + "\u{002A}"}`
            : props.label}
          {props.tooltip && (
            <InputTooltip
              tooltipMessage={props.tooltipMessage}
              placement={props.placement}
            />
          )}
        </label>
        <input
          ref={ref}
          name={props.name}
          type="date"
          readOnly={props.readonly}
          id={props.name}
          // required={props.required}
          onChange={props.onChange}
          className={styles.dateInput}
        ></input>
      </div>
    );
  }
);
