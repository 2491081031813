import { CloseRounded } from "@mui/icons-material";
import { Button } from "@mui/material";
import { SnackbarKey, useSnackbar } from "notistack";
import React, { FC, useEffect } from "react";

interface Props {
  message: string | any;
  variant: "success" | "error" | "warning" | "info" | undefined;
  action?: React.ReactNode;
  handleCloseSnackbar: () => void;
}

const GenericNotification: FC<Props> = ({
  message,
  variant,
  // action,
  handleCloseSnackbar,
}) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  let snackMessage: any;

  if (typeof message === "string") {
    snackMessage = message;
  } else if (typeof message === "object") {
    snackMessage = Object.entries(message).map(
      ([fieldName, msg]: any) => fieldName + ":" + msg
    );
  }
  if (Array.isArray(snackMessage)) {
    snackMessage = snackMessage[0];
  }
  const snackbarOptions: any = {
    variant: variant,
    action: (key: SnackbarKey) => (
      <>
        {/* {action} */}
        <Button
          onClick={() => {
            closeSnackbar(key);
            handleCloseSnackbar();
          }}
          size="small"
          sx={{ color: "#fff" }}
        >
          <CloseRounded />
        </Button>
      </>
    ),
  };

  useEffect(() => {
    enqueueSnackbar(snackMessage, snackbarOptions);
  }, [enqueueSnackbar, message, snackbarOptions]);

  return null;
};

export default GenericNotification;
