import { useTranslation } from "react-i18next";
import GenericCrudTable from "../../components/tables/genericCrudTable";
import Restricted from "../../consumer/restricted";

export default function ExaminationList() {
  const { t } = useTranslation();

  const replaceDate = (id: any) => {
    const date = new Date(id).toLocaleString();
    return date;
  };
  return (
    <Restricted permissionRequired="frontend_view_examination">
      <GenericCrudTable
        allowColumnFiltering={true}
        showColumnFilterbyDefault={true}
        heading={t("examinations")}
        entity={"examination"}
        columns={[
          { header: "ID", accessorKey: "id", id: "id" },
          {
            header: t("name"),
            accessorKey: "name",
            id: "name",
            enableForCreate: true,
            required: true,
            errorMessage: t("field_required"),
          },
          {
            header: t("description"),
            accessorKey: "description",
            id: "description",
            enableForCreate: true,
            textarea: true,
          },
          {
            header: t("external_title"),
            accessorKey: "external_title",
            id: "external_title",
            enableForCreate: true,
          },
          {
            header: t("category_set"),
            accessorKey: "category_set",
            id: "category_set",
            customColumnFilterId: "category_set__name",
            required: true,
            errorMessage: t("field_required"),
            accessorFn: (row: any) => {
              const categoryList = [];
              if (row.category_set) {
                for (const key in row.category_set) {
                  if (row.category_set[key].name) {
                    categoryList.push(row.category_set[key].name);
                  }
                }
              }

              return categoryList.join(", ");
            },
            enableForCreate: true,
            multiselect: true,
            entity: "mainCategory",
          },
          {
            header: t("is_sensitive"),
            id: "is_sensitive",
            accessorKey: "is_sensitive",
            enableForCreate: true,
            selectOptions: [
              { value: true, label: t("yes"), id: "true" },
              { value: false, label: t("no"), id: "false" },
            ],
            accessorFn: (row: any) => {
              if (row.is_sensitive) {
                return t("yes");
              } else {
                return t("no");
              }
            },
            filterVariant: "checkbox",
          },
          {
            header: t("created_on"),
            accessorKey: "created_on",
            id: "created_on",
            sortingFn: "datetime",
            filterVariant: "date",
            Cell: ({ cell }: any) => {
              return <span>{replaceDate(cell.getValue())}</span>;
            },
          },
          {
            header: t("created_by"),
            accessorKey: "created_by",
            customColumnFilterId: "created_by_name",

            id: "created_by",
            Cell: ({ cell }: any) => {
              return <span>{cell.getValue().name}</span>;
            },
          },
          {
            header: t("modified_on"),
            accessorKey: "modified_on",
            id: "modified_on",
            sortingFn: "datetime",
            filterVariant: "date",
            Cell: ({ cell }: any) => {
              return <span>{replaceDate(cell.getValue())}</span>;
            },
          },
          {
            header: t("modified_by"),
            accessorKey: "modified_by",
            customColumnFilterId: "modified_by_name",

            id: "modified_by",
            Cell: ({ cell }: any) => {
              return <span>{cell.getValue().name}</span>;
            },
          },
        ]}
      />
    </Restricted>
  );
}
