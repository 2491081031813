import { Grid, Tooltip } from "@mui/material";
import React, { FC, useEffect, useState } from "react";
import GeneralListItem from "../general/generaListItem";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useNavigate } from "react-router-dom";
import { Collapse, IconButton, IconButtonProps, styled } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import styles from "./appointmentTreeItemForms.module.css";
import CheckIcon from "@mui/icons-material/Check";

interface Props {
  formsToShow: "hidden" | "visible";
  categoryExaminationSet?: any;
}

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const AppointmentTreeItemForms: FC<Props> = ({
  formsToShow,
  categoryExaminationSet,
}) => {
  const [widgetForms, setWidgetForms] = useState<any>([]);
  const [widgetMeasurements, setWidgetMeasurements] = useState<any>([]);
  const [formsInfo, setFormsInfo] = useState<any>([]);
  const [invisibleFormsOpen, setInvisibleFormsOpen] = useState<boolean>(false);

  const navigate = useNavigate();

  useEffect(() => {
    const tempWidgetForms: any = [];
    const tempMeasurements = [];
    const formNames: any = [];
    for (const examination of categoryExaminationSet) {
      if (examination.finding_set) {
        for (const finding of examination.finding_set) {
          if (finding.measurement_set) {
            for (const measurement of finding.measurement_set) {
              tempMeasurements.push(measurement);
            }
          }
          if (finding.form_set) {
            for (const form of finding.form_set) {
              if (!formNames.includes(form.name)) {
                form["appointmentId"] = finding.appointment;
                form["findingId"] = finding.id;
                form["examinationId"] = examination.examination;
                tempWidgetForms.push(form);
                formNames.push(form.name);
              }
            }
          }
        }
      }
    }

    setWidgetForms(tempWidgetForms);
    setWidgetMeasurements(tempMeasurements);
  }, []);
  useEffect(() => {
    setFormsInfo(getMeasurementsInfo());
  }, [widgetForms, widgetMeasurements]);

  const getMeasurementsInfo = () => {
    return widgetForms.map((form: any) => {
      const examination = form?.examinationId;
      const totalDefinitions =
        form.measurementdefinitiontoform_set?.length || 0;
      const filledDefinitions =
        form.measurementdefinitiontoform_set.filter((definition: any) => {
          const defName = definition?.measurement_definition.name;
          const measurement = widgetMeasurements.find(
            (m: any) =>
              m.name === defName && m.findings.includes(form.findingId)
          );
          return (
            measurement &&
            measurement.value !== "" &&
            measurement.value !== null &&
            measurement.value !== undefined
          );
        }).length || 0;

      return {
        id: form.id,
        form,
        totalDefinitions,
        filledDefinitions,
        examination,
      };
    });
  };
  const renderItems = () => {
    const sortedFormsByExamination = formsInfo.sort((a: any, b: any) =>
      a.examination.localeCompare(b.examination)
    );

    const filteredVisibleForms = sortedFormsByExamination.filter(
      (form: any) => !form.form?.hidden
    );
    const groupedVisibleFormsByExamination = filteredVisibleForms.reduce(
      (acc: any, obj: any) => {
        const key = obj.examination;
        if (!acc[key]) {
          acc[key] = [];
        }
        acc[key].push(obj);
        return acc;
      },
      {}
    );
    Object.keys(groupedVisibleFormsByExamination).forEach((examination) => {
      groupedVisibleFormsByExamination[examination].sort((a: any, b: any) => {
        if (a.form.widget_id === "anamnese_allgemein") return -1;
        if (b.form.widget_id === "anamnese_allgemein") return 1;
        return 0;
      });
    });

    const filteredHiddenForms = sortedFormsByExamination.filter(
      (form: any) => form.form?.hidden
    );
    const groupedHiddenFormsByExamination = filteredHiddenForms.reduce(
      (acc: any, obj: any) => {
        const key = obj.examination;
        if (!acc[key]) {
          acc[key] = [];
        }
        acc[key].push(obj);
        return acc;
      },
      {}
    );

    if (formsToShow === "visible") {
      const examinationKeysForVisibleForms = Object.keys(
        groupedVisibleFormsByExamination
      );
      return examinationKeysForVisibleForms.map(
        (examination: any, index: any) => (
          <React.Fragment key={index}>
            {groupedVisibleFormsByExamination[examination].map(
              (currentForm: any) => {
                const label = (
                  <span className={styles.label}>
                    {currentForm.filledDefinitions ===
                    currentForm.totalDefinitions ? (
                      <CheckIcon />
                    ) : (
                      `${currentForm.filledDefinitions}/${currentForm.totalDefinitions}`
                    )}
                  </span>
                );

                return (
                  <GeneralListItem
                    key={currentForm.id}
                    allowHover={true}
                    onClickElement="wrapper"
                    onClick={() =>
                      navigate(
                        "/appointmentDashboard/" +
                          currentForm.form?.appointmentId +
                          "/" +
                          currentForm.id +
                          "/" +
                          currentForm.form?.findingId
                      )
                    }
                    wrapperClassName={styles.generalListItemWrapper}
                    hideButton={true}
                  >
                    <Tooltip title={"Formular öffnen"} placement="top-end">
                      <div className={styles.itemWrapper}>
                        <h6 className={styles.noMargin}>
                          {currentForm.form?.name}
                        </h6>
                        <div className={styles.itemContent}>
                          {label}
                          <ArrowForwardIcon />
                        </div>
                      </div>
                    </Tooltip>
                  </GeneralListItem>
                );
              }
            )}
            {index < examinationKeysForVisibleForms.length - 1 && <hr />}
          </React.Fragment>
        )
      );
    }
    if (formsToShow === "hidden" && filteredHiddenForms.length !== 0) {
      const examinationKeysForHiddenForms = Object.keys(
        groupedHiddenFormsByExamination
      );
      return (
        <div>
          <ExpandMore
            expand={invisibleFormsOpen}
            onClick={handleShowInVisibleForms}
            aria-expanded={invisibleFormsOpen}
            aria-label="show more"
          >
            <ExpandMoreIcon />
          </ExpandMore>
          <Collapse
            in={invisibleFormsOpen}
            className={styles.collapseContent}
            timeout="auto"
            unmountOnExit
          >
            {examinationKeysForHiddenForms.map(
              (examination: any, index: any) => (
                <React.Fragment key={index}>
                  {groupedHiddenFormsByExamination[examination].map(
                    (currentForm: any) => {
                      const label = (
                        <span className={styles.label}>
                          {currentForm.filledDefinitions ===
                          currentForm.totalDefinitions ? (
                            <CheckIcon />
                          ) : (
                            `${currentForm.filledDefinitions}/${currentForm.totalDefinitions}`
                          )}
                        </span>
                      );

                      return (
                        <GeneralListItem
                          key={currentForm.id}
                          allowHover={true}
                          onClickElement="wrapper"
                          onClick={() =>
                            navigate(
                              "/appointmentDashboard/" +
                                currentForm.form?.appointmentId +
                                "/" +
                                currentForm.id +
                                "/" +
                                currentForm.form?.findingId
                            )
                          }
                          wrapperClassName={styles.generalListItemWrapper}
                          hideButton={true}
                        >
                          <Tooltip
                            title={"Formular öffnen"}
                            placement="top-end"
                          >
                            <div className={styles.itemWrapperNoAlign}>
                              <h6 className={styles.noMargin}>
                                {currentForm.form?.name}
                              </h6>
                              <div className={styles.itemContentNoAlign}>
                                {label}
                                <ArrowForwardIcon />
                              </div>
                            </div>
                          </Tooltip>
                        </GeneralListItem>
                      );
                    }
                  )}
                </React.Fragment>
              )
            )}
          </Collapse>
        </div>
      );
    }
  };

  const ExpandMore = styled((props: ExpandMoreProps) => {
    // eslint-disable-next-line
    const { expand, ...other } = props;
    return (
      <Grid item xs={12} className={styles.expandMoreButton}>
        <span>Weitere Formulare</span>
        <IconButton {...other} />
      </Grid>
    );
  })(({ theme, expand }) => ({
    transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  }));

  const handleShowInVisibleForms = () => {
    setInvisibleFormsOpen(!invisibleFormsOpen);
  };

  return (
    <Grid xs={12} item>
      <>{renderItems()}</>
    </Grid>
  );
};

export default AppointmentTreeItemForms;
