import React from "react";
import PermissionContext from "../context/permissionContext";
import { useSelector } from "react-redux";
import { RootState } from "../components/state/store";

type Props = {
  children: any;
};

// This provider is intended to be surrounding the whole application.
// It should receive the users permissions as parameter
const PermissionProvider: React.FunctionComponent<Props> = ({ children }) => {
  const currentUser = useSelector((state: RootState) => state.login);
  // Creates a method that returns whether the requested permission is available in the list of permissions
  // passed as parameter
  const isAllowedTo = (permission: string) => {
    if (currentUser && currentUser.permissions) {
      return currentUser.permissions.includes(permission);
    }

    return false;
  };

  // This component will render its children wrapped around a PermissionContext's provider whose
  // value is set to the method defined above
  return (
    <PermissionContext.Provider value={{ isAllowedTo }}>
      {children}
    </PermissionContext.Provider>
  );
};

export default PermissionProvider;
