import { PayloadAction, createAction, createSlice } from "@reduxjs/toolkit";
const clearAction = createAction("clear");

export interface ChildrenState {
  value: string | number;
  label: string;
  subjectId: string | number;
}

const initialState: any = [];

const childrenSlice = createSlice({
  name: "children",
  initialState,
  reducers: {
    setChildren: (state, action: PayloadAction<any>) =>
      (state = action.payload),
  },
  extraReducers: (builder) => {
    builder.addCase(clearAction, () => initialState);
  },
});
export const { setChildren } = childrenSlice.actions;
export default childrenSlice.reducer;
