import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export interface NavigateToState {
  navigate: boolean;
  target: string;
}

const initialState: NavigateToState = {
  navigate: false,
  target: "",
};

const navigateToSlice = createSlice({
  name: "parent",
  initialState,
  reducers: {
    navigateTo: (
      state,
      action: PayloadAction<{
        navigate: boolean;
        target: string;
      }>
    ) => {
      state.navigate = action.payload.navigate;
      state.target = action.payload.target;
    },
  },
});

export const { navigateTo } = navigateToSlice.actions;
export default navigateToSlice.reducer;
