import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from "@mui/material";
import React, { FC, useEffect, useState } from "react";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { useSignOut } from "react-auth-kit";
import { useDispatch } from "react-redux";
import styles from './genericErrorMessageModal.module.css';

interface Props {
  title: string;
  confirmButtonText?: string;
  error: any;
  onClosehandler?: () => void;
}

const GenericErrorMessageModal: FC<Props> = ({
  title,
  confirmButtonText,
  error,
  onClosehandler,
}) => {
  const [modalOpen, setModalOpen] = useState<boolean>(true);
  const signOut = useSignOut();
  const dispatch = useDispatch();

  const combinedSignout = () => {
    dispatch({ type: "clear" });
    signOut();
  };
  useEffect(() => {
    if (error) {
      setModalOpen(true);
    }
  }, [error]);

  let buttonText = "Ok";
  if (confirmButtonText) {
    buttonText = confirmButtonText;
  }

  let message: any = <List></List>;
  if (error === "logout") {
    combinedSignout();
  }
  if (typeof error === "string") {
    message = (
      <ListItem>
        <ListItemAvatar>
          <ErrorOutlineIcon className={styles.errorIcon} />
        </ListItemAvatar>
        <ListItemText primary={error} />
      </ListItem>
    );
  } else if (typeof error === "object") {
    message = Object.entries(error).map(([fieldName, errorMessage]: any) => (
      <ListItem key={fieldName}>
        <ListItemAvatar>
          <ErrorOutlineIcon className={styles.errorIcon} />
        </ListItemAvatar>
        <ListItemText
          primaryTypographyProps={{ className: styles.listItemTextPrimary }}
          primary={errorMessage}
          secondary={fieldName}
        />
      </ListItem>
    ));
  }

  return (
    <Dialog open={modalOpen} scroll="paper">
      <DialogTitle className={styles.dialogTitle}>{title}</DialogTitle>
      <DialogContent dividers>{message}</DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            setModalOpen(false), onClosehandler?.();
          }}
        >
          {buttonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default GenericErrorMessageModal;
