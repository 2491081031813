import React, { FC, useEffect, useState } from "react";
import NavigationDrawer from "./navigationDrawer";
import HeaderBar from "../headerBar";
import {
  useAuthUser,
  useIsAuthenticated,
  useSignIn,
  useSignOut,
} from "react-auth-kit";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { api } from "../../helper/api";
import { setChildren } from "../state/parent/childrenSlice";
import { RootState } from "../state/store";
import "./headerBarWrapper.css";
import GenericErrorMessageModal from "../forms/errorHandling/genericErrorMessageModal";
import { t } from "i18next";

interface Props {
  subdomain: string;
}
const HeaderBarWrapper: FC<Props> = ({ subdomain }) => {
  const [user, setUser] = useState<string | undefined>("");
  const [email, setEmail] = useState<string>("");
  const [companyName, setCompanyName] = useState<string>("");
  const [loginstate, setLoginstate] = useState<number>(0);
  const [loginStateRefreshInterval, setLoginStateRefreshInterval]: any =
    useState();
  const [anchorEl, setAnchorEl] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
  const [error, setError] = useState<any>();

  const openSettings = Boolean(anchorEl);

  const getAuth = useAuthUser();

  const signIn = useSignIn();
  const signOut = useSignOut();

  const isAuthenticated = useIsAuthenticated();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const toggleDrawer = (open: any) => (event: any) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDrawerOpen(open);
  };

  const combinedSignout = () => {
    dispatch({ type: "clear" });
    signOut();
  };
  useEffect(() => {
    if (subdomain === "manage") {
      api.genericApiRequest({
        entity: "me",
        method: "get",
        successHandler: (res: any) => {
          if (res.data.assigned_companies.length > 0) {
            const tempCompanies = [];
            for (const key in res.data.assigned_companies) {
              const company = res.data.assigned_companies[key].company;
              if (company.name != res.data.company_name) {
                tempCompanies.push({
                  value: company.id,
                  label: company.name,
                  subjectId: company.subject_id,
                });
              }
            }
            for (const key in res.data.child_companies) {
              const company = res.data.child_companies[key];
              tempCompanies.push({
                value: company.id,
                label: company.name,
                subjectId: company.subject_id,
              });
            }
            dispatch(setChildren(tempCompanies));
          }
        },
        failHandler: (error: any) => {
          setError(error);
        },
      });
    }
  }, []);

  useEffect(() => {
    if (isAuthenticated()) {
      const auth = getAuth();

      if (auth && auth.email) {
        setLoginstate(1);
        setUser(auth.first_name + " " + auth.last_name);

        setEmail(auth.email);
        setCompanyName(auth.company_name);

        if (loginStateRefreshInterval === undefined) {
          setLoginStateRefreshInterval(
            setInterval(
              () => {
                console.log("refreshing login timeout...");
                api.genericApiRequest({
                  entity: "me",
                  method: "get",

                  successHandler: (res: any) => {
                    if (res.status === 200) {
                      const responseData = res.data;
                      const sessionExpiration = Math.floor(
                        responseData.token_expiry_in / 60
                      );
                      if (sessionExpiration > 0) {
                        signIn({
                          token: responseData.email,
                          expiresIn: sessionExpiration,
                          tokenType: "Session",
                          authState: {
                            email: responseData.email,
                            first_name: responseData.first_name,
                            last_name: responseData.last_name,
                            company_name: responseData.company_name,
                          },
                        });
                      } else {
                        clearInterval(loginStateRefreshInterval);
                        setLoginstate(0);
                        setUser("");
                        setEmail("");
                        setCompanyName("");
                        setLoginStateRefreshInterval(undefined);

                        combinedSignout();
                      }
                    }
                  },
                  failHandler: (error: any, status: any) => {
                    if (status !== 504) {
                      setError(error);
                    }
                  },
                });
              },
              5 * 60 * 1000
            )
          );
        }
      }
    } else {
      if (loginstate === 1) {
        setLoginstate(0);
        setUser("");
        setEmail("");
        setCompanyName("");
        clearInterval(loginStateRefreshInterval);
        setLoginStateRefreshInterval(undefined);
        combinedSignout();
      }
    }
  }, [getAuth, isAuthenticated, loginstate]);

  let selectedCompany = companyName;
  const childName = useSelector((state: RootState) => state.parent.companyName);
  if (childName !== "") {
    selectedCompany = childName;
  }

  const nameParts = user?.split(" ");
  const firstNameInitial = nameParts?.[0] ? nameParts[0][0] : "";
  const lastNameInitial = nameParts?.[1] ? nameParts[1][0] : "";

  const handleOpenSettings = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseSettings = () => {
    setAnchorEl(null);
  };
  const handleNavigateSettings = () => {
    navigate("/usersettings");
    handleCloseSettings();
  };

  const closeDrawer = () => {
    setDrawerOpen(false);
  };

  return (
    <>
      <div className="headerbar_wrapper">
        <HeaderBar
          toggleDrawer={toggleDrawer}
          selectedCompany={selectedCompany}
          handleOpenSettings={handleOpenSettings}
          handleCloseSettings={handleCloseSettings}
          handleNavigateSettings={handleNavigateSettings}
          combinedSignout={combinedSignout}
          signOut={signOut}
          openSettings={openSettings}
          firstNameInitial={firstNameInitial}
          lastNameInitial={lastNameInitial}
          anchorEl={anchorEl}
          user={user}
          email={email}
        />
        <NavigationDrawer
          drawerOpen={drawerOpen}
          toggleDrawer={toggleDrawer}
          subdomain={subdomain}
          closeDrawer={closeDrawer}
        />
      </div>
      {error && error !== "" && (
        <GenericErrorMessageModal
          title={t("error_occurred")}
          error={error}
          onClosehandler={() => {
            setError("");
          }}
        />
      )}
    </>
  );
};

export default HeaderBarWrapper;
