import React, { FC, useEffect, useState } from "react";
import { DotLoader } from "react-spinners";
import GenericErrorMessageModal from "../forms/errorHandling/genericErrorMessageModal";
import { t } from "i18next";
import { IAppointment } from "../../types/Entities";
import { api } from "../../helper/api";
import { Box, Grid, Paper } from "@mui/material";
import AppointmentTreeItemFileList from "./appointmentTreeItemFileList";
import AppointmentTreeItemHeaderbar from "./appointmentTreeItemHeaderbar";
import AppointmentTreeItemSubjectInformation from "./appointmentTreeItemSubjectInformation";
import AppointmentTreeItemWidgets from "./appointmentTreeItemWidgets";
import AppointmentTreeItemUploadButton from "./appointmentTreeItemUploadButton";
import AppointmentTreeItemForms from "./appointmentTreeItemForms";
import styles from "./appointmentTreeView.module.css";
import DocumentTable from "../tables/simpleTableViews/documentTable";

interface Props {
  appointmentId: string;
}
export interface categoryExaminationSet {
  id?: string;
  status?: string;
  appointment?: string;
  category?: string;
  examination?: string;
  category_name?: string;
  examination_name?: string;
  findings?: [];
}
export interface formValues {
  id?: string;
  created_by?: string;
  modified_by?: string;
  created_on?: string;
  modified_on?: string;
  unit?: string;
  name?: string;
  value?: number;
  appointment?: string;
  findings?: [];
}

const AppointmentTreeView: FC<Props> = ({ appointmentId }) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<any>();

  const [currentAppointment, setCurrentAppointment]: any =
    useState<IAppointment>();
  const [categoryExaminationSet, setCategoryExaminationSet] =
    useState<categoryExaminationSet[]>();
  const [executedBy, setExecutedBy] = useState<any>("");
  const [executedByOptions, setExecutedByOptions] = useState<any>([]);
  const [subjectOfAppointment, setSubjectOfAppointment] = useState<any>();

  useEffect(() => {
    if (appointmentId && appointmentId != "") {
      /*eslint-disable */
      loadAppointment(appointmentId, (responseData: any) => {
        /*eslint-enable */
      });
    } else {
      setCurrentAppointment();
    }
  }, [appointmentId]);

  const loadAppointment = (
    appointmentId: any,
    onSuccessHandler: any = undefined
  ) => {
    setIsLoading(true);
    const additionalRouteParts: any = {};
    additionalRouteParts["dashboard"] = "/";

    api.genericApiRequest({
      entity: "appointment",
      method: "get",
      entityId: appointmentId,
      additionalRouteParts: additionalRouteParts,
      successHandler: (res: any) => {
        const currentAppointmentObject = res.data;
        setCurrentAppointment(currentAppointmentObject);
        setExecutedBy(currentAppointmentObject.executed_by?.id);
        setSubjectOfAppointment(currentAppointmentObject?.subject);
        setCategoryExaminationSet(
          currentAppointmentObject.categoryexamination_set
        );

        if (onSuccessHandler) {
          onSuccessHandler(currentAppointmentObject);
        }
        const additionalUserParameters: any = {};
        additionalUserParameters["impersonate_subject"] =
          "11111111-1111-1111-1111-444444444444";
        additionalUserParameters["is_active"] = "true";

        api.genericApiRequest({
          entity: "user",
          method: "get",
          parametersToRender: {
            additionalUrlParameters: additionalUserParameters,
            depth: "0",
          },

          successHandler: (res: any) => {
            const tempUsers = [];
            for (const key in res.data.results) {
              const item = res.data.results[key];
              const allUsers = {
                value: item.id,
                label: item.last_name + ", " + item.first_name,
              };
              tempUsers.push(allUsers);
            }
            setExecutedByOptions(
              tempUsers.sort((a: any, b: any) => a.label.localeCompare(b.label))
            );
          },
          failHandler: (error: any) => {
            setError(error);
          },
        });
        setIsLoading(false);
      },
      failHandler: (error: any) => {
        setError(error);
      },
    });
  };

  const handleLoading = (loading: boolean) => {
    setIsLoading(loading);
  };
  const handleSetError = (error: any) => {
    setError(error);
  };
  const handleSetCurrentAppointment = (appointment: any) => {
    setCurrentAppointment(appointment);
  };
  const handleSetcategoryExaminationSet = (categoryExaminationSet: any) => {
    setCategoryExaminationSet(categoryExaminationSet);
  };

  const handleExecutedByChange = (selectedOption: any) => {
    if (
      selectedOption &&
      typeof selectedOption === "object" &&
      selectedOption.value
    ) {
      setExecutedBy(selectedOption.value);
      if (selectedOption.value !== currentAppointment?.executed_by?.id) {
        const config = {
          headers: {
            "Content-Type": "application/json",
          },
        };
        const result = { executed_by: selectedOption?.value };
        api.genericApiRequest({
          entity: "appointment",
          method: "put",
          // successHandler: () => {},
          failHandler: (error: any) => {
            setError(error);
          },
          entityId: String(appointmentId),
          submitData: result,
          config: config,
        });
      }
    }
  };

  return (
    <>
      {isLoading ? (
        <DotLoader
          color="#8c1ec8"
          size={65}
          cssOverride={{ position: "absolute", top: "45vh", left: "50vw" }}
        />
      ) : (
        <Box>
          {/* Header  */}
          <Box className={styles.headerBox}>
            <AppointmentTreeItemHeaderbar
              appointment={currentAppointment}
              appointmentId={appointmentId}
              handleSetLoading={handleLoading}
              handleSetError={handleSetError}
              handleSetCurrentAppointment={handleSetCurrentAppointment}
              handleSetcategoryExaminationSet={handleSetcategoryExaminationSet}
              executedByOptions={executedByOptions}
              executedByPresetValue={executedBy}
              handleExecutedByChange={handleExecutedByChange}
            />
          </Box>
          <Grid
            container
            // className={styles.gridContainer}
            columnSpacing={"1rem"}
          >
            {/* Left Column */}
            <Grid container item xs={9} className={styles.leftColumn}>
              <AppointmentTreeItemSubjectInformation
                appointment={currentAppointment}
              />
              <AppointmentTreeItemWidgets
                categoryExaminationSet={categoryExaminationSet}
              />
              <AppointmentTreeItemFileList appointment={currentAppointment} />
            </Grid>

            {/* Right Column */}
            <Grid item xs={3}>
              <Box>
                {(!subjectOfAppointment?.is_registered ||
                  subjectOfAppointment?.is_analog) && (
                  <AppointmentTreeItemUploadButton
                    appointment={currentAppointment}
                    useCase="formOverview"
                  />
                )}

                <AppointmentTreeItemForms
                  formsToShow="visible"
                  categoryExaminationSet={categoryExaminationSet}
                />
                <AppointmentTreeItemForms
                  formsToShow="hidden"
                  categoryExaminationSet={categoryExaminationSet}
                />
              </Box>
            </Grid>
          </Grid>
          <Paper className={styles.paperContainer}>
            <h2 className={styles.paperTitle}>{t("all_documents")}</h2>
            <DocumentTable
              customPageSize={5}
              folder="all"
              subjectId={subjectOfAppointment.id}
              subjectForDispatch={subjectOfAppointment}
              customDensity="compact"
              tableMargin={"0"}
              tableBoxShadow={"none"}
              buttonLinkToDocuments={true}
            />
          </Paper>
        </Box>
      )}

      {error && error !== "" && (
        <GenericErrorMessageModal
          title={t("error_occurred")}
          error={error}
          onClosehandler={() => {
            setError("");
          }}
        />
      )}
    </>
  );
};

export default AppointmentTreeView;
