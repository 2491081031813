import { FC } from "react";

const Background: FC = () => {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 1920 1080"
      xmlSpace="preserve"
      preserveAspectRatio="none"
    >
      <style type="text/css"></style>
      <g id="HG">
        <rect
          className="st0"
          style={{ fill: "#FF6428" }}
          width="1920"
          height="1080"
        />
      </g>
      <g id="Formen">
        <g>
          <defs>
            <rect id="SVGID_1_" width="1920" height="1080" />
          </defs>
          <clipPath id="SVGID_00000150794720280223251140000010848288345995025828_">
            <use xlinkHref="#SVGID_1_" style={{ overflow: "visible" }} />
          </clipPath>
          <path
            style={{
              clipPath:
                "url(#SVGID_00000150794720280223251140000010848288345995025828_)",
              fill: "#D6BCE9",
            }}
            d="M1530-100
                c0,0-258.6,214.9-279,511c-27,391,284,553,582,513c332-44.6,443-285,443-285l-95-802L1530-100z"
          />
        </g>
        <g>
          <defs>
            <rect
              id="SVGID_00000078039797368355771810000017218850336205115788_"
              width="1920"
              height="1080"
            />
          </defs>
          <clipPath id="SVGID_00000116220877695070852500000009121721231316612013_">
            <use
              xlinkHref="#SVGID_00000078039797368355771810000017218850336205115788_"
              style={{ overflow: "visible" }}
            />
          </clipPath>
          <path
            style={{
              clipPath:
                "url(#SVGID_00000116220877695070852500000009121721231316612013_)",
              fill: "#FF8353",
            }}
            d="M-90,49
                c0,0,282.3-16.6,439.2,210.3c136,196.6,33.9,350.4,236.7,535.1c152.6,138.9,345.9,147.5,459.8,233.8
                c82.7,62.7,105.6,143.8,105.6,143.8l-1215-40.1L-90,49z"
          />
        </g>
      </g>
    </svg>
  );
};

export default Background;
