import { PayloadAction, createAction, createSlice } from "@reduxjs/toolkit";
const clearAction = createAction("clear");

interface TableSearchState {
  [entity: string]: string;
}

const initialState: TableSearchState = {};

const tableSearchSlice = createSlice({
  name: "tableSearch",
  initialState: initialState,
  reducers: {
    setTableSearch: (
      state,
      action: PayloadAction<{ entity: string; searchValue: string }>
    ) => {
      const { searchValue, entity } = action.payload;

      state[entity] = searchValue;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(clearAction, () => initialState);
  },
});

export const { setTableSearch } = tableSearchSlice.actions;
export default tableSearchSlice.reducer;
