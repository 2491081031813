import GenericCrudTable from "../../components/tables/genericCrudTable";
import { useTranslation } from "react-i18next";
import Restricted from "../../consumer/restricted";
import { useEffect, useState } from "react";
import { api } from "../../helper/api";
import GenericErrorMessageModal from "../../components/forms/errorHandling/genericErrorMessageModal";
import { FormLabel, List, ListItem, ListItemText } from "@mui/material";
import styles from "./mailTemplateList.module.css";

export default function MailTemplateList() {
  const [mailTemplateName, setMailTemplateName] = useState<any>("");
  const [mailTemplateTags, setMailTemplateTags] = useState<any>([]);
  const [error, setError] = useState<any>();

  const { t } = useTranslation();

  const replaceDate = (id: any) => {
    const date = new Date(id).toLocaleString();
    return date;
  };

  const getMailTemplateIdFromCrudTable = (data: any) => {
    const templateName = data.name;
    setMailTemplateName(templateName);
  };

  useEffect(() => {
    const additionalUrlParameter: any = {};
    additionalUrlParameter["name"] = mailTemplateName;
    const additionalRouteParts: any = {};
    additionalRouteParts["available_tags"] = "/";

    if (mailTemplateName !== "") {
      api.genericApiRequest({
        entity: "mailTemplate",
        method: "get",
        parametersToRender: {
          depth: "0",
          additionalUrlParameters: additionalUrlParameter,
        },
        additionalRouteParts: additionalRouteParts,
        successHandler: (res: any) => {
          setMailTemplateTags(res.data.results);
        },
        failHandler: (error: any) => {
          console.log(error);
          setError(error);
        },
      });
    }
  }, [mailTemplateName]);

  const tagList = mailTemplateTags.map((name: any) => {
    return (
      <>
        <ListItem key={name} sx={{ width: "fit-Content" }}>
          <ListItemText secondary={name} />
        </ListItem>
      </>
    );
  });

  return (
    <Restricted permissionRequired="frontend_view_email_template">
      <GenericCrudTable
        allowColumnFiltering={true}
        showColumnFilterbyDefault={true}
        heading={t("email_templates")}
        entity={"mailTemplate"}
        disallowDelete={true}
        handleGetMailTemplateId={getMailTemplateIdFromCrudTable}
        additionalComponentForModal={
          <FormLabel className={styles.tagLabel}>
            {t("mailtemplate_tags_available")}
            <List className={styles.tagList}>
              {tagList}
            </List>
          </FormLabel>
        }
        columns={[
          { header: "ID", accessorKey: "id", id: "id" },
          {
            header: t("name"),
            accessorKey: "name",
            id: "name",
            enableForCreate: true,
            required: true,
            errorMessage: t("field_required"),
          },
          {
            header: t("mail_subject"),
            accessorKey: "mail_subject",
            id: "mail_subject",
            enableForCreate: true,
          },
          {
            header: t("mail_body"),
            accessorKey: "mail_body",
            enableForCreate: true,
            id: "mail_body",
            textarea: true,
            required: true,
            errorMessage: t("field_required"),
          },
          {
            header: t("created_on"),
            accessorKey: "created_on",
            sortingFn: "datetime",
            filterVariant: "date",

            id: "created_on",
            Cell: ({ cell }: any) => {
              return <span>{replaceDate(cell.getValue())}</span>;
            },
          },
          {
            header: t("created_by"),
            accessorKey: "created_by",
            id: "created_by",
            Cell: ({ cell }: any) => {
              return <span>{cell.getValue().name}</span>;
            },
          },
          {
            header: t("modified_on"),
            accessorKey: "modified_on",
            sortingFn: "modified_on",
            filterVariant: "date",

            id: "modified_on",
            Cell: ({ cell }: any) => {
              return <span>{replaceDate(cell.getValue())}</span>;
            },
          },
          {
            header: t("modified_by"),
            accessorKey: "modified_by",
            customColumnFilterId: "modified_by_name",

            id: "modified_by",
            Cell: ({ cell }: any) => {
              return <span>{cell.getValue().name}</span>;
            },
          },
        ]}
      />
      {error && error !== "" && (
        <GenericErrorMessageModal
          title={t("error_occurred")}
          error={error}
          onClosehandler={() => {
            setError("");
          }}
        />
      )}
    </Restricted>
  );
}
