import { useEffect, useRef, useState } from "react";
import { Editor } from "@tinymce/tinymce-react";
import { Button } from "@mui/material";
import { ITemplateData } from "../../types/TeamplateData";
import { t } from "i18next";
import styles from "./tinymce.module.css";

interface Props {
  value?: ITemplateData | any;
  handleOnSave?: (content: any) => void;
  lastSaved?: any;
  onChange?: (content: any) => void;
}
export default function TinyMceEditor(props: Props) {
  const [value, setValue] = useState<any>("");
  const editorRef = useRef<any>(null);

  const handleOnSave = () => {
    const content = editorRef.current.getContent();
    props.handleOnSave?.(content);
  };

  const onChange = (value: any) => {
    setValue(value);
  };

  useEffect(() => {
    if (props.onChange) {
      props.onChange(value);
    }
  }, [value]);

  return (
    <div className={styles.editorContainer}>
      <Editor
        onEditorChange={onChange}
        tinymceScriptSrc={process.env.PUBLIC_URL + "/tinymce/tinymce.min.js"}
        onInit={(evt: any, editor: any) => (editorRef.current = editor)}
        initialValue={props.value}
        init={{
          height: 500,
          menubar: false,
          plugins: [
            "advlist",
            "autolink",
            "lists",
            "link",
            "image",
            "charmap",
            "anchor",
            "searchreplace",
            "visualblocks",
            "code",
            "fullscreen",
            "insertdatetime",
            "media",
            "table",
            "preview",
            "help",
            "wordcount",
            "pagebreak",
            "paste",
          ],
          paste_as_text: true,
          remove_trailing_brs: false,
          pagebreak_separator: '<div style="break-after:page"></div>',
          toolbar:
            "undo redo | blocks | " +
            "bold italic forecolor | alignleft aligncenter " +
            "alignright alignjustify | bullist numlist outdent indent | " +
            "removeformat | hr pagebreak | image editimage | link | preview | table | code | help ",
          content_style:
            "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
        }}
      />
      <div className={styles.footerContainer}>
        <Button
          color="secondary"
          variant="contained"
          type="button"
          onClick={handleOnSave}
          style={{ fontSize: "inherit" }}
        >
          Speichern
        </Button>
        {props.lastSaved && typeof (props.lastSaved === "string") && (
          <div className={styles.lastSaved}>
            {t("last_saved")}: <span>{props.lastSaved}</span>
          </div>
        )}
      </div>
    </div>
  );
}
