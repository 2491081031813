import { useState, useEffect } from "react";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import "../../components/dragndrop/formEditorStyles.css";
import { useNavigate, useParams } from "react-router-dom";
import { Column } from "../../components/dragndrop/dndColumn";
import { DeleteModal } from "../../components/dragndrop/dndDeleteModal";
import CheckBox from "../../components/forms/inputs/checkBox";
import { IMeasurementDefinition } from "../../types/Entities";
import { useTranslation } from "react-i18next";
import Button from "../../components/forms/inputs/button";
import { DotLoader } from "react-spinners";
import { api } from "../../helper/api";
import GenericErrorMessageModal from "../../components/forms/errorHandling/genericErrorMessageModal";
import GenericNotification from "../../components/notification/genericNotification";
import Restricted from "../../consumer/restricted";
import DefaultInput from "../../components/forms/inputs/defaultInput";
import {
  Backdrop,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
  Paper,
} from "@mui/material";
import styles from "./formEditor.module.css";

interface MdCatalogue extends IMeasurementDefinition {
  mandatory?: boolean;
  visibility_ruleset?: any;
  is_used_in_form?: any;
}
interface ModalConfirmation {
  id?: string | null;
  label?: string;
  show?: boolean;
}

interface FormValue {
  name?: string;
}

export function FormEditor() {
  const [measurementDefinitionCatalogue, setMeasurementDefinitionCatalogue] =
    useState<MdCatalogue[]>([]);
  const [query, setQuery] = useState<string>("");
  const [preExistingFormElements, setPreExistingFormElements] = useState<any>(
    []
  );
  const [checkedForPreExistingElements, setCheckedForPreExistingElements] =
    useState<boolean>(false);
  const [formName, setFormName] = useState<string>("");
  const [leftColumn, setLeftColumn] = useState<MdCatalogue[]>([]);
  const [rightColumn, setRightColumn] = useState<MdCatalogue[]>([]);
  const [notAssignedLeftColumnElements, setNotAssignedLeftColumnElements] =
    useState<MdCatalogue[]>([]);
  const [
    alreadyAssignedLeftColumnElements,
    setAlreadyAssignedLeftColumnElements,
  ] = useState<any>([]);
  const [combinedElementsForFilter, setCombinedElementsForFilter] = useState<
    MdCatalogue[]
  >([]);
  const [showModalConfirmation, setShowModalConfirmation] =
    useState<ModalConfirmation>({
      show: false,
      id: null,
      label: "",
    });
  const [selectOption, setSelectOption] = useState<string>("");
  const [formValue, setFormValue] = useState<FormValue>({
    name: "",
  });
  const [isEditableBySubject, setIsEditableBySubject] = useState<boolean>();
  const [probeId, setProbeId] = useState<string>("");

  const [error, setError] = useState<any>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [showNotification, setShowNotification] = useState<boolean>(false);
  const [notificationMessage, setNotificationMessage] = useState<any>("");
  const [notificationVariant, setNotificationVariant] = useState<
    "success" | "error" | "warning" | "info" | undefined
  >();

  const { formId } = useParams() as { formId: string };

  const navigate = useNavigate();

  const { t } = useTranslation();

  const resetStateOnCloseNotification = () => {
    setShowNotification(false);
    setNotificationMessage("");
  };

  const getMeasurementDefinitions = () => {
    let itemCollection: any = [];
    const additionalUrlParameters: any = {};

    additionalUrlParameters["depth"] = "0";
    additionalUrlParameters["limit"] = "50";

    setIsLoading(true);
    api.genericApiRequest({
      entity: "measurementDefinition",
      method: "get",
      getAllPages: true,
      successHandler: (res: any) => {
        itemCollection = res.data.results;

        setMeasurementDefinitionCatalogue(itemCollection);
        setIsLoading(false);
        // }
      },
      failHandler: (error: any) => {
        setError(error);
      },
      parametersToRender: additionalUrlParameters,
    });
  };

  //___ Get MD all or if redirected from Form if "id" is given___
  useEffect(() => {
    const formProps: any = {};
    getMeasurementDefinitions();

    if (formId) {
      // setIsLoading(true);

      api.genericApiRequest({
        entity: "form",
        method: "get",

        successHandler: (res: any) => {
          setProbeId(res.data.probe.id);
          setFormName(res.data.name);
          setIsEditableBySubject(res.data.editable_by_subject);
          res.data.measurementdefinitiontoform_set.forEach((item: any) => [
            (formProps[item.measurement_definition.id] = item),
          ]);
          setPreExistingFormElements(formProps);
          // setIsLoading(false);

          setCheckedForPreExistingElements(true);
        },
        failHandler: (error: any) => {
          setError(error);
        },
        entityId: String(formId),
      });
    } else {
      setCheckedForPreExistingElements(true);
    }
  }, []);

  useEffect(() => {
    if (
      measurementDefinitionCatalogue.length > 0 ||
      checkedForPreExistingElements === true
    ) {
      const rightColumnElements: any = measurementDefinitionCatalogue.filter(
        (item: any) =>
          Object.keys(preExistingFormElements).includes(String(item.id))
      );

      for (const item of rightColumnElements) {
        const newItem = {
          mandatory: preExistingFormElements?.[item.id]?.mandatory,
          order: preExistingFormElements?.[item.id]?.order,
          visibility_ruleset:
            preExistingFormElements?.[item.id]?.visibility_ruleset,
        };
        Object.assign(item, newItem);
      }
      const orderedRightColumnElements = [...rightColumnElements].sort(
        (a, b) => a.order - b.order
      );
      setRightColumn(orderedRightColumnElements);

      const leftColumnElements = measurementDefinitionCatalogue
        .filter(
          (leftValue: any) =>
            !rightColumnElements.some(
              (rightValue: any) => leftValue.id === rightValue.id
            )
        )
        .sort((a: any, b: any) => a.label.localeCompare(b.label));

      const combined = measurementDefinitionCatalogue
        .filter(
          (leftValue: any) =>
            !rightColumnElements.some(
              (rightValue: any) => leftValue.id === rightValue.id
            )
        )
        .sort((a: any, b: any) => a.label.localeCompare(b.label));

      const notAlreadyUsedLeftColumnElements = leftColumnElements
        .filter((item: any) => {
          return item.is_used_in_form !== true;
        })
        .sort((a: any, b: any) => {
          const dateComparison: any =
            (new Date(b.created_on) as any) - (new Date(a.created_on) as any);
          if (dateComparison !== 0) {
            return dateComparison;
          }
          return a.label.localeCompare(b.label);
        });

      const alreadyUsedLeftColumnElements = leftColumnElements
        .filter((item: any) => {
          return item.is_used_in_form === true;
        })
        .sort((a: any, b: any) => a.label.localeCompare(b.label));

      setLeftColumn(leftColumnElements);
      setCombinedElementsForFilter(combined);
      setNotAssignedLeftColumnElements(notAlreadyUsedLeftColumnElements);
      setAlreadyAssignedLeftColumnElements(alreadyUsedLeftColumnElements);
    }
  }, [
    measurementDefinitionCatalogue,
    preExistingFormElements,
    checkedForPreExistingElements,
  ]);
  useEffect(() => {
    const leftColumnElements = measurementDefinitionCatalogue.filter(
      (leftValue: any) =>
        !rightColumn.some((rightValue: any) => leftValue.id === rightValue.id)
    );

    const filteredLeftColumnElements: any = leftColumnElements.filter(
      (item: any) => {
        if (query !== "") {
          return (
            item.label.toLowerCase().includes(query.toLowerCase()) ||
            item.name.toLowerCase().includes(query.toLowerCase())
          );
        } else {
          return true;
        }
      }
    );
    setLeftColumn(filteredLeftColumnElements);
  }, [query]);

  //-- OnDrag - Start --
  function onDragEnd(result: any) {
    if (!result.destination) {
      return;
    }

    // reordering in same list
    if (result.source.droppableId === result.destination.droppableId) {
      if (result.source.droppableId !== "column-1") {
        return;
      }
      const items: any = reorderList(
        rightColumn,
        result.source.index,
        result.destination.index
      );

      setRightColumn(items);

      return;
    }

    // moving between lists
    if (result.source.droppableId === "column-1") {
      return;
    }
    const item = leftColumn[result.source.index];

    const newItem = { mandatory: false, visibility_ruleset: "{}" };
    Object.assign(item, newItem);

    // 1. remove item from source column
    const newLeftColumn = leftColumn;
    newLeftColumn.splice(result.source.index, 1);

    // 2. insert into destination column
    const newRightColumn = rightColumn;
    // in line modification of items
    newRightColumn.splice(result.destination.index, 0, item);

    setRightColumn(newRightColumn);
    setLeftColumn(newLeftColumn);
  }
  //-- Ondrag - End --

  const handleMandatoryCheckbox = (id: any) => {
    const newItem: any = rightColumn.find((item: any) => item.id === id);

    const newRightColumn: any = [...rightColumn];
    newRightColumn.find((item: any) => item.id === id).mandatory =
      !newItem.mandatory;

    setRightColumn(newRightColumn);
  };
  const handleRulesetChange = (value: any, id: any) => {
    const newRightColumn: any = [...rightColumn];
    const itemToUpdate = newRightColumn.find((item: any) => item.id === id);
    if (itemToUpdate == null || itemToUpdate == undefined) {
      return;
    }

    itemToUpdate.visibility_ruleset = value;

    setRightColumn(newRightColumn);
  };

  const handleDelete = () => {
    if (showModalConfirmation.show && showModalConfirmation.id) {
      const newRightColumn = rightColumn.filter(
        (item: any) => item.id !== showModalConfirmation.id
      );
      const newLeftColumn: any = measurementDefinitionCatalogue.filter(
        (id1: any) => !newRightColumn.some((id2: any) => id1 === id2)
      );

      setRightColumn(newRightColumn);
      setLeftColumn(newLeftColumn);
      setShowModalConfirmation({
        show: false,
        id: null,
      });
    }
  };
  const showModalConfirmationHandler = (id: any) => {
    const newItem: any = rightColumn.find((item: any) => item.id === id);
    setShowModalConfirmation({ show: true, id: id, label: newItem.label });
  };
  const closeModalConfirmationHandler = () => {
    setShowModalConfirmation({ show: false, id: null, label: "" });
  };
  const handleSaveForm = () => {
    const result: any = [];

    rightColumn.forEach((item: any) => {
      result.push({
        id: item.id,
        mandatory: item.mandatory,
        visibility_ruleset:
          item.visibility_ruleset !== "" ? item.visibility_ruleset : "{}",
      });
    });

    const submitData = {
      name: formValue.name !== "" ? formValue.name : formName,
      probe: probeId,
      measurement_definition: result,
      editable_by_subject: isEditableBySubject,
    };
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    api.genericApiRequest({
      entity: "form",
      method: "put",
      successHandler: (res: any) => {
        setNotificationMessage(t("save_successful"));
        setNotificationVariant("success");
        setShowNotification(true);
        setProbeId(res.data.probe?.id);
        navigate(`/admin/formEditor/${res.data.id}`);
      },
      failHandler: (error: any) => {
        setError(error);
      },
      entityId: String(formId),
      submitData: submitData,
      config: config,
    });
  };

  const handleNavigateBack = () => {
    if (probeId !== "") {
      navigate(`/admin/probeEditor/${probeId}`);
    } else {
      navigate(-1);
    }
  };

  function reorderList(list: any, startIndex: any, endIndex: any) {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  }

  const handleLeftColumnFilter = (value: string) => {
    setSelectOption(value);
    if (value === "all") {
      setLeftColumn(combinedElementsForFilter);
    }
    if (value === "unused") {
      setLeftColumn(notAssignedLeftColumnElements);
    }
    if (value === "used") {
      setLeftColumn(alreadyAssignedLeftColumnElements);
    }
  };

  const handleChange = (event: any) => {
    setFormValue({ ...formValue, [event.target.name]: event.target.value });
  };

  const handleCheckboxChange = (value: boolean) => {
    setIsEditableBySubject(value);
  };
  return (
    <Restricted permissionRequired="frontend_view_form_editor">
      {isLoading ? (
        <DotLoader
          color="#8c1ec8"
          size={65}
          cssOverride={{ position: "absolute", top: "45vh", left: "50vw" }}
        />
      ) : (
        <>
          <DragDropContext onDragEnd={onDragEnd}>
            <Grid container rowSpacing={"1rem"} className={styles.container1}>
              <Grid container item xs={12} className={styles.formEditorHeader}>
                <Grid item xs={12}>
                  <List
                    subheader={"Visibility Ruleset Beispiele:"}
                    className={styles.exampleList}
                  >
                    <ListItem className={styles.exampleListItem}>
                      <ListItemText
                        primaryTypographyProps={{
                          component: "span",
                          sx: { fontSize: "0.85rem" },
                        }}
                        primary={`{"${t("variable_name")}": {"is": "yes"}}`}
                      />

                      <ListItemText
                        primaryTypographyProps={{
                          component: "span",
                          sx: { fontSize: "0.85rem" },
                        }}
                        primary={`{"${t("variable_name")}": {"not": "no"}}`}
                      />
                      <ListItemText
                        primaryTypographyProps={{
                          component: "span",
                          sx: { fontSize: "0.85rem" },
                        }}
                        primary={`{"${t("variable_name")}": {"gt": 0.5}}`}
                      />
                      <ListItemText
                        primaryTypographyProps={{
                          component: "span",
                          sx: { fontSize: "0.85rem" },
                        }}
                        primary={`{"${t("variable_name")}": {"lt": 1.4}}`}
                      />
                      <ListItemText
                        primaryTypographyProps={{
                          component: "span",
                          sx: { fontSize: "0.85rem" },
                        }}
                        primary={`{"${t(
                          "variable_name"
                        )}": {"in": [0.1, 0.2, 5.0]}}`}
                      />
                      <ListItemText
                        primaryTypographyProps={{
                          component: "span",
                          sx: { fontSize: "0.85rem" },
                        }}
                        primary={`{"${t("variable_name")}": {"nin": ["${t(
                          "hay_fever"
                        )}", "${t("rash")}"]}}`}
                      />
                      <ListItemText
                        primaryTypographyProps={{
                          component: "span",
                          sx: { fontSize: "0.85rem" },
                        }}
                        primary={`Bei Mehrfachauswahl: {"${t(
                          "variable_name"
                        )}": {"contains": "Heuschnupfen"}}`}
                      />
                    </ListItem>
                  </List>
                </Grid>
              </Grid>
              <Grid item className={styles.noPadding}>
                <Divider component={"div"} className={styles.noPadding} />
              </Grid>
              <Grid
                container
                item
                columnSpacing={"1rem"}
                className={styles.container2}
              >
                <Grid item xs={4}>
                  <DefaultInput
                    value={query}
                    onChange={(e) => setQuery(e.target.value)}
                    placeholder="Suche..."
                  />
                </Grid>
                <Grid item xs={8} className={styles.inputCheckboxContainer}>
                  {formName && (
                    <DefaultInput
                      name="name"
                      onChange={handleChange}
                      defaultValue={formName}
                    />
                  )}
                  <CheckBox
                    label={t("editableBySubject")}
                    onChange={handleCheckboxChange}
                    defaultValue={isEditableBySubject}
                    labelClassName={styles.checkBoxLabel}
                  />
                </Grid>
              </Grid>

              <Grid
                container
                item
                component={Paper}
                xs={12}
                className={styles.container3}
              >
                <Droppable
                  droppableId="all-droppables"
                  direction="horizontal"
                  type="column"
                >
                  {(provided) => (
                    <Grid
                      container
                      columnSpacing={"1rem"}
                      item
                      xs={12}
                      component={"div"}
                      className={styles.columnContainer}
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                    >
                      <Grid item xs={4}>
                        <Column
                          key={"column-0"}
                          column={{
                            id: "column-0",
                            items: leftColumn,
                            title: t("available_elements"),
                          }}
                          index={0}
                          preExistingFormElements={preExistingFormElements}
                          handleLeftColumnFilter={handleLeftColumnFilter}
                          selectOption={selectOption}
                        />
                      </Grid>

                      <Grid item xs={8}>
                        <Column
                          key={"column-1"}
                          column={{
                            id: "column-1",
                            items: rightColumn,
                          }}
                          index={1}
                          onShowConfirmationModal={showModalConfirmationHandler}
                          preExistingFormElements={preExistingFormElements}
                          onHandleCheckboxChange={handleMandatoryCheckbox}
                          onHandleRulesetChange={handleRulesetChange}
                          handleLeftColumnFilter={handleLeftColumnFilter}
                          selectOption={selectOption}
                        />
                      </Grid>

                      {provided.placeholder}
                    </Grid>
                  )}
                </Droppable>
              </Grid>

              <Grid container item justifyContent={"space-evenly"}>
                <Grid item>
                  <Button title={t("back")} onClick={handleNavigateBack} />
                </Grid>
                <Grid item>
                  <Button
                    className="formEditorSaveButton"
                    onClick={handleSaveForm}
                    variant="contained"
                    color="secondary"
                    title={t("save")}
                  />
                </Grid>
              </Grid>
            </Grid>

            {showModalConfirmation.show && (
              <Backdrop
                onClick={closeModalConfirmationHandler}
                open={showModalConfirmation.show}
                className={styles.backdrop}
              />
            )}
            {showModalConfirmation.show && (
              <DeleteModal
                onHandleDelete={handleDelete}
                onHandleClose={closeModalConfirmationHandler}
                showModalConfirmation={showModalConfirmation}
              />
            )}
            <>
              {error && error !== "" && (
                <GenericErrorMessageModal
                  title={t("error_occurred")}
                  error={error}
                  onClosehandler={() => {
                    setError("");
                  }}
                />
              )}
              {showNotification && (
                <GenericNotification
                  message={notificationMessage}
                  variant={notificationVariant}
                  handleCloseSnackbar={resetStateOnCloseNotification}
                />
              )}
            </>
          </DragDropContext>
        </>
      )}
    </Restricted>
  );
}
