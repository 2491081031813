import { FC } from "react";
import { ListItem } from "@mui/material";
import "./navigationBar.css";
import {
  NavigationItemButton,
  NavigationListItemContent,
  NavigationNavLink,
} from "./navigationItems";
import Restricted from "../../consumer/restricted";

interface Props {
  drawerOpen: boolean;
  selectedIndex: number;
  handleListItemClick?: any;
}

const GeneralRoutes: FC<Props> = ({
  drawerOpen,
  selectedIndex,
  handleListItemClick,
}) => {
  return (
    <>
      <Restricted permissionRequired="frontend_view_home">
        <ListItem
          disablePadding
          className={drawerOpen ? "navigationItemOpen" : "navigationItem"}
        >
          <NavigationNavLink to={"/"}>
            <NavigationItemButton
              drawerOpen={drawerOpen}
              onClick={(event: any) => handleListItemClick(event, 0)}
              selected={selectedIndex === 0}
            >
              <NavigationListItemContent
                icon="home"
                primaryText="Home"
                drawerOpen={drawerOpen}
              />
            </NavigationItemButton>
          </NavigationNavLink>
        </ListItem>
      </Restricted>
      <Restricted permissionRequired="frontend_view_user_todo">
        <ListItem
          disablePadding
          className={drawerOpen ? "navigationItemOpen" : "navigationItem"}
        >
          <NavigationNavLink to="/todo">
            <NavigationItemButton
              drawerOpen={drawerOpen}
              onClick={(event: any) => handleListItemClick(event, 1)}
              selected={selectedIndex === 1}
            >
              <NavigationListItemContent
                icon="todo"
                primaryText="To Do"
                drawerOpen={drawerOpen}
              />
            </NavigationItemButton>
          </NavigationNavLink>
        </ListItem>
      </Restricted>
      <Restricted permissionRequired="frontend_view_document">
        <ListItem
          disablePadding
          className={drawerOpen ? "navigationItemOpen" : "navigationItem"}
        >
          <NavigationNavLink to="/document">
            <NavigationItemButton
              drawerOpen={drawerOpen}
              onClick={(event: any) => handleListItemClick(event, 2)}
              selected={selectedIndex === 2}
            >
              <NavigationListItemContent
                icon="document"
                primaryText="Dokumente"
                drawerOpen={drawerOpen}
              />
            </NavigationItemButton>
          </NavigationNavLink>
        </ListItem>
      </Restricted>
    </>
  );
};

export default GeneralRoutes;
