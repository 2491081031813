import { configureStore } from "@reduxjs/toolkit";
import parentReducer from "./parent/parentSlice";
import childrenReducer from "./parent/childrenSlice";
import logoutReducer from "./login/logoutSlice";
import loginReducer from "./parent/loginSlice";
import appointmentDashboardReducer from "./appointment/appointmentDashboardSlice";
import navigateToReducer from "./navigation/navigateToSlice";
import subjectSearchReducer from "./subjectSearch/subjectSearchSlice";
import tableSearchReducer from "./tableSearch/tableSearchSlice";
import appointmentDashboardStatusFilterReducer from "./appointmentDashboard/statusFilterSlice";
import appointmentDashboardCategoryFilterReducer from "./appointmentDashboard/categoryFilterSlice";
import appointmentDashboardExecutedByFilterReducer from "./appointmentDashboard/executedByFilterSlice";
import appointmentDashboardSubjectFilterReducer from "./appointmentDashboard/subjectFilterSlice";

import appointmentDashboardSelectedCalendarDateReducer from "./appointmentDashboard/selectedCalendarDaySlice";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";

const persistParentConfig = {
  key: "setParent",
  version: 1,
  storage,
};
const persistLoginConfig = {
  key: "setLogin",
  version: 1,
  storage,
};
const persistSubjectSearchConfig = {
  key: "setSubjectSearch",
  version: 1,
  storage,
};
const persistTableSearchConfig = {
  key: "setTableSearch",
  version: 1,
  storage,
};
const persistAppointmentDashboardStatusFilterConfig = {
  key: "setAppointmentDashboardStatusFilter",
  version: 1,
  storage,
};
const persistAppointmentDashboardCategoryFilterConfig = {
  key: "setAppointmentDashboardcategoryFilter",
  version: 1,
  storage,
};
const persistAppointmentDashboardExecutedByFilterConfig = {
  key: "setAppointmentDashboardExecutedByFilter",
  version: 1,
  storage,
};
const persistAppointmentDashboardSubjectFilterConfig = {
  key: "setAppointmentDashboardSubjectFilter",
  version: 1,
  storage,
};
const persistAppointmentDashboardSelectedCalendarDateConfig = {
  key: "setAppointmentDashboardSelectedCalendarDate",
  version: 1,
  storage,
};

const persistedParentReducer = persistReducer(
  persistParentConfig,
  parentReducer
);
const persistedLoginReducer = persistReducer(persistLoginConfig, loginReducer);
const persistedSubjectSearchReducer = persistReducer(
  persistSubjectSearchConfig,
  subjectSearchReducer
);
const persistedAppointmentDashboardStatusFilterReducer = persistReducer(
  persistAppointmentDashboardStatusFilterConfig,
  appointmentDashboardStatusFilterReducer
);
const persistedAppointmentDashboardCategoryFilterReducer = persistReducer(
  persistAppointmentDashboardCategoryFilterConfig,
  appointmentDashboardCategoryFilterReducer
);
const persistedAppointmentDashboardExecutedByFilterReducer = persistReducer(
  persistAppointmentDashboardExecutedByFilterConfig,
  appointmentDashboardExecutedByFilterReducer
);
const persistedAppointmentDashboardSubjectFilterReducer = persistReducer(
  persistAppointmentDashboardSubjectFilterConfig,
  appointmentDashboardSubjectFilterReducer
);
const persistedTableSearchReducer = persistReducer(
  persistTableSearchConfig,
  tableSearchReducer
);
const persistedAppointmentDashboardCalendarDateReducer = persistReducer(
  persistAppointmentDashboardSelectedCalendarDateConfig,
  appointmentDashboardSelectedCalendarDateReducer
);

export const store = configureStore({
  reducer: {
    parent: persistedParentReducer,
    children: childrenReducer,
    login: persistedLoginReducer,
    logout: logoutReducer,
    navigateTo: navigateToReducer,
    subjectSearch: persistedSubjectSearchReducer,
    // appointmentDashboard: persistedAppointmentDashboardReducer,
    appointmentDashboard: appointmentDashboardReducer,
    // tableSearch: persistedTableSearchReducer,
    tableSearch: persistedTableSearchReducer,
    appointmentDashboardStatusFilter:
      persistedAppointmentDashboardStatusFilterReducer,
    appointmentDashboardCategoryFilter:
      persistedAppointmentDashboardCategoryFilterReducer,
    appointmentDashboardExecutedByFilter:
      persistedAppointmentDashboardExecutedByFilterReducer,
    appointmentDashboardSubjectFilter:
      persistedAppointmentDashboardSubjectFilterReducer,
    appointmentDashboardSelectedCalendarDate:
      persistedAppointmentDashboardCalendarDateReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});
//eslint-disable-next-line
export let persistor = persistStore(store);
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
