import { FC } from "react";

interface Props {
  width?: string;
  height?: string;
  color?: string;
}

const FlagIcon: FC<Props> = ({ width, height }) => {
  let iconWidth = "24";
  let iconHeight = "24";

  if (width) {
    iconWidth = width;
  }

  if (height) {
    iconHeight = height;
  }

  return (
    <svg
      width={iconWidth}
      height={iconHeight}
      viewBox="0 0 12 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.5 14.0005C0.367392 14.0005 0.240215 13.9478 0.146447 13.854C0.0526785 13.7603 0 13.6331 0 13.5005V1.12955C3.57728e-05 0.998546 0.0343858 0.869835 0.0996304 0.756233C0.164875 0.64263 0.258738 0.548099 0.371875 0.482051C0.75 0.262363 1.51188 0.000488281 3 0.000488281C4.16281 0.000488281 5.46344 0.460176 6.61094 0.865488C7.535 1.19205 8.40781 1.50049 9 1.50049C9.76232 1.49815 10.5166 1.34412 11.2188 1.04736C11.3043 1.01127 11.3974 0.996894 11.4898 1.00552C11.5822 1.01415 11.671 1.04551 11.7484 1.09681C11.8257 1.14811 11.8892 1.21775 11.9331 1.29953C11.977 1.3813 12 1.47267 12 1.56549V8.42049C11.9999 8.54199 11.9643 8.66082 11.8977 8.76245C11.8311 8.86407 11.7364 8.94407 11.625 8.99268C11.3528 9.11174 10.3591 9.50049 9 9.50049C8.24562 9.50049 7.30062 9.27736 6.30031 9.0408C5.17594 8.77518 4.01344 8.50049 3 8.50049C1.84781 8.50049 1.25812 8.67486 1 8.78518V13.5005C1 13.6331 0.947321 13.7603 0.853553 13.854C0.759785 13.9478 0.632608 14.0005 0.5 14.0005Z"
        fill="#FF6428"
      />
    </svg>
  );
};

export default FlagIcon;
