import { FC, useEffect, useState } from "react";
import "./navigationBar.css";
import ManagerRoutes from "./managerRoutes";
import GeneralRoutes from "./generalRoutes";
import AdminRoutes from "./adminRoutes";
import CustomerRoutes from "./customerRoutes";

// RoutesIndex
// generalRoutes 0-10
// managerRoutes 10-20
// adminRoutes 20-40
// customerRoutes 50-60

interface Props {
  drawerOpen: boolean;
  subdomain: string;
  subRouteOpen: boolean;
  handleSubrouteOpen?: any;
  closeSubroutes?: any;
  closeDrawer: () => void;
  location: any;
  openSubRoutes: (open: boolean) => void;
}

const NavigationRoutes: FC<Props> = ({
  drawerOpen,
  subdomain,
  subRouteOpen,
  handleSubrouteOpen,
  closeSubroutes,
  closeDrawer,
  location,
  openSubRoutes,
}) => {
  const index =
    location.pathname === "/"
      ? 0
      : location.pathname === "/todo" || location.pathname === "/todo/"
      ? 1
      : location.pathname === "/document" || location.pathname === "/document/"
      ? 2
      : location.pathname === "/mitarbeiter" ||
        location.pathname === "/mitarbeiter/"
      ? 10
      : (location.pathname === "/appointmentrequestopen" ||
          location.pathname === "/appointmentrequestopen/") &&
        subdomain === "manage"
      ? 11
      : (location.pathname === "/buildings" ||
          location.pathname === "/buildings/") &&
        subdomain === "manage"
      ? 12
      : (location.pathname === "/workerAppointments" ||
          location.pathname === "/workerAppointments/") &&
        subdomain === "manage"
      ? 13
      : location.pathname === "/appointmentDashboard" ||
        location.pathname === "/appointmentDashboard/"
      ? 20
      : location.pathname === "/appointmentrequest" ||
        location.pathname === "/appointmentrequest/"
      ? 21
      : location.pathname === "/admin/mainCategoryList" ||
        location.pathname === "/admin/mainCategoryList/"
      ? 22
      : location.pathname === "/admin/examinationList" ||
        location.pathname === "/admin/examinationList/"
      ? 23
      : location.pathname === "/admin/probeList" ||
        location.pathname === "/admin/probeList/"
      ? 24
      : location.pathname === "/admin/measurementDefinitionList" ||
        location.pathname === "/admin/measurementDefinitionList/"
      ? 25
      : location.pathname === "/admin/appointmentList" ||
        location.pathname === "/admin/appointmentList/"
      ? 26
      : location.pathname === "/admin/templateEditor" ||
        location.pathname === "/admin/templateEditor/"
      ? 27
      : location.pathname === "/admin/documentEditor" ||
        location.pathname === "/admin/documentEditor/"
      ? 28
      : location.pathname === "/admin/userAdministration" ||
        location.pathname === "/admin/userAdministration/"
      ? 29
      : location.pathname === "/admin/companyList" ||
        location.pathname === "/admin/companyList/"
      ? 30
      : location.pathname === "/admin/todoList" ||
        location.pathname === "/admin/todoList/"
      ? 31
      : location.pathname === "/admin/ruleList" ||
        location.pathname === "/admin/ruleList/"
      ? 33
      : location.pathname === "/admin/mailTemplateList" ||
        location.pathname === "/admin/mailTemplateList/"
      ? 34
      : location.pathname === "/admin/coreData" ||
        location.pathname === "/admin/coreData/"
      ? 35
      : location.pathname === "/admin/todoCreator" ||
        location.pathname === "/admin/todoCreator/"
      ? 36
      : location.pathname === "/admin/permissionList" ||
        location.pathname === "/admin/permissionList/"
      ? 37
      : location.pathname === "/admin/permissionGroupList" ||
        location.pathname === "/admin/permissionGroupList/"
      ? 38
      : location.pathname === "/admin/teams" ||
        location.pathname === "/admin/teams/"
      ? 39
      : location.pathname === "/admin/auditlog" ||
        location.pathname === "/admin/auditlog/"
      ? 40
      : location.pathname === "/admin/finding" ||
        location.pathname === "/admin/finding/"
      ? 41
      : location.pathname === "/admin/subjectOverview" ||
        location.pathname === "/admin/subjectOverview/"
      ? 42
      : (location.pathname === "/appointmentrequestopen" ||
          location.pathname === "/appointmentrequestopen/") &&
        subdomain === "portal" &&
        50;

  const [selectedIndex, setSelectedIndex] = useState<any>(index);

  useEffect(() => {
    if (location.pathname === "/home") {
      selectedIndex === 0;
    }
    if (location.pathname === "/todo") {
      selectedIndex === 1;
    }
    if (location.pathname === "/document") {
      selectedIndex === 2;
    }
    if (location.pathname === "/mitarbeiter") {
      selectedIndex === 10;
    }
    if (location.pathname === "/mitarbeiter" && subdomain === "manage") {
      selectedIndex === 11;
    }
    if (location.pathname === "/buildings" && subdomain === "manage") {
      selectedIndex === 12;
    }
    if (location.pathname === "/appointmentDashboard") {
      selectedIndex === 20;
    }
    if (location.pathname === "/appointmentrequest") {
      selectedIndex === 21;
    }
    if (location.pathname === "/admin/mainCategoryList") {
      selectedIndex === 22;
    }
    if (location.pathname === "/admin/examinationList") {
      selectedIndex === 23;
    }
    if (location.pathname === "/admin/probeList") {
      selectedIndex === 24;
    }
    if (location.pathname === "/admin/measurementDefinitionList") {
      selectedIndex === 25;
    }
    if (location.pathname === "/admin/appointmentList") {
      selectedIndex === 26;
    }
    if (location.pathname === "/admin/templateEditor") {
      selectedIndex === 27;
    }
    if (location.pathname === "/admin/documentEditor") {
      selectedIndex === 28;
    }
    if (location.pathname === "/admin/userAdministration") {
      selectedIndex === 29;
    }
    if (location.pathname === "/admin/companyList") {
      selectedIndex === 30;
    }
    if (location.pathname === "/admin/todoList") {
      selectedIndex === 31;
    }
    if (location.pathname === "/admin/ruleList") {
      selectedIndex === 33;
    }
    if (location.pathname === "/admin/mailTemplateList") {
      selectedIndex === 34;
    }
    if (location.pathname === "/admin/coreData") {
      selectedIndex === 35;
    }
    if (location.pathname === "/admin/todoCreator") {
      selectedIndex === 36;
    }
    if (location.pathname === "/admin/permissionList") {
      selectedIndex === 37;
    }
    if (location.pathname === "/admin/permissionGroupList") {
      selectedIndex === 38;
    }
    if (location.pathname === "/admin/teams") {
      selectedIndex === 39;
    }
    if (location.pathname === "/admin/auditlog") {
      selectedIndex === 40;
    }
    if (location.pathname === "/admin/finding") {
      selectedIndex === 41;
    }
    if (location.pathname === "/admin/subjectOverview") {
      selectedIndex === 42;
    }
    if (
      location.pathname === "/appointmentrequestopen" &&
      subdomain === "portal"
    ) {
      selectedIndex === 50;
    }

    if (location.pathname.includes("admin")) {
      openSubRoutes(true);
    }
  }, [location]);

  const handleListItemClick = (event: any, index: any) => {
    setSelectedIndex(index);
    closeSubroutes();
    closeDrawer();
  };
  const handleListSoubrouteItemClick = (event: any, index: any) => {
    setSelectedIndex(index);
    closeDrawer();
  };

  return (
    <>
      <GeneralRoutes
        drawerOpen={drawerOpen}
        selectedIndex={selectedIndex}
        handleListItemClick={handleListItemClick}
      />
      <ManagerRoutes
        drawerOpen={drawerOpen}
        selectedIndex={selectedIndex}
        handleListItemClick={handleListItemClick}
        subdomain={subdomain}
      />
      <CustomerRoutes
        drawerOpen={drawerOpen}
        selectedIndex={selectedIndex}
        handleListItemClick={handleListItemClick}
        subdomain={subdomain}
      />
      <AdminRoutes
        drawerOpen={drawerOpen}
        selectedIndex={selectedIndex}
        handleListItemClick={handleListItemClick}
        handleListSoubrouteItemClick={handleListSoubrouteItemClick}
        subdomain={subdomain}
        subRouteOpen={subRouteOpen}
        handleSubrouteOpen={handleSubrouteOpen}
      />
    </>
  );
};

export default NavigationRoutes;
