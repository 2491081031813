import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { t } from "i18next";
import { FC, useEffect, useState } from "react";
import DefaultInput from "./inputs/defaultInput";
import Button from "./inputs/button";
import { InputWithRef } from "./inputsWithRef/inputWithRef";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import CustomErrorMessage from "./inputsWithRef/customErrorMessage";
import { api } from "../../helper/api";
import { useNavigate } from "react-router-dom";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import GenericErrorMessageModal from "./errorHandling/genericErrorMessageModal";
import GenericNotification from "../notification/genericNotification";
import styles from "./resetEmailForm.module.css";

interface Props {
  email?: string;
  userId?: string;
}

const ResetEmailForm: FC<Props> = ({ email, userId }) => {
  const [currentEmail, setCurrentEmail] = useState<string>("");
  const [error, setError] = useState<any>();
  const [showNotification, setShowNotification] = useState<boolean>(false);
  const [notificationMessage, setNotificationMessage] = useState<any>("");
  const [notificationVariant, setNotificationVariant] = useState<
    "success" | "error" | "warning" | "info" | undefined
  >();

  const {
    register,
    handleSubmit,
    getValues,
    reset,
    formState: { errors },
  } = useForm<any>({ mode: "onChange" });
  const navigate = useNavigate();
  const resetStateOnCloseNotification = () => {
    setShowNotification(false);
    setNotificationMessage("");
  };

  useEffect(() => {
    if (email && email !== "") {
      setCurrentEmail(email);
    }
  }, [email]);

  const onSubmit = (data: any) => {
    const result = { email: data.confirmedNewEmail };
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    api.genericApiRequest({
      entity: "user",
      entityId: userId,
      method: "put",
      successHandler: (res: any) => {
        setNotificationMessage(t("successfully_saved"));
        setNotificationVariant("success");
        setShowNotification(true);
        navigate("/usersettings");
        setCurrentEmail(res.data.email);
        reset();
      },
      failHandler: (error: any) => {
        setError(error);
      },
      submitData: result,
      config: config,
    });
  };

  return (
    <>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          {t("change_email")}
        </AccordionSummary>
        <AccordionDetails>
          <form onSubmit={handleSubmit(onSubmit)} className={styles.formStyle} noValidate>
            <DefaultInput
              label={t("current_email")}
              value={currentEmail}
              type="text"
              inputClassName={styles.inputStyle}
              readonly={true}
              disabled={true}
            />
            <hr />
            <InputWithRef
              {...register("newEmail", { required: t("field_required") })}
              label={t("new_email")}
              className={styles.inputStyle}
            />
            <ErrorMessage
              errors={errors}
              name={"newEmail"}
              render={({ message }) => (
                <CustomErrorMessage error={"error"} errorMessage={message} />
              )}
            />

            <InputWithRef
              {...register("confirmedNewEmail", {
                required: t("field_required"),
                validate: (value: any) => {
                  if (
                    value.toLowerCase() === getValues("newEmail").toLowerCase()
                  ) {
                    return;
                  } else {
                    return t("emails_dont_match");
                  }
                },
              })}
              label={t("confirm_new_email")}
              className={styles.inputStyle}
            />
            <ErrorMessage
              errors={errors}
              name={"confirmedNewEmail"}
              render={({ message }) => (
                <CustomErrorMessage error={"error"} errorMessage={message} />
              )}
            />

            <Button type="submit" title={t("save")} />
          </form>
        </AccordionDetails>
      </Accordion>
      {error && error !== "" && (
        <GenericErrorMessageModal
          title={t("error_occurred")}
          error={error}
          onClosehandler={() => {
            setError("");
          }}
        />
      )}
      {showNotification && (
        <GenericNotification
          message={notificationMessage}
          variant={notificationVariant}
          handleCloseSnackbar={resetStateOnCloseNotification}
        />
      )}
    </>
  );
};

export default ResetEmailForm;
