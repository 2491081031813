import { Tooltip } from "@mui/material";
import { t } from "i18next";
import React, { FC, useMemo } from "react";
import GenericCrudTable from "../tables/genericCrudTable";
import { MRT_ColumnDef } from "material-react-table";

interface Props {
  detailedSubject?: any;
}

const SubjectOverviewNotes: FC<Props> = ({ detailedSubject }) => {
  const replaceDate = (id: any, dateTime = false, showMinutes?: boolean) => {
    let date = "";
    if (dateTime === true || showMinutes) {
      date = new Date(id).toLocaleString("de-DE", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
      });
    } else {
      date = new Date(id).toLocaleString("de-DE", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      });
    }

    if (id === null) {
      return "-";
    } else return date;
  };
  const columns = useMemo<MRT_ColumnDef<any>[]>(
    () => [
      {
        accessorKey: "id",
        header: "ID",
        id: "id",
        enableHiding: false,
      },
      {
        accessorKey: "created_on",
        header: t("created_on"),
        id: "created_on",
        size: 40,
        sortingFn: "datetime",
        Cell: ({ cell }: any) => {
          return (
            <Tooltip
              title={replaceDate(cell.getValue(), true)}
              placement="top-end"
            >
              <span>{replaceDate(cell.getValue())}</span>
            </Tooltip>
          );
        },
      },
      {
        accessorKey: "created_by.name",
        header: t("created_by"),
        id: "created_by.name",
        childEntityName: "created_by",
        size: 40,
      },
      {
        accessorKey: "payload",
        header: t("string"),
        id: "payload",
        enableForCreate: true,
        textarea: true,
        size: 1000,
        Cell: ({ cell }: any) => {
          let value = cell.getValue();
          if (typeof value === "object") {
            value = Object.entries(value)
              .map(([key, value]) => `${key}: ${value}`)
              .join(", ");
          }
          return <span>{value}</span>;
        },
      },
      {
        accessorKey: "entity_id",
        header: "EntityId",
        id: "entity_id",
        enableHiding: false,
      },
      {
        accessorKey: "entity_type",
        header: "EntityType",
        id: "entity_type",
        enableHiding: false,
      },

      {
        accessorKey: "modified_by.name",
        header: t("modified_by"),
        id: "modified_by.name",
        childEntityName: "modified_by",
        size: 40,
      },
      {
        accessorKey: "modified_on",
        header: t("modified_on"),
        id: "modified_on",
        size: 40,
        sortingFn: "datetime",
        Cell: ({ cell }: any) => {
          return <span>{replaceDate(cell.getValue())}</span>;
        },
      },
    ],
    []
  );
  return (
    <GenericCrudTable
      columns={columns}
      entity="note"
      customGlobalFilterPosition={"left"}
      customDensity="compact"
      customPageSize={5}
      // showRowActions={false}
      searchId={detailedSubject?.id}
      entityType={"subject"}
      customColumnVisibility={{
        "created_by.name": true,
        "modified_by.name": false,
        entity_id: false,
        entity_type: false,
        modfied_on: false,
      }}
      tableCollapseWidth={"90%"}
    />
  );
};

export default SubjectOverviewNotes;
