import { t } from "i18next";
import React, { FC, useState } from "react";
import GenericCrudTable from "../tables/genericCrudTable";
import dayjs from "dayjs";
import DatePicker from "react-datepicker";
import { IconButton, InputAdornment, TextField, Tooltip } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import Button from "../forms/inputs/button";
import Modal from "../modal";
import styles from "./workerAppointmentsTable.module.css";

const WorkerAppointmentsTable: FC = () => {
  const [startDate, setStartDate] = useState<any>(null);
  const [endDate, setEndDate] = useState<any>(null);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [additionalParametersForFilter, setAdditionalParametersForFilter] =
    useState<any>();
  const columns = [
    { header: "id", id: "id", accessorKey: "id" },
    {
      header: t("customer"),
      accessorKey: "subject.name",
      childEntityName: "subject",
      id: "subject__name",
      accessorFn: (row: any) => {
        if (row.subject) {
          return row.subject?.name;
        }
      },
    },
    {
      header: t("building_location"),
      accessorKey: "building_information.city",
      id: "building_information__name",
      childEntityName: "building_information",
      accessorFn: (row: any) => {
        const parts = [];
        const { name, street_house, zip, city } = row.building_information;
        if (name) {
          parts.push(name);
        }
        if (street_house) {
          parts.push(street_house);
        }
        if (zip || city) {
          const zipCity = [zip, city].filter(Boolean).join(" ");
          parts.push(zipCity);
        }
        return parts.join(", ");
      },
    },
    {
      header: t("department"),
      accessorKey: "subject.department_set",
      enableSorting: false,
      id: "departmentName",
      accessorFn: (row: any) => {
        const departmentSet = row.subject?.department_set;
        const departments = [];
        for (const key in departmentSet) {
          const name = departmentSet[key].name;
          departments.push(name);
        }
        return departments.join(", ");
      },
    },
    {
      header: t("date"),
      accessorKey: "datetime",
      id: "datetime",
      accessorFn: (row: any) => {
        if (row.datetime) {
          return (
            dayjs(row.datetime).format("DD.MM.YYYY") +
            " - " +
            `${
              dayjs(row.datetime).format("HH:mm") + " " + t("time_indicator")
            } `
          );
        } else {
          return "-";
        }
      },
    },
  ];

  const handleDateChange = (date: any, isStart: any) => {
    if (isStart) {
      setStartDate(date);
    } else {
      setEndDate(date);
    }
  };

  const setParameters = () => {
    const additionalUrlParameters: any = {};
    if (startDate !== null && endDate !== null) {
      additionalUrlParameters["datetime_date_range"] = [
        dayjs(startDate).format("YYYY-MM-DD"),
        dayjs(endDate).format("YYYY-MM-DD"),
      ];
    }
    setAdditionalParametersForFilter(additionalUrlParameters);
    setModalOpen(false);
  };

  const handleClearDate = (e: any, date: "start" | "end" | "all") => {
    e.stopPropagation();
    if (date === "start") {
      setStartDate(null);
    } else if (date === "end") {
      setEndDate(null);
    }
    if (date === "all") {
      setStartDate(null);
      setEndDate(null);
      setAdditionalParametersForFilter(null);
    }
  };

  const customToolbarButton = (
    <div className={styles.toolbarButtonContainer}>
      <Button
        title={t("period")}
        variant="contained"
        className={styles.toolbarButton}
        onClick={() => setModalOpen(true)}
      />
      {startDate !== null && endDate !== null && (
        <>
          <Tooltip title={t("delete_filter")} placement="bottom">
            <IconButton onClick={(e: any) => handleClearDate(e, "all")}>
              <ClearIcon />
            </IconButton>
          </Tooltip>
          <div>
            <span className={styles.dateContainer}>{`${t("start_date")}: `}</span>
            <span>{dayjs(startDate).format("DD.MM.YYYY")}</span>
          </div>
          <div>
            <span className={styles.dateContainer}>{`${t("end_date")}: `}</span>
            <span>{dayjs(endDate).format("DD.MM.YYYY")}</span>
          </div>
        </>
      )}
    </div>
  );

  return (
    <>
      <GenericCrudTable
        entity="appointment/worker_appointments"
        columns={columns}
        showRowActions={false}
        additionalUrlParameter={additionalParametersForFilter}
        heading={t("worker_appointments")}
        customTopToolBarAction={customToolbarButton}
        // disableSorting={true}
        disableGlobalFilter={true}
      />
      {modalOpen && (
        <Modal
          open={modalOpen}
          onClose={() => {
            setModalOpen(false);
          }}
          title={t("choose_period")}
          cancelButtonText={t("cancel")}
          submitButtonText={"filtern"}
          onSubmit={setParameters}
        >
          <div className={styles.datePickerContainer}>
            <DatePicker
              selected={startDate}
              onChange={(date) => handleDateChange(date, true)}
              dateFormat="dd/MM/yyyy"
              showMonthDropdown
              showYearDropdown={false}
              dropdownMode="select"
              placeholderText={t("start_date")}
              withPortal
              showPopperArrow={false}
              popperPlacement="bottom-start"
              popperClassName="customDatePicker"
              customInput={
                <TextField
                  variant="outlined"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={(e: any) => handleClearDate(e, "start")}
                        >
                          <ClearIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              }
            />
            <DatePicker
              selected={endDate}
              onChange={(date) => handleDateChange(date, false)}
              dateFormat="dd/MM/yyyy"
              showMonthDropdown
              showYearDropdown={false}
              dropdownMode="select"
              placeholderText={t("end_date")}
              withPortal
              showPopperArrow={false}
              popperPlacement="bottom-end"
              minDate={startDate}
              customInput={
                <TextField
                  variant="outlined"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={(e: any) => handleClearDate(e, "end")}
                        >
                          <ClearIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              }
            />
          </div>
        </Modal>
      )}
    </>
  );
};

export default WorkerAppointmentsTable;
