import { FC, useEffect, useState } from "react";
import DefaultInput from "../forms/inputs/defaultInput";
import Button from "../forms/inputs/button";
import styles from "./measurementDefinitionSelect.module.css";

interface Props {
  id?: string | undefined;
  onChange?: (id: string | undefined, value: string, label: string) => void;
  predefinedValue?: any;
  predefinedLabel?: any;
  onDelete?: (id: string | undefined) => void;
}

const MeasurementDefinitionSelect: FC<Props> = (props: Props) => {
  const [value, setValue] = useState<string>("");
  const [label, setLabel] = useState<string>("");
  const [hasFocus, setHasFocus] = useState<boolean>(false);

  useEffect(() => {
    if (props.predefinedLabel) {
      setLabel(props.predefinedLabel);
    }
    if (props.predefinedValue) {
      setValue(props.predefinedValue);
    }
  }, [props.predefinedLabel, props.predefinedValue]);

  let customValue = value;
  if (!hasFocus) {
    customValue = label;
  }

  useEffect(() => {
    if (
      (props.predefinedLabel === undefined &&
        props.predefinedValue === undefined) ||
      (props.predefinedLabel !== undefined &&
        props.predefinedValue === undefined)
    ) {
      setValue(customValue);
    }
  }, [customValue, props.predefinedLabel, props.predefinedValue]);

  const handleValueChange = (event: any) => {
    event.preventDefault();
    setValue(event.target.value);
  };
  const handleLabelChange = (event: any) => {
    event.preventDefault();
    setLabel(event.target.value);
  };

  useEffect(() => {
    if (props.onChange) {
      props.onChange(props.id, value, label);
    }
  }, [value, label]);

  const colorChange = hasFocus ? false : true;

  const handleBlur = () => {
    if (!value) {
      setHasFocus(false);
      setValue(label);
    }
  };

  const handleFocus = () => {
    setHasFocus(true);
    setValue("");
  };

  return (
    <div className={styles.inputWrapper} id={props.id}>
      <DefaultInput
        label="Bezeichnung"
        name={props.id}
        onChange={handleLabelChange}
        value={label}
      />
      <DefaultInput
        label="Wert"
        changeColor={colorChange}
        name={props.id}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onChange={handleValueChange}
        value={value}
      />
      <Button title="Löschen" onClick={() => props?.onDelete?.(props.id)} />
    </div>
  );
};

export default MeasurementDefinitionSelect;
