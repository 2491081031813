import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import FindingForm from "../components/forms/findingForm";

export default function Form() {
  const [formTitle, setFormTitle] = useState<string>("");
  const { findingId, formId } = useParams();

  useEffect(() => {
    document.title = formTitle;
  }, [formTitle]);

  const onFormTitleChange = function (newTitle: string) {
    setFormTitle(newTitle);
  };

  return (
    <div>
      <h1>{formTitle}</h1>
      <div>
        <FindingForm
          findingId={findingId}
          formId={formId}
          onFormTitleChange={onFormTitleChange}
          onCancelFormHandler={() => ""}
          onSuccessfulFormSubmitHandler={() => ""}
        />
      </div>
    </div>
  );
}
