import styles from "./dateField.module.css";

interface Props {
  mandatory?: boolean;
  label?: string;
  readonly?: boolean;
  name?: string;
  required?: boolean;
  onChange?: (event: any) => void;
  defaultValue?: any;
}
export default function DateField(props: Props) {
  return (
    <label className={styles.dateFieldLabel}>
      {props.mandatory === true ? `${props.label + "\u{002A}"}` : props.label}
      <input
        type="date"
        readOnly={props.readonly}
        id={props.name}
        required={props.required}
      ></input>
    </label>
  );
}
