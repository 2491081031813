import { PayloadAction, createAction, createSlice } from "@reduxjs/toolkit";
const clearAction = createAction("clear");
const clearAppointmentDashboardSubjectFilter = createAction(
  "clearAppointmentDashboardSubjectFilter"
);

export interface subjectFilterState {
  value: string;
  label: string;
}

const initialState: any = { value: "", label: "" };

const appointmentDashboardSubjectFilterSlice = createSlice({
  name: "appointmentDashboardSubjectFilter",
  initialState,
  reducers: {
    setAppointmentDashboardSubjectFilter: (state, action: PayloadAction<any>) =>
      (state = action.payload),
  },
  extraReducers: (builder) => {
    builder.addCase(clearAction, () => initialState);
    builder.addCase(clearAppointmentDashboardSubjectFilter, () => initialState);
  },
});

export const { setAppointmentDashboardSubjectFilter } =
  appointmentDashboardSubjectFilterSlice.actions;
export default appointmentDashboardSubjectFilterSlice.reducer;
