import { useTranslation } from "react-i18next";
import ResetPasswordForm from "../components/forms/resetPasswordForm";
import { useEffect, useState } from "react";
import { api } from "../helper/api";
import ResetEmailForm from "../components/forms/resetEmailForm";
import { Grid } from "@mui/material";
import GenericErrorMessageModal from "../components/forms/errorHandling/genericErrorMessageModal";
import Restricted from "../consumer/restricted";
import UserSettingsForm from "../components/forms/userSettingsForm";
import styles from "./userSettings.module.css";

export default function UserSettings() {
  const [userInfos, setUserInfos] = useState<any>([]);
  const [error, setError] = useState<any>();

  const { t } = useTranslation();

  useEffect(() => {
    api.genericApiRequest({
      entity: "me",
      method: "get",
      successHandler: (res: any) => {
        const data = res.data;
        setUserInfos(data);
      },
      failHandler: (error: any) => {
        setError(error);
      },
    });
  }, []);

  const heading = `${t("user_profile")} - ${userInfos.first_name}, ${
    userInfos.last_name
  }`;

  return (
    <Restricted permissionRequired="frontend_view_user_setting">
      <Grid
        container
        rowSpacing={"1rem"}
        className={styles.container}
      >
        <Grid item xs={12}>
          <h1>{heading}</h1>
        </Grid>
        <Grid item xs={12}>
          <ResetEmailForm email={userInfos.email} userId={userInfos.user_id} />
        </Grid>
        <Grid item xs={12}>
          <ResetPasswordForm />
        </Grid>
        <Grid item xs={12}>
          <UserSettingsForm userInfos={userInfos} />
        </Grid>
      </Grid>
      {error && error !== "" && (
        <GenericErrorMessageModal
          title={t("error_occurred")}
          error={error}
          onClosehandler={() => {
            setError("");
          }}
        />
      )}
    </Restricted>
  );
}
