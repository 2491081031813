import { useRef, useState } from "react";
import FileExplorer from "../components/files/fileExplorer";
import { IDropDownOption } from "../types/DropDown";
import { Grid } from "@mui/material";
import { DropDownSubjectSearch } from "../components/forms/inputs/dropDownSubjectSearch";
import Restricted from "../consumer/restricted";
// import DropDownAsync from "../components/forms/inputs/dropDownAsync";
import styles from "./document.module.css";

interface Options extends IDropDownOption {
  treeId?: string;
}

export default function Document() {
  /*eslint-disable */
  const [options, setOptions] = useState<Options[]>([]);
  /*eslint-enable */
  const [subjectTreeId, setSubjectTreeId] = useState<string>("");
  const [subjectId, setSubjectId] = useState<string>("");

  const hostname = window.location.hostname;
  const subdomain = hostname.split(".")[0];

  const handleSubjectSelect = (selectedOption: any) => {
    if (
      selectedOption &&
      typeof selectedOption === "object" &&
      selectedOption.value
    ) {
      setSubjectId(selectedOption.value);
      setSubjectTreeId(selectedOption.treeId);
    }
  };

  const docRef = useRef(null);

  return (
    <Restricted permissionRequired="frontend_view_document">
      <Grid container>
        <Grid item xs={12} className={styles.container}>
          <Grid container className={styles.searchContainer}>
            {subdomain === "admin" && (
              <Grid item xs={12} sm={6} zIndex={10}>
                <DropDownSubjectSearch
                  subdomain={subdomain}
                  options={options}
                  onChange={handleSubjectSelect}
                  autofocus={true}
                  customRef={docRef}
                  useGlobalState={true}
                  isClearable={true}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          {((subdomain === "admin" && subjectId) ||
            (subdomain !== "admin" && !subjectId)) && (
            <FileExplorer subjectId={subjectId} subjectTreeId={subjectTreeId} />
          )}
        </Grid>
      </Grid>
    </Restricted>
  );
}
