import { Grid, IconButton, ListItemText, Tooltip } from "@mui/material";
import { t } from "i18next";
import React, { FC, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import AppointmentTreeItemActionButtons from "./appointmentTreeItemActionButtons";
import DropDown from "../forms/inputs/dropDown";
import styles from "./appointmentTreeItemHeaderbar.module.css";
import GenericErrorMessageModal from "../../components/forms/errorHandling/genericErrorMessageModal";
import { getAppointmentDetails } from "../../helper/statusOptions";
import { Edit } from "@mui/icons-material";

interface Props {
  appointment: any;
  appointmentId: string;
  handleSetLoading: (loading: boolean) => void;
  handleSetError: (error: any) => void;
  handleSetCurrentAppointment: (appointment: any) => void;
  handleSetcategoryExaminationSet: (categoryExaminationSet: any) => void;
  executedByOptions: any;
  executedByPresetValue: any;
  handleExecutedByChange: (selectedOption: any) => void;
}

const AppointmentTreeItemHeaderbar: FC<Props> = ({
  appointment,
  appointmentId,
  handleSetLoading,
  handleSetError,
  handleSetCurrentAppointment,
  handleSetcategoryExaminationSet,
  executedByOptions,
  executedByPresetValue,
  handleExecutedByChange,
}) => {
  const [modalError, setModalError] = useState<string | null>(null);

  const handleError = (error: any) => {
    handleSetError(error);
    setModalError(error);
  };

  const navigate = useNavigate();

  const reducedStatus = () => {
    const { appointmentStatus } = getAppointmentDetails(appointment.status);
    return (
      <Tooltip title={appointment.status} placement="right-end">
        <span>{appointmentStatus}</span>
      </Tooltip>
    );
  };

  const getReadableDate = () => {
    const parsedDate = new Date(appointment.datetime);

    return parsedDate.toLocaleString("de", {
      timeZone: "Europe/Berlin",
      timeStyle: "short",
      dateStyle: "medium",
    });
  };

  const navigateToAppointmentEdit = () => {
    navigate(`/appointmentEditor/${appointmentId}`);
  };

  return (
    <>
      <Grid container className={styles.container} columnSpacing={"1rem"}>
        <Grid container item xs={9} className={styles.actionButtonsContainer}>
          <Grid item>
            <Tooltip
              placement="right"
              title={t("back")}
              sx={{ marginBottom: "1rem" }}
            >
              <IconButton
                sx={{ alignSelf: "flex-start" }}
                onClick={() => {
                  navigate("/appointmentDashboard");
                }}
              >
                <ArrowBackIcon />
              </IconButton>
            </Tooltip>
          </Grid>
          <AppointmentTreeItemActionButtons
            appointment={appointment}
            appointmentId={appointmentId}
            handleSetLoading={handleSetLoading}
            handleSetError={handleError}
            handleSetCurrentAppointment={handleSetCurrentAppointment}
            handleSetcategoryExaminationSet={handleSetcategoryExaminationSet}
          />
          <Grid container item xs={3}>
            <Grid item xs={10}>
              <DropDown
                placeholder={t("executed_by") + " ..."}
                options={executedByOptions}
                defaultValue={executedByPresetValue}
                name="executed_by"
                onChange={handleExecutedByChange}
              />
            </Grid>
            <Grid
              item
              xs={2}
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <Tooltip
                title={t("open_custom", { subject: t("appointment_single") })}
                placement="right"
              >
                <Link
                  to={`/admin/appointmentEditor/${appointmentId}`}
                  target="_blank"
                  className={styles.link}
                >
                  <Edit />
                </Link>
              </Tooltip>
            </Grid>
          </Grid>
        </Grid>
        <Grid container item xs={3} className={styles.statusContainer}>
          <Grid item>
            <ListItemText
              className={styles.statusText}
              primary={t("status") + ":"}
              secondary={<>{reducedStatus()}</>}
              primaryTypographyProps={{ className: styles.primaryText }}
              secondaryTypographyProps={{ className: styles.secondaryText }}
            />
          </Grid>
          <Grid>
            <ListItemText
              className={styles.statusText}
              secondary={getReadableDate()}
              primaryTypographyProps={{ className: styles.secondaryText }}
              secondaryTypographyProps={{ className: styles.secondaryText }}
            />
          </Grid>
        </Grid>
      </Grid>
      {modalError && (
        <GenericErrorMessageModal
          title={t("error_occurred")}
          error={modalError}
          onClosehandler={() => setModalError(null)}
        />
      )}
    </>
  );
};

export default AppointmentTreeItemHeaderbar;
