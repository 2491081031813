import React, { FC, useEffect, useState } from "react";
import HighFiveIcon from "../../icons/highFiveIcon";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import Button from "../forms/inputs/button";
import { t } from "i18next";
import { DotLoader } from "react-spinners";
import { List, ListItem, ListItemText, Typography } from "@mui/material";
import styles from './timeSlotPickConfirmation.module.css';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

interface Props {
  appointmentInformation?: any;
}

const TimeSlotPickConfirmation: FC<Props> = ({ appointmentInformation }) => {
  const [currentAppointmentInformation, setCurrentAppointmentInformation] =
    useState<any>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const navigate = useNavigate();

  useEffect(() => {
    setCurrentAppointmentInformation(appointmentInformation);
    setIsLoading(false);
  }, [appointmentInformation]);

  const navigateToTodo = () => {
    navigate("/todo/");
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const renderSecondaryText = () => {
    return (
      <div className={styles.secondaryText}>
        <span>{t("secondary_mail_text_slot_confirmation")}</span>
        <p>
          <span>
            {t("contact_our_service_team") + " "}

            <a className={styles.emailLink} href="mailto:service@kaerhealth.com">
              {t("email_service_kaer")}
            </a>
          </span>
        </p>
      </div>
    );
  };

  const renderContent = () => {
    const dateTime = dayjs(currentAppointmentInformation?.datetime).format(
      "DD.MM.YYYY, HH:mm"
    );
    const description = (
      <List className={styles.noPadding}>
        <ListItem className={styles.noPadding}>
          <ListItemText
            primary={
              <Typography
                className={styles.typoUnderlined}
                component="span"
                variant="body2"
                color="text.primary"
              >
                {t("confirmation_expecting_you")}
              </Typography>
            }
          />
        </ListItem>
        {currentAppointmentInformation?.examination_set.map(
          (examination: any) => {
            return (
              <ListItem key={examination.name} className={styles.noPadding}>
                <ListItemText
                  secondaryTypographyProps={{ component: "div" }}
                  primary={
                    <Typography
                      className={styles.displayBlock}
                      component="span"
                      variant="body2"
                      color="text.primary"
                    >
                      {examination?.examination_name}
                    </Typography>
                  }
                  secondary={
                    <Typography
                      component="span"
                      variant="body2"
                      color="text.secondary"
                      sx={{ whiteSpace: "preserve" }}
                    >
                      {examination?.examination_description}
                    </Typography>
                  }
                />
              </ListItem>
            );
          }
        )}
      </List>
    );

    return (
      <>
        {isLoading ? (
          <DotLoader
            color="#8c1ec8"
            size={65}
            cssOverride={{ position: "absolute", top: "45vh", left: "50vw" }}
          />
        ) : (
          <div className={styles.content}>
            <div className={styles.iconContainer}>
              <HighFiveIcon width="160" height="159" />
              <h2 className={styles.heading}>
                {t("appointment_booking_successful")}
              </h2>
            </div>
            <div className={styles.tableContainer}>
              <table className={styles.table} border={0}>
                <colgroup>
                  <col className={styles.tableCol1} />
                  <col className={styles.tableCol2} />
                </colgroup>
                <tbody>
                  <tr className={styles.tableRow}>
                    <td className={styles.tableHeader}>
                      {t("confirmation_date_time")}
                    </td>
                    <td className={styles.tableData}>
                      <div>{dateTime} Uhr</div>
                    </td>
                  </tr>
                  <tr className={styles.tableRow}>
                    <td className={styles.tableDescription}>
                      {t("confirmation_description_process")}
                    </td>
                    <td className={styles.tableData}>
                      <div>{description}</div>
                    </td>
                  </tr>
                </tbody>
              </table>
              <Button
                title={
                  !isMobile ? (
                    t("new_todo_after_picked_slot")
                  ) : (
                    <>
                      {t("new_todo_after_picked_slot_short")}
                      <br />
                      {t("click_here")}
                    </>
                  )
                }
                variant="contained"
                className={styles.button}
                onClick={navigateToTodo}
              />
              {renderSecondaryText()}
            </div>
          </div>
        )}
      </>
    );
  };
  return (
    <>
      <div className={styles.container}>
        {renderContent()}
      </div>
    </>
  );
};

export default TimeSlotPickConfirmation;
