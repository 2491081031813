import React, { FC } from "react";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import styles from './customErrorMessage.module.css';

interface Props {
  errorMessage?: any;
  error?: any;
  errorFontColor?: any;
  errorFontSize?: any;
}

const CustomErrorMessage: FC<Props> = (props: Props) => {
  let icon = <></>;

  switch (props.error) {
    case "error":
      icon = <ErrorOutlineIcon color="error" />;
      break;
    case "success":
      icon = <CheckCircleOutlineIcon color="success" />;
  }
  return (
    <div className={styles.errorWrapper}>
      {icon}
      <span
        className={styles.errorSpan}
        style={{ color: props.errorFontColor, fontSize: props.errorFontSize }}
      >
        {props.errorMessage}
      </span>
    </div>
  );
};

export default CustomErrorMessage;
