import { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import getDay from "date-fns/getDay";
import eachDayOfInterval from "date-fns/eachDayOfInterval";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from "react-datepicker";
import de from "date-fns/locale/de";
import styles from "./dateTimePicker.module.css";

interface Props {
  onlyWeekDays?: boolean;
  handleDateRange?: (item: any) => void;
  filterDate?(date: Date): boolean;
  selectsRange?: boolean;
  startTime?: any;
  endTime?: any;
  calculatedDays: any;
  selectedDays: any;
  disabled?: boolean;
}

export function DateTimePicker(props: Props) {
  const [dateRange, setDateRange] = useState<Date[] | undefined[] | null[]>([
    null,
    null,
  ]);
  const [startDate, endDate] = dateRange;
  registerLocale("de", de);

  useEffect(() => {
    if (props.startTime && props.endTime) {
      setDateRange([props.startTime.$d, props.endTime.$d]);
    }
  }, [props.startTime, props.endTime]);

  //disables Weekends in the calendar
  const isWeekday = (date: any) => {
    const day = getDay(date);
    return day !== 0 && day !== 6;
  };

  const eachDayOfRange =
    startDate &&
    endDate &&
    eachDayOfInterval({ start: startDate, end: endDate });

  let appointmentRequestDays: any;
  if (props.onlyWeekDays) {
    appointmentRequestDays =
      eachDayOfRange !== null &&
      eachDayOfRange?.filter((x: any) => isWeekday(x));
  } else {
    appointmentRequestDays = eachDayOfRange;
  }

  const updateRange = (update: any) => {
    setDateRange(update);
    if (props.handleDateRange) {
      props.handleDateRange(appointmentRequestDays);
    }
  };

  useEffect(() => {
    if (props.handleDateRange) {
      if (startDate && !endDate) {
        props.handleDateRange(startDate);
      }
      if (startDate && endDate) {
        props.handleDateRange(appointmentRequestDays);
      }
    }
  }, [startDate, endDate]);

  return (
    <>
      <DatePicker
        selectsRange={true}
        onChange={(update) => updateRange(update)}
        locale={"de"}
        calendarStartDay={1}
        startDate={startDate}
        endDate={endDate}
        filterDate={props.filterDate}
        inline={!props.disabled}
        disabled={props.disabled}
      >
        <div className={styles.datePickerWrapper}>
          <p>
            Benötigt:{" "}
            <span className={styles.datePickerText}>{props.calculatedDays}</span>
          </p>
          <p>
            Ausgewählt:
            <span className={styles.datePickerText}>{props.selectedDays}</span>
          </p>
        </div>
      </DatePicker>
    </>
  );
}
