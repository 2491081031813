import { useState, useEffect } from "react";

interface Props {
  visibility: any;
  fieldname: string;
  className?: string;
  children?: React.ReactNode;
}

export default function FindingFormVisibilityWrapper(props: Props) {
  const [visible, setVisible]: any = useState(true);

  useEffect(() => {
    if (props.fieldname in props.visibility) {
      if (props.visibility[props.fieldname]) {
        setVisible(true);
      } else {
        setVisible(false);
      }
    } else {
      setVisible(true);
    }
  }, [props.visibility]);

  if (!visible) {
    return <></>;
  }

  return <div className={props.className}>{props.children}</div>;
}
