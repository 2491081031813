import React, { FC } from "react";
import { FormControl, Grid, InputLabel, MenuItem, Select } from "@mui/material";
import { t } from "i18next";

interface Props {
  onChange?: any;
}

const CsvExport: FC<Props> = ({ onChange }) => {
  const [csvFileExportTarget, setCsvFileExportTarget] = React.useState("");

  const handleChange = (event: any) => {
    setCsvFileExportTarget(event.target.value);
    onChange(event.target.value);
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <FormControl fullWidth={true}>
          <InputLabel id="csv_export">{t("csv_export")}</InputLabel>
          <Select
            labelId="csv_export"
            id="csv_export"
            value={csvFileExportTarget}
            label={t("csv_export")}
            onChange={handleChange}
          >
            <MenuItem value={"vistec"}>Vistec</MenuItem>
            <MenuItem value={"tutoolio"}>Tutoolio</MenuItem>
            <MenuItem value={"labor"}>Labor</MenuItem>
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  );
};

export default CsvExport;
