import React, { FC } from "react";
import BuildingTable from "../components/buildings/buildingTable";
import Restricted from "../consumer/restricted";

interface Props {
  subdomain?: string;
}

const Buildings: FC<Props> = () => {
  return (
    <Restricted permissionRequired="frontend_view_buildings">
      <BuildingTable />
    </Restricted>
  );
};

export default Buildings;
