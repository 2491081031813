import { useEffect, useRef, useState } from "react";
import { ISubject } from "../../types/Entities";
import { useTranslation } from "react-i18next";
import { IDropDownOption } from "../../types/DropDown";
import DefaultInput from "../../components/forms/inputs/defaultInput";
import DropDown from "../../components/forms/inputs/dropDown";
import { TextArea } from "../../components/forms/inputs/textArea";
import DropDownSubjectSearch from "../../components/forms/inputs/dropDownSubjectSearch";
import { api } from "../../helper/api";
import Button from "../../components/forms/inputs/button";
import GenericErrorMessageModal from "../../components/forms/errorHandling/genericErrorMessageModal";
import { Grid, Paper, Checkbox, FormControlLabel } from "@mui/material";
import { DotLoader } from "react-spinners";
import GenericNotification from "../../components/notification/genericNotification";
import Restricted from "../../consumer/restricted";
import styles from "./todoCreator.module.css";

export default function TodoCreator() {
  const [subject, setSubject] = useState<ISubject>();
  const [type, setType] = useState<any>();
  const [formData, setFormData] = useState<any>({});
  const [targetFolders, setTargetFolders] = useState<any>();
  /*eslint-disable */
  const [subjectOptions, setSubjectOptions] = useState<IDropDownOption[]>([]);
  /*eslint-enable */
  const [disabledSaveButton, setDisabledSaveButton] = useState(true);
  const [error, setError] = useState<any>();
  const [subjectIsCompany, setSubjectIsCompany] = useState<boolean>(false);
  const [chooseDepartments, setChooseDepartments] = useState<boolean>(false);
  const [departmentOptions, setDepartmentOptions] = useState<any>([]);
  const [selectedDepartments, setSelectedDepartments] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [showNotification, setShowNotification] = useState<boolean>(false);
  const [notificationMessage, setNotificationMessage] = useState<any>("");
  const [notificationVariant, setNotificationVariant] = useState<
    "success" | "error" | "warning" | "info" | undefined
  >();

  const { t } = useTranslation();
  const todoCreatorRef = useRef(null);
  useEffect(() => {
    setIsLoading(false);
  });

  const resetStateOnCloseNotification = () => {
    setShowNotification(false);
    setNotificationMessage("");
  };

  const handleSubjectChange = (selectedOption: any) => {
    const additionalUrlParameters: any = {};
    if (
      selectedOption &&
      typeof selectedOption === "object" &&
      selectedOption.value
    ) {
      if (selectedOption.companyId) {
        setSubjectIsCompany(true);
        setChooseDepartments(false);
      } else {
        setSubjectIsCompany(false);
      }

      setSubject(selectedOption.value);
      setDisabledSaveButton(false);
      additionalUrlParameters["impersonate_subject"] = selectedOption.value;
      api.genericApiRequest({
        entity: "folder",
        method: "get",
        parametersToRender: {
          depth: "0",
          additionalUrlParameters: additionalUrlParameters,
        },
        successHandler: (res: any) => {
          const folders: any = [];

          const sortedData = res.data.results.sort((a: any, b: any) =>
            a.name.localeCompare(b.name)
          );

          for (const key in sortedData) {
            const item = sortedData[key];
            folders.push({
              label: item.name,
              value: item.id,
            });
          }

          setTargetFolders(folders);
        },
        failHandler: (error: any) => {
          setError(error);
        },
      });
    }
  };

  useEffect(() => {
    if (chooseDepartments) {
      const additionalUrlParameters: any = {};
      additionalUrlParameters["impersonate_subject"] = subject;

      api.genericApiRequest({
        method: "get",
        entity: "department",
        parametersToRender: {
          depth: "0",
          additionalUrlParameters: additionalUrlParameters,
        },
        successHandler: (res: any) => {
          const departmentOptions = [];
          for (const key in res.data.results) {
            const item = res.data.results[key];
            departmentOptions.push({ value: item.id, label: item.name });
          }
          setDepartmentOptions(departmentOptions);
        },
        failHandler: (error: any) => {
          setError(error);
        },
      });
    }
  }, [chooseDepartments]);

  const handleFormDataChange = (key: string, value: any) => {
    const newFormData: any = formData;
    newFormData[key] = value;
    setFormData(newFormData);
  };

  const handleTypeChange = (selectedOption: any) => {
    setType(selectedOption);
    if (selectedOption) {
      handleFormDataChange("type", selectedOption.value);
    }
  };

  const handleInputChange = (name: string, event: any) => {
    if (event) {
      let value = "";
      if (event["label"] !== undefined) {
        value = event.value;
      } else {
        value = event.target.value;
      }
      handleFormDataChange(name, value);
    }
  };

  const getFormDataItem = (key: string) => {
    if (formData[key] != undefined) {
      return formData[key];
    } else {
      return "";
    }
  };

  const getTargetJson = () => {
    const target: any = {};
    let typeString = "";
    if (type) {
      typeString = type.value;
    }
    switch (typeString) {
      case "text":
        if (
          getFormDataItem("link") != "" &&
          getFormDataItem("link") != "https://" &&
          getFormDataItem("linkTitle") != ""
        ) {
          target["link"] = getFormDataItem("link");
          target["linkTitle"] = getFormDataItem("linkTitle");
        }
        break;
      case "upload":
        if (
          getFormDataItem("folder") != "" &&
          getFormDataItem("filename") != ""
        ) {
          target["folder"] = formData["folder"];
          target["filename"] = formData["filename"];
        }
        break;
    }

    return target;
  };

  const createNewTodo = async () => {
    let allUsers: any = [];
    try {
      const additionalUserParameters: any = {};
      additionalUserParameters["impersonate_subject"] = subject;
      additionalUserParameters["department_set"] = selectedDepartments;

      const promises = [];
      promises.push(
        new Promise((resolve: any) => {
          api.genericApiRequest({
            method: "get",
            entity: "user",
            parametersToRender: {
              depth: "0",
              additionalUrlParameters: additionalUserParameters,
            },
            successHandler: (res: any) => {
              resolve(res.data.results);
            },
            failHandler: (error: any) => {
              setError(error);
            },
          });
        })
      );
      await Promise.all(promises).then((value: any) => {
        for (const key in value) {
          const result = value[key];
          allUsers = result;
        }
      });
    } catch (error) {
      console.log(error);
    }

    if (chooseDepartments && allUsers.length !== 0) {
      multiPostRequest(allUsers);
    } else singlePostRequest();
  };

  const multiPostRequest = async (users: any) => {
    setIsLoading(true);
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {
      const promises = users.map((user: any) => {
        return new Promise((resolve) => {
          api.genericApiRequest({
            entity: "todo",
            method: "post",
            successHandler: () => {
              resolve({
                user: user.first_name + ", " + user.last_name,
                status: true,
              });
            },
            failHandler: (error: any) => {
              resolve({
                user: user.first_name + ", " + user.last_name,
                status: false,
                error: error,
              });
            },
            submitData: {
              description: getFormDataItem("description"),
              done: "false",
              due_date: new Date(Date.now()).toISOString(),
              name: getFormDataItem("title"),
              subject: user.person.subject,
              type: type.value,
              target: getTargetJson(),
            },
            config: config,
          });
        });
      });
      await Promise.all(promises).then((value: any) => {
        const successfullRequests = [];
        const failedRequests = [];
        for (const object of value) {
          if (object.status === false) {
            failedRequests.push(object);
          } else successfullRequests.push(object);
        }

        if (failedRequests.length !== 0) {
          const combinedErrors = failedRequests.reduce((acc, obj) => {
            const key = Object.keys(obj)[0];
            acc[key] = obj[key];
            return acc;
          }, {});
          for (const failedObj of failedRequests) {
            if (failedObj.status === false) {
              setNotificationMessage(
                t("todo_create_failed", { user: failedObj.user })
              );
              setNotificationVariant("error");
              setShowNotification(true);
            }
          }
          for (const obj of successfullRequests) {
            setNotificationMessage(
              t("todo_successfully_created", { user: obj.user })
            );
            setNotificationVariant("success");
            setShowNotification(true);
          }
          setError(combinedErrors);
        }
        if (failedRequests.length === 0) {
          for (const obj of successfullRequests) {
            setNotificationMessage(
              t("todo_successfully_created", { user: obj.user })
            );
            setNotificationVariant("success");
            setShowNotification(true);
          }
        }
        setIsLoading(false);
      });
    } catch (error: any) {
      console.log(error);
    }
  };

  const singlePostRequest = () => {
    setIsLoading(true);
    api.genericApiRequest({
      entity: "todo",
      method: "post",
      successHandler: () => {
        alert("Success.");
        setIsLoading(false);
      },
      failHandler: (error: any) => {
        setError(error);
      },
      submitData: {
        description: getFormDataItem("description"),
        done: "false",
        due_date: new Date(Date.now()).toISOString(),
        name: getFormDataItem("title"),
        subject: subject,
        type: type.value,
        target: getTargetJson(),
      },
    });
  };

  const handleCheckboxChange = (event: any) => {
    setChooseDepartments(event.target.checked);
  };
  const handleSelectedDepartments = (selectedOption: any) => {
    if (selectedOption) {
      const ids = [];
      for (const key in selectedOption) {
        const item = selectedOption[key];
        ids.push(item.value);
      }
      setSelectedDepartments(ids);
    }
  };

  const inputStyle = {
    width: "100%",
  } as React.CSSProperties;

  let additionalInputs = <></>;
  let typeString = "";
  if (type) {
    typeString = type.value;
  }
  switch (typeString) {
    case "text":
      additionalInputs = (
        <>
          <Grid item xs={12}>
            <DefaultInput
              onChange={(event: any) => {
                handleInputChange("link", event);
              }}
              label={t("link_destination_optional")}
              inputClassName={styles.fullWidth}
              defaultValue="https://"
            />
          </Grid>
          <Grid item xs={12}>
            <DefaultInput
              onChange={(event: any) => {
                handleInputChange("linkTitle", event);
              }}
              label={t("link_title_optional")}
              inputClassName={styles.fullWidth}
            />
          </Grid>
        </>
      );
      break;
    case "upload":
      if (targetFolders) {
        additionalInputs = (
          <>
            <Grid item xs={12}>
              <DropDown
                onChange={(event: any) => {
                  handleInputChange("folder", event);
                }}
                options={targetFolders}
                label={t("target_directory")}
              />
            </Grid>
            <Grid item xs={12}>
              <DefaultInput
                onChange={(event: any) => {
                  handleInputChange("filename", event);
                }}
                label={t("file_name")}
                inputClassName={styles.fullWidth}
              />
            </Grid>
          </>
        );
      }
      break;
  }

  return (
    <Restricted permissionRequired="frontend_view_todo_creator">
      {isLoading ? (
        <DotLoader
          color="#8c1ec8"
          size={65}
          cssOverride={{ position: "absolute", top: "45vh", left: "50vw" }}
        />
      ) : (
        <Paper className={styles.paperContainer}>
          <Grid container rowSpacing={"1rem"}>
            <Grid item xs={12}>
              <h1>Todo Creator</h1>
            </Grid>
            <Grid item xs={12}>
              <DropDownSubjectSearch
                subdomain="admin"
                onChange={handleSubjectChange}
                autofocus={true}
                customRef={todoCreatorRef}
                placeholder={t("choose_find_subject")}
              />
            </Grid>
            {subjectIsCompany === true && (
              <>
                <Grid item xs={12}>
                  <FormControlLabel
                    label={t("todo_for_multiple_departments")}
                    control={
                      <Checkbox
                        onChange={(event: any) => handleCheckboxChange(event)}
                        checked={chooseDepartments}
                      />
                    }
                  />
                </Grid>
                {chooseDepartments && departmentOptions.length !== 0 && (
                  <Grid item xs={12}>
                    <DropDown
                      label={t("departments")}
                      placeholder={t("choose_departments")}
                      options={departmentOptions}
                      isMulti={true}
                      isClearable={true}
                      onChange={handleSelectedDepartments}
                    />
                  </Grid>
                )}
              </>
            )}
            <Grid item xs={12}>
              <DropDown
                name="type"
                label="Typ"
                placeholder={t("choose_type")}
                options={[
                  { value: "upload", label: "upload" },
                  { value: "text", label: "text" },
                ]}
                onChange={handleTypeChange}
              />
            </Grid>
            <Grid item xs={12}>
              <DefaultInput
                label="Titel"
                inputClassName={styles.fullWidth}
                onChange={(event: any) => {
                  handleInputChange("title", event);
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextArea
                label="Beschreibung"
                withLabel={true}
                onChange={(event: any) => {
                  handleInputChange("description", event);
                }}
                rows={4}
                cols={65}
                labelClassName={styles.textArea__label}
                fieldClassName={styles.textArea__field}
              />
            </Grid>
            {additionalInputs}
            <Grid item>
              <Button
                variant="contained"
                color="secondary"
                title={t("create")}
                disabled={disabledSaveButton}
                onClick={() => createNewTodo()}
              />
            </Grid>
            {error && error !== "" && (
              <GenericErrorMessageModal
                title={t("error_occurred")}
                error={error}
                onClosehandler={() => {
                  setError("");
                }}
              />
            )}
            {showNotification && (
              <GenericNotification
                message={notificationMessage}
                variant={notificationVariant}
                handleCloseSnackbar={resetStateOnCloseNotification}
              />
            )}
          </Grid>
        </Paper>
      )}
    </Restricted>
  );
}
