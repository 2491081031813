import ArrowDropDownCircleIcon from "@mui/icons-material/ArrowDropDownCircle";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import TextFieldsIcon from "@mui/icons-material/TextFields";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import TitleIcon from "@mui/icons-material/Title";
import styles from "./dndLeftColumnWrapper.module.css";

interface Props {
  type?: string;
  label?: string;
  name?: string;
}

export function LeftColumnWrapper(props: Props) {
  const truncateLabel = (string: any, maxLength: any) => {
    if (string.length > maxLength) {
      return string.substring(0, maxLength) + "...";
    }
    return string;
  };
  return (
    <div className={styles.leftColumnWrapper}>
      <div className={styles.flexContainer}>
        {(function () {
          switch (props.type) {
            case "text":
              return <TextFieldsIcon />;
              break;
            case "bool":
              return <RadioButtonCheckedIcon />;
              break;
            case "choice":
              return <ArrowDropDownCircleIcon />;
              break;
            case "date":
              return <CalendarMonthIcon />;
              break;
            default:
              return <TitleIcon />;
          }
        })()}
        <div className={styles.label}>{truncateLabel(props.label, 40)}</div>
      </div>
      <div className={styles.name}>{`(${props?.name})`}</div>
    </div>
  );
}
