import { useTranslation } from "react-i18next";
import GenericCrudTable from "../../components/tables/genericCrudTable";
import Restricted from "../../consumer/restricted";

export default function FindingList() {
  const { t } = useTranslation();

  const replaceDate = (id: any) => {
    const date = new Date(id).toLocaleString();
    return date;
  };
  return (
    <Restricted permissionRequired="frontend_view_finding">
      <GenericCrudTable
        allowColumnFiltering={true}
        showColumnFilterbyDefault={true}
        heading={t("findings")}
        entity={"finding"}
        columns={[
          { header: "ID", accessorKey: "id", id: "id" },
          {
            header: t("name"),
            accessorKey: "name",
            id: "name",
            enableForCreate: true,
            required: true,
            errorMessage: t("field_required"),
          },
          {
            header: t("appointment"),
            accessorKey: "appointment",
            id: "appointment",
            required: false,
            errorMessage: t("field_required"),
            enableForCreate: false,
            accessorFn: (row: any) => {
              return row.appointment.id;
            },
          },
          {
            header: t("probe"),
            accessorKey: "probe",
            id: "probe",
            customColumnFilterId: "probe__name",
            required: true,
            errorMessage: t("field_required"),
            enableForCreate: true,
            entity: "probe",
            accessorFn: (row: any) => {
              return row.probe.name;
            },
          },
          {
            header: t("created_on"),
            accessorKey: "created_on",
            id: "created_on",
            filterVariant: "date",

            sortingFn: "datetime",
            Cell: ({ cell }: any) => {
              return <span>{replaceDate(cell.getValue())}</span>;
            },
          },
          {
            header: t("created_by"),
            accessorKey: "created_by",
            customColumnFilterId: "created_by_name",

            id: "created_by",
            Cell: ({ cell }: any) => {
              return <span>{cell.getValue().name}</span>;
            },
          },
          {
            header: t("modified_on"),
            accessorKey: "modified_on",
            id: "modified_on",
            filterVariant: "date",

            sortingFn: "datetime",
            Cell: ({ cell }: any) => {
              return <span>{replaceDate(cell.getValue())}</span>;
            },
          },
          {
            header: t("modified_by"),
            accessorKey: "modified_by",
            customColumnFilterId: "modified_by_name",

            id: "modified_by",
            Cell: ({ cell }: any) => {
              return <span>{cell.getValue().name}</span>;
            },
          },
        ]}
      />
    </Restricted>
  );
}
