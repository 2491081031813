import { Trans } from "react-i18next";
import { Grid } from "@mui/material";
import "./dataProtectionInformation.css";

export default function DataProtectionInformation() {
  return (
    <Grid container className="dataProtectionInformation">
      <Grid item xs={9} ml={"auto"} mr={"auto"}>
        <Trans
          i18nKey="data_protection_translations"
          components={{
            h1: <h1 style={{ }} />,
            h2: <h2 style={{ }} />,
            h3: <h3 style={{ }} />,
            h4: <h4 style={{ }} />,
            p: <p />,
            ul: <ul />,
            li: <li />,
            a: <a />,
          }}
        />
      </Grid>
    </Grid>
  );
}

// NOTE: The header tags always expect a style prop to be passed in, even if it is empty.