import React, { FC, useEffect, useState } from "react";
import { api } from "../../helper/api";
import { Grid, IconButton, Tooltip } from "@mui/material";
import Button from "../forms/inputs/button";
import { t } from "i18next";
import styles from "./permission.module.css";
import GenericErrorMessageModal from "../forms/errorHandling/genericErrorMessageModal";
import GenericNotification from "../notification/genericNotification";
import { useNavigate, useParams } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { DotLoader } from "react-spinners";

interface Props {
  title?: string;
  group?: any;
}

const PermissionGroupTable: FC<Props> = () => {
  const [allPermissions, setAllPermissions] = useState<any>([]);
  const [groupName, setGroupName] = useState<string>("");
  const [groupPermissions, setGroupPermissions] = useState<any>([]);
  const [error, setError] = useState<any>();
  const [showNotification, setShowNotification] = useState<boolean>(false);
  const [notificationMessage, setNotificationMessage] = useState<any>("");
  const [notificationVariant, setNotificationVariant] = useState<
    "success" | "error" | "warning" | "info" | undefined
  >();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const { groupId } = useParams() as {
    groupId: string;
  };

  const navigate = useNavigate();

  useEffect(() => {
    getAllPermissions();
  }, []);

  useEffect(() => {
    if (groupId) {
      api.genericApiRequest({
        entity: "group",
        method: "get",
        entityId: groupId,
        successHandler: (res: any) => {
          const data = res.data;
          setGroupPermissions(data.permissions);
          setGroupName(data.name);
          setIsLoading(false);
        },
        failHandler: (error: any) => {
          setError(error);
        },
      });
    }
  }, [groupId]);

  const resetStateOnCloseNotification = () => {
    setShowNotification(false);
    setNotificationMessage("");
  };

  const getAllPermissions = () => {
    let itemCollection: any = [];
    const additionalUrlParameters: any = {};

    additionalUrlParameters["depth"] = "0";
    additionalUrlParameters["limit"] = "50";

    api.genericApiRequest({
      entity: "permission",
      method: "get",
      getAllPages: true,
      successHandler: (res: any) => {
        itemCollection = res.data.results;
        setAllPermissions(
          itemCollection.sort(
            (a: any, b: any) => a.content_type - b.content_type
          )
        );
      },
      failHandler: (error: any) => {
        setError(error);
      },
      parametersToRender: additionalUrlParameters,
    });
  };

  const handleCheckboxChange = (isChecked: boolean, permissionName: any) => {
    if (isChecked) {
      if (!groupPermissions.includes(permissionName)) {
        setGroupPermissions((prevPermissions: any) => [
          ...prevPermissions,
          permissionName,
        ]);
      }
    } else {
      setGroupPermissions((prevPermissions: any) =>
        prevPermissions.filter((name: any) => name !== permissionName)
      );
    }
  };

  const hanldeUpdateGroup = (skipQuestion = false) => {
    if (!skipQuestion) {
      if (
        /*eslint-disable */
        !confirm(t("confirm_save_new_grouppermissions", { group: groupName }))
        /*eslint-enable */
      ) {
        return;
      }
    }
    const result = { name: groupName, permissions: groupPermissions };
    const additionalUrlParameters: any = { include_kaer_internal: true };

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    api.genericApiRequest({
      entity: "group",
      method: "put",
      parametersToRender: additionalUrlParameters,
      submitData: result,
      config: config,
      entityId: groupId,
      successHandler: () => {
        setNotificationMessage(t("save_successful"));
        setNotificationVariant("success");
        setShowNotification(true);
      },
      failHandler: (error: any) => {
        setError(error);
      },
    });
  };
  let currentContentType: any = null;

  const handleNavigateBack = () => {
    navigate("/admin/permissionGroupList");
  };
  return (
    <>
      {isLoading ? (
        <DotLoader
          color="#8c1ec8"
          size={65}
          cssOverride={{ position: "absolute", top: "45vh", left: "50vw" }}
        />
      ) : (
        <div className={styles.permissionWrapper}>
          <Tooltip placement="right" title={t("back")}>
            <IconButton onClick={handleNavigateBack}>
              <ArrowBackIcon />
            </IconButton>
          </Tooltip>
          <Grid
            container
            className={styles.permissionsContainer}
          >
            {groupId !== "" && (
              <Grid item xs={12}>
                <h1>Berechtigungen</h1>
                <Grid item xs={12}>
                  <h2>
                    {t(groupName)} ({groupName})
                  </h2>
                </Grid>
                <table className={styles.permissionTable}>
                  <tbody>
                    {allPermissions.map((permission: any) => {
                      const { name, content_type, id, codename } = permission;
                      const isNewContentType =
                        content_type !== currentContentType;
                      currentContentType = content_type;
                      return (
                        <React.Fragment key={id}>
                          {isNewContentType && (
                            <tr className="permissionSectionHeading">
                              <th>{content_type}</th>
                              <th>{t("permitted")}</th>
                            </tr>
                          )}
                          <tr>
                            <td>{name}</td>
                            <td>
                              <input
                                type="checkbox"
                                name={codename}
                                checked={groupPermissions.includes(codename)}
                                onChange={(event: any) => {
                                  handleCheckboxChange(
                                    event.target.checked,
                                    codename
                                  );
                                }}
                              />
                            </td>
                          </tr>
                        </React.Fragment>
                      );
                    })}
                  </tbody>
                </table>
              </Grid>
            )}
            {groupId !== "" && (
              <Grid
                item
                container
                xs={12}
                className={styles.buttonsContainer}
              >
                <Grid item>
                  <Button
                    title={t("back")}
                    variant="outlined"
                    onClick={handleNavigateBack}
                  />
                </Grid>
                <Grid item>
                  <Button
                    title={t("save")}
                    variant="outlined"
                    onClick={() => hanldeUpdateGroup(false)}
                  />
                </Grid>
              </Grid>
            )}
          </Grid>
        </div>
      )}
      {error && error !== "" && (
        <GenericErrorMessageModal
          title={t("error_occurred")}
          error={error}
          onClosehandler={() => {
            setError("");
          }}
        />
      )}
      {showNotification && (
        <GenericNotification
          message={notificationMessage}
          variant={notificationVariant}
          handleCloseSnackbar={resetStateOnCloseNotification}
        />
      )}
    </>
  );
};

export default PermissionGroupTable;
