import React, { FC } from "react";
import DocumentTable from "../tables/simpleTableViews/documentTable";

interface Props {
  subjectId?: any;
  subjectForDispatch?: any;
}

const SubjectOverviewDocuments: FC<Props> = ({
  subjectId,
  subjectForDispatch,
}) => {
  return (
    <>
      <DocumentTable
        customPageSize={5}
        folder="all"
        subjectId={subjectId}
        customDensity="compact"
        tableMargin={"0"}
        buttonLinkToDocuments={true}
        tableBoxShadow={"none"}
        subjectForDispatch={subjectForDispatch}
      />
    </>
  );
};

export default SubjectOverviewDocuments;
