import { PayloadAction, createAction, createSlice } from "@reduxjs/toolkit";
const clearAction = createAction("clear");
const clearSubjectSearch = createAction("clearSubjectSearch");

export interface SubjectSearchState {
  value: string | number;
  label: string;
  treeId?: any;
  companyId?: any;
}

const initialState: any = [];

const subjectSearchSlice = createSlice({
  name: "subjectSearch",
  initialState,
  reducers: {
    setSubjectSearch: (state, action: PayloadAction<any>) =>
      (state = action.payload),
  },
  extraReducers: (builder) => {
    builder.addCase(clearAction, () => initialState);
    builder.addCase(clearSubjectSearch, () => initialState);
  },
});

export const { setSubjectSearch } = subjectSearchSlice.actions;
export default subjectSearchSlice.reducer;
