import React, { FC } from "react";

import { Controller } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import CustomErrorMessage from "../inputsWithRef/customErrorMessage";

interface Props {
  id: any;
  label: any;
  control: any;
  name: any;
  required?: any;
  requiredErrorMessage?: any;
  style?: any;
  className?: string;
  errorWrapperStyle?: any;
  errorFontColor?: any;
}

const CheckboxWithControl: FC<Props> = ({
  id,
  label,
  control,
  name,
  required,
  requiredErrorMessage,
  style,
  className,
  errorWrapperStyle,
  errorFontColor,
}) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={{
        required: { value: required, message: requiredErrorMessage },
      }}
      render={({ field: { onChange, ...rest }, formState: { errors } }) => (
        <>
          <label className={className} style={style} htmlFor={id}>
            <input {...rest} id={id} type="checkbox" onChange={onChange} />
            {label}
          </label>
          <ErrorMessage
            errors={errors}
            name={name as string}
            render={({ message }) => (
              <div style={errorWrapperStyle}>
                <CustomErrorMessage
                  error={"error"}
                  errorMessage={message}
                  errorFontColor={errorFontColor}
                />
              </div>
            )}
          />
        </>
      )}
    />
  );
};

export default CheckboxWithControl;
