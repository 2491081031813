import React, { FC } from "react";
import { CreateNewAppointmentRequest } from "../components/appointmentRequest/createNewAppointmentRequest";
import Restricted from "../consumer/restricted";

const AppointmentRequestCreate: FC = () => {
  return (
    <Restricted permissionRequired="frontend_view_appointment_request">
      <CreateNewAppointmentRequest />
    </Restricted>
  );
};

export default AppointmentRequestCreate;
