import { PayloadAction, createAction, createSlice } from "@reduxjs/toolkit";
const clearAction = createAction("clear");
const clearAppointmentDashboardExecutedByFilter = createAction(
  "clearAppointmentDashboardExecutedByFilter"
);

export interface executedByFilterState {
  value: string;
}

const initialState: any = { value: "" };

const appointmentDashboardExecutedByFilterSlice = createSlice({
  name: "appointmentDashboardExecutedByFilter",
  initialState,
  reducers: {
    setAppointmentDashboardExecutedByFilter: (
      state,
      action: PayloadAction<any>
    ) => (state = { value: action.payload }),
  },
  extraReducers: (builder) => {
    builder.addCase(clearAction, () => initialState);
    builder.addCase(
      clearAppointmentDashboardExecutedByFilter,
      () => initialState
    );
  },
});

export const { setAppointmentDashboardExecutedByFilter } =
  appointmentDashboardExecutedByFilterSlice.actions;
export default appointmentDashboardExecutedByFilterSlice.reducer;
