import { Button as MuiButton, Tooltip } from "@mui/material";

interface Props {
  onClick?: (event: any) => void;
  disabled?: any;
  title?: string | any;
  type?: "submit" | "reset" | "button" | undefined;
  style?: React.CSSProperties;
  color?:
    | "primary"
    | "secondary"
    | "warning"
    | "error"
    | "success"
    | "info"
    | "inherit"
    | undefined;
  variant?: "contained" | "text" | "outlined" | undefined;
  className?: any;
  component?: any;
  withTooltip?: boolean;
  tooltipTitle?: any;
  textTransform?: any;
  tooltipPlacement?:
    | "bottom"
    | "left"
    | "right"
    | "top"
    | "bottom-end"
    | "bottom-start"
    | "left-end"
    | "left-start"
    | "right-end"
    | "right-start"
    | "top-end"
    | "top-start"
    | undefined;
}
export default function Button(props: Props) {
  if (props.withTooltip) {
    return (
      <Tooltip
        arrow
        placement={props.tooltipPlacement}
        title={props.tooltipTitle}
      >
        <MuiButton
          color={props.color ? props.color : undefined}
          variant={props.variant ? props.variant : undefined}
          style={props.style}
          type={props.type ? props.type : "button"}
          onClick={props.onClick}
          disabled={props.disabled}
          className={props.className}
          sx={{
            width: "fit-Content",
            textTransform: props.textTransform,
          }}
        >
          {props.title}
        </MuiButton>
      </Tooltip>
    );
  } else
    return (
      <MuiButton
        color={props.color ? props.color : undefined}
        variant={props.variant ? props.variant : undefined}
        style={props.style}
        type={props.type ? props.type : "button"}
        onClick={props.onClick}
        disabled={props.disabled}
        className={props.className}
        sx={{ width: "fit-Content", textTransform: props.textTransform }}
      >
        {props.title}
      </MuiButton>
    );
}
