import React, { useRef } from "react";
import { Droppable } from "react-beautiful-dnd";
import { FixedSizeList } from "react-window";
import Item from "./dndItem";
import { Row } from "./dndRow";
import "./formEditorStyles.css";
import styles from "./dndItemList.module.css";

interface Props {
  column?: any;
  preExistingFormElements?: any;
  onHandleCheckboxChange?: (id: string) => void;
  onShowConfirmationModal?: (id: string) => void;
  onHandleRulesetChange?: (event: any, id: any) => void;
  index?: any;
}

export const ItemList = React.memo(function ItemList({
  column,
  preExistingFormElements,
  onHandleCheckboxChange,
  onShowConfirmationModal,
  onHandleRulesetChange,
}: Props) {
  const listRef = useRef();

  return (
    <Droppable
      droppableId={column.id}
      mode="virtual"
      isDropDisabled={column.id === "column-0" ? true : false}
      renderClone={(provided, snapshot, rubric) => (
        <Item
          provided={provided}
          isDragging={snapshot.isDragging}
          item={column.items[rubric.source.index]}
          columnId={column.id}
          style={
            snapshot.isDragging && rubric.source.droppableId === "column-0"
              ? {
                  borderRadius: "8px",
                  border: "solid lightgray",
                  alignItems: "center",
                  justifyContent: "center",
                }
              : ""
          }
        />
      )}
    >
      {(provided, snapshot) => {
        const itemCount = snapshot.isUsingPlaceholder
          ? column.items.length + 1
          : column.items.length;

        return (
          <FixedSizeList
            overscanCount={250}
            height={column.id === "column-0" ? 800 : 850}
            itemCount={itemCount}
            itemSize={column.id === "column-0" ? 75 : 230}
            width={"100%"}
            outerRef={provided.innerRef}
            itemData={
              {
                data: column.items,
                columnId: column.id,
                onShowConfirmationModal,
                preExistingFormElements,
                onHandleCheckboxChange,
                onHandleRulesetChange,
              } as any
            }
            className={styles.inputList}
            innerRef={listRef}
          >
            {Row}
          </FixedSizeList>
        );
      }}
    </Droppable>
  );
});
