import React, { FC } from "react";
import { AppointmentRequestOpen } from "../components/appointmentRequest/appointmentRequestOpen";
import Restricted from "../consumer/restricted";

interface Props {
  subdomain: string;
}

const AppointmentRequest: FC<Props> = ({ subdomain }) => {
  return (
    <Restricted permissionRequired="frontend_view_user_appointment">
      <AppointmentRequestOpen subdomain={subdomain} />
    </Restricted>
  );
};

export default AppointmentRequest;
