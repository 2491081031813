import { useEffect, useState } from "react";
import Select, { MenuPlacement } from "react-select";
import { IDropDownOption } from "../../../types/DropDown";
import { IFolderTreeOptions } from "../../files/folderTree";
interface Props {
  options: IDropDownOption[] | IFolderTreeOptions[] | any;
  defaultValue?: any;
  isMulti?: any;
  onChange?: (
    values:
      | IDropDownOption
      | IFolderTreeOptions
      | IDropDownOption[]
      | IFolderTreeOptions[],
    label?: any
  ) => void;
  labelStyle?: React.CSSProperties;
  labelClassName?: string;
  mandatory?: boolean;
  label?: string;
  onBlur?: any;
  isClearable?: boolean;
  isSearchable?: boolean;
  disabled?: boolean;
  name?: string;
  required?: boolean;
  deleteItem?: boolean;
  newTemplateCreated?: boolean;
  editedItem?: any;
  menuPlacement?: MenuPlacement;
  placeholder?: any;
  resetInput?: any;
  def?: any;
  wrapperStyle?: any;
  wrapperClassName?: string;
  drawerOpen?: boolean;
}
export default function DropDown(props: Props) {
  const [values, setValues] = useState<
    | IDropDownOption[]
    | IFolderTreeOptions[]
    | IDropDownOption
    | IFolderTreeOptions
  >([]);
  const [defaultValuesAreSet, setDefaultValuesAreSet] =
    useState<boolean>(false);

  const findOptionForValue = (value: any) => {
    for (const key in props.options) {
      const option = props.options[key];
      if (Array.isArray(value)) {
        if (JSON.stringify(value) == JSON.stringify(option.value)) {
          return option;
        }
      }
      if (value === option.value) {
        return option;
      }
    }
  };
  if (
    defaultValuesAreSet ||
    props.options.length == 0 ||
    props.defaultValue == undefined
  ) {
    //nothing to see here...
  } else {
    const preSelectedValue: any = [];
    if (
      props.isMulti &&
      props.defaultValue &&
      Array.isArray(props.defaultValue)
    ) {
      for (const key in props.defaultValue) {
        const option = findOptionForValue(props.defaultValue[key]);
        if (option) {
          preSelectedValue.push(option);
        }
      }

      setDefaultValuesAreSet(true);
      setValues(preSelectedValue);
    } else if (props.defaultValue != undefined) {
      const option = findOptionForValue(props.defaultValue);

      if (option) {
        preSelectedValue.push(option);
      }

      setDefaultValuesAreSet(true);
      setValues(preSelectedValue);
    }
  }

  useEffect(() => {
    if (props.onChange) {
      if (!props.isMulti && Array.isArray(values)) {
        props.onChange(values[0], props.name);
      } else {
        props.onChange(values, props.name);
      }
    }
  }, [values]);

  const onChangeHandler = (selectedOption: any, action?: any) => {
    setValues(selectedOption);
    if (action.action === "clear" && props.resetInput) {
      props.resetInput();
    }
  };

  useEffect(() => {
    if (props.editedItem === true) {
      setDefaultValuesAreSet(false);
    }
  }, [props.editedItem, values]);

  useEffect(() => {
    if (props.deleteItem === true) {
      setValues([]);
    }
  }, [props.deleteItem]);

  return (
    <div
      className={props.wrapperClassName}
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        ...props.wrapperStyle,
      }}
    >
      <label
        className={props.labelClassName}
        style={props.labelStyle}
      >
        {props.mandatory === true ? `${props.label + "\u{002A}"}` : props.label}
      </label>
      <Select
        id={props.name}
        options={props.options}
        value={values}
        onChange={(selectedOption, action) =>
          onChangeHandler(selectedOption, action)
        }
        onBlur={props.onBlur}
        name={props.name}
        isMulti={props.isMulti}
        isDisabled={props.disabled}
        isSearchable={props.isSearchable}
        isClearable={props.isClearable}
        required={props.required}
        isLoading={props.options.length !== 0 ? false : true}
        menuPlacement={props.menuPlacement}
        placeholder={props.placeholder}
        menuPortalTarget={document.body}
        styles={{
          clearIndicator: (base) => ({
            ...base,
            color: "#ba78de",
            backgroundColor: "#f4f2f2",
            borderRadius: "0.75rem",
            marginRight: "0.3rem",
            "&:hover": {
              color: "#8c1ec8",
            },
          }),
          dropdownIndicator: (base) => ({
            ...base,
            color: "#ba78de",
            backgroundColor: "#f4f2f2",
            borderRadius: "0.75rem",
            margin: "0 0.3rem",
            "&:hover": {
              color: "#8c1ec8",
            },
          }),
          loadingIndicator: (base) => ({
            ...base,
            color: "#ba78de",
          }),
          control: (baseStyles) => ({
            ...baseStyles,
            borderRadius: "0.5rem",
          }),
          input: (baseStyles) => ({
            ...baseStyles,
            margin: "0",
            padding: "0",
          }),
          valueContainer: (baseStyles) => ({
            ...baseStyles,
            padding: "0.75rem",
            borderRadius: "0.5rem",
          }),
          option: (styles, { isDisabled, isFocused, isSelected }) => {
            return {
              ...styles,
              backgroundColor: isDisabled
                ? undefined
                : isSelected
                ? "#f4f2f2"
                : isFocused
                ? "#a34bd3"
                : undefined,
              color: isDisabled
                ? undefined
                : isSelected
                ? "black"
                : isFocused
                ? "white"
                : undefined,

              cursor: isDisabled ? "not-allowed" : "default",
            };
          },
          menuPortal: (base) => ({
            ...base,
            zIndex: props.drawerOpen ? 99999 : 9999,
          }),
        }}
      />
    </div>
  );
}
