import { PayloadAction, createAction, createSlice } from "@reduxjs/toolkit";
const clearAction = createAction("clear");
const clearAppointmentDashboardSelectedCalendarDate = createAction(
  "clearAppointmentDashboardSelectedCalendarDate"
);

export interface selectedDashboardCalendarDate {
  value: string;
}

const initialState: any = { value: "" };

const appointmentDashboardSelectedCalendarDate = createSlice({
  name: "appointmentDashboardSelectedCalendarDate",
  initialState,
  reducers: {
    setAppointmentDashboardSelectedCalendarDate: (
      state,
      action: PayloadAction<any>
    ) => (state = { value: action.payload }),
  },
  extraReducers: (builder) => {
    builder.addCase(clearAction, () => initialState);
    builder.addCase(
      clearAppointmentDashboardSelectedCalendarDate,
      () => initialState
    );
  },
});

export const { setAppointmentDashboardSelectedCalendarDate } =
  appointmentDashboardSelectedCalendarDate.actions;
export default appointmentDashboardSelectedCalendarDate.reducer;
