import { Grid, IconButton, Tooltip } from "@mui/material";
import { FC } from "react";
import CoreDataForm from "./coreDataForm";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { t } from "i18next";
import styles from "./coreDataFormWrapper.module.css";

interface Props {
  formTitle: string;
  formId: string;
  formValues: any;
  handleCancelForm: () => void;
  coreDataId?: string;
  probeId?: any;
  subjectId?: any;
  onSuccessfulFormSubmitHandler: () => void;
  reload?: () => void;
  navigateBack?: any;
}

const CoreDataFormWrapper: FC<Props> = ({
  formTitle,
  formId,
  formValues,
  handleCancelForm,
  coreDataId,
  probeId,
  subjectId,
  onSuccessfulFormSubmitHandler,
  reload,
  navigateBack,
}) => {
  return (
    <Grid item xs={9}>
      <Tooltip placement="right" title={t("back")}>
        <IconButton onClick={navigateBack} className={styles.backButton}>
          <ArrowBackIcon />
        </IconButton>
      </Tooltip>
      <div>
        <h2 className={styles.formTitle}>{formTitle}</h2>

        <CoreDataForm
          onCancelFormHandler={handleCancelForm}
          onSuccessfulFormSubmitHandler={onSuccessfulFormSubmitHandler}
          formId={formId}
          formValues={formValues}
          coreDataId={coreDataId}
          probeId={probeId}
          subjectId={subjectId}
          reload={reload}
        />
      </div>
    </Grid>
  );
};

export default CoreDataFormWrapper;
