import React from "react";
import InputTooltip, { placement } from "../../general/tooltip";
import styles from './inputWithRef.module.css';

interface Props {
  label?: string;
  tooltip?: boolean;
  placement?: placement;
  tooltipMessage?: any;
  autoComplete?: string;
  mandatory?: boolean;
  name: string | undefined;
  required?: boolean;
  onChange?: (event: any) => void;
  type?: string;
  placeholder?: string;
  readonly?: boolean;
  changeColor?: boolean;
  onFocus?: () => void;
  onBlur?: (event?: any) => void;
  pattern?: any;
  id?: any;
  style?: any;
  disabled?: any;
  className?: string;

  // defaultValue?: string;
  // value?: string;
}

export const InputWithRef = React.forwardRef<HTMLInputElement, Props>(
  function InputWithRef(props, ref) {

    return (
      <div className={`${styles.inputContainer} ${props.className}`} style={props.style}>
        <label
          className={`${styles.label} ${props.disabled ? styles.labelDisabled : ''}`}
          htmlFor={props.name}
        >
          {props.label}
          {props.required === true && (
            <span
              className={`${props.disabled ? styles.labelDisabled : styles.labelRequired}`}
            >
              {"\u{002A}"}
            </span>
          )}

          {props.tooltip && (
            <InputTooltip
              tooltipMessage={props.tooltipMessage}
              placement={props.placement}
            />
          )}
        </label>
        <input
          ref={ref}
          onFocus={props.onFocus}
          onBlur={props.onBlur}
          className={`${styles.inputField} ${props.changeColor ? styles.inputFieldLightGray : ''}`}
          id={props.name}
          // required={props.required}
          onChange={props.onChange}
          name={props.name}
          autoComplete={props.autoComplete}
          // type={props.type}
          disabled={props.disabled}
          placeholder={props.placeholder}
          readOnly={props.readonly}
        />
      </div>
    );
  }
);
