import { Box, Button, IconButton, Tooltip } from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Edit, Delete } from "@mui/icons-material";
import GenericCrudTableModal from "../../components/tables/genericCrudTableModal";
import DropDown from "../../components/forms/inputs/dropDown";
import dayjs from "dayjs";
import "dayjs/locale/de";
import { IDropDownOption } from "../../types/DropDown";
import { DateTimePicker } from "@mui/x-date-pickers";
import { useTranslation } from "react-i18next";
import { DropDownSubjectSearch } from "../../components/forms/inputs/dropDownSubjectSearch";
import { api } from "../../helper/api";
import { MaterialReactTable } from "material-react-table";
import GenericErrorMessageModal from "../../components/forms/errorHandling/genericErrorMessageModal";
import GenericNotification from "../../components/notification/genericNotification";
import Restricted from "../../consumer/restricted";
import AppointmentBuildingInformation from "../../components/appointment/appointmentBuildingInformation";
import styles from "./appointmentEditor.module.css";
import { DotLoader } from "react-spinners";
import { getTranslatedStatusOptions } from "../../helper/statusOptions";

interface IAssignedCategoriesExaminations {
  appointment?: string;
  category?: string;
  category_name?: string;
  examination?: string;
  examination_name?: string;
  findngs?: [];
  id?: string;
  next_version?: number | undefined;
  status?: string;
  trace_id?: string;
  version?: number;
}

interface IMainCatList {
  created_by?: string;
  created_on?: string;
  description?: string;
  id?: string;
  modified_by?: string;
  modified_on?: string;
  name?: string;
  next_version?: number;
  trace_id?: string;
  version?: number;
}

interface IExaminationList extends IMainCatList {
  category_set?: string[];
}

export function AppointmentEditor() {
  const [assignedCategoriesExaminations, setAssignedCategoriesExaminations] =
    useState<IAssignedCategoriesExaminations[] | any>([]);
  const [assignedFindings, setAssignedFindings] = useState<
    IAssignedCategoriesExaminations[] | any
  >([]);
  /*eslint-disable */
  const [mainCategoryList, setMainCategoryList] = useState<IMainCatList[]>([]);
  const [examinationList, setExaminationList] = useState<IExaminationList>({});
  const [appointmentStatus, setAppointmentStatus] = useState<IDropDownOption>();
  const [date, setDate] = useState<any>("");

  const [subject, setSubject] = useState<any>([]);
  const [subjectOptions, setSubjectOptions] = useState<IDropDownOption[]>([]);
  /*eslint-enable */
  const [team, setTeam] = useState<any>("");
  const [teamOptions, setTeamOptions] = useState<IDropDownOption[]>([]);
  const [executedBy, setExecutedBy] = useState<any>("");
  const [executedByOptions, setExecutedByOptions] = useState<any>([]);

  const [modalMode, setModalMode] = useState<string>("insert");
  const [modalPresetValues, setModalPresetValues] = useState<any>([]);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [rowToUpdate, setRowToUpdate] = useState<any>([]);
  const [error, setError] = useState<any>();
  const [showNotification, setShowNotification] = useState<boolean>(false);
  const [notificationMessage, setNotificationMessage] = useState<any>("");
  const [notificationVariant, setNotificationVariant] = useState<
    "success" | "error" | "warning" | "info" | undefined
  >();
  const [buildingInformation, setBuildingInformation] = useState<any>();
  const [currentAppointment, setCurrentAppointment] = useState<any>();

  const [formValues, setFormValues] = useState<any>({
    contact_name: "",
    contact_phone: "",
    contact_email: "",
    parking_situation: "",
    additional_information: "",
  });
  const [buildingOptionsForSubject, setBuildingOptionsForSubject] =
    useState<any>([]);
  const [selectedBuilding, setSelectedBuilding] = useState<any>();
  // eslint-disable-next-line
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [allOptionsLoaded, setAllOptionsLoaded] = useState<any>({
    subjectOptions: true,
    teamOptions: false,
    executedByOptions: false,
    buildingInformation: false,
    buildingInformationOptions: false,
  });

  const { appointmentId } = useParams() as { appointmentId: string };
  const navigate = useNavigate();
  dayjs.locale("de");

  const hostname = window.location.hostname;
  const subdomain = hostname.split(".")[0];

  const { t } = useTranslation();

  const statusOptions = getTranslatedStatusOptions();

  const resetStateOnCloseNotification = () => {
    setShowNotification(false);
    setNotificationMessage("");
  };

  const resetTable = function () {
    api.genericApiRequest({
      entity: "appointment",
      method: "get",

      successHandler: (res: any) => {
        const appointment = res.data;
        setAssignedCategoriesExaminations(appointment.categoryexamination_set);
        setAssignedFindings(appointment.finding_set);
      },
      failHandler: (error: any) => {
        setError(error);
      },
      entityId: String(appointmentId),
      parametersToRender: { depth: "1" },
    });
  };

  useEffect(() => {
    api.genericApiRequest({
      entity: "appointment",
      method: "get",
      successHandler: (res: any) => {
        const appointment = res.data;
        setAssignedCategoriesExaminations(appointment.categoryexamination_set);
        setAssignedFindings(appointment.finding_set);
        setCurrentAppointment(res.data);
        setDate(dayjs(appointment.datetime));
        setAppointmentStatus(appointment.status);
        setSubject({
          value: appointment.subject?.id,
          label: appointment.subject?.name,
        });
        setTeam(appointment.team?.id);
        setExecutedBy(appointment.executed_by?.id);
        setBuildingInformation(appointment.building_information);
      },
      failHandler: (error: any) => {
        setError(error);
      },
      entityId: String(appointmentId),
      parametersToRender: { depth: "1" },
    });
    api.genericApiRequest({
      entity: "team",
      method: "get",

      successHandler: (res: any) => {
        const teams = [];
        for (const key in res.data.results) {
          const item = res.data.results[key];
          const allTeams = { value: item.id, label: item.name };
          teams.push(allTeams);
        }
        setTeamOptions(teams);
        setAllOptionsLoaded((prevOptions: any) => ({
          ...prevOptions,
          teamOptions: true,
        }));
      },
      failHandler: (error: any) => {
        setError(error);
      },
    });
    const additionalUserParameters: any = {};
    additionalUserParameters["impersonate_subject"] =
      "11111111-1111-1111-1111-444444444444";
    additionalUserParameters["is_active"] = "true";

    api.genericApiRequest({
      entity: "user",
      method: "get",
      parametersToRender: {
        additionalUrlParameters: additionalUserParameters,
        depth: "0",
      },

      successHandler: (res: any) => {
        const tempUsers = [];
        for (const key in res.data.results) {
          const item = res.data.results[key];
          const allUsers = {
            value: item.id,
            label: item.last_name + ", " + item.first_name,
          };
          tempUsers.push(allUsers);
        }
        setExecutedByOptions(
          tempUsers.sort((a: any, b: any) => a.label.localeCompare(b.label))
        );
        setAllOptionsLoaded((prevOptions: any) => ({
          ...prevOptions,
          executedByOptions: true,
        }));
      },
      failHandler: (error: any) => {
        setError(error);
      },
    });
  }, []);

  useEffect(() => {
    if (subject.value !== undefined) {
      const additionalUrlParameters: any = {};
      additionalUrlParameters["subject_id"] = subject.value;
      api.genericApiRequest({
        entity: "building",
        method: "get",
        parametersToRender: {
          depth: "0",
          additionalUrlParameters: additionalUrlParameters,
        },
        successHandler: (res: any) => {
          const data = res.data.results;
          const tempBuildingOptions = [];
          for (const key in data) {
            const building = data[key];
            const value = building.id;
            const label =
              building.name +
              ` (${building?.street_house}, ${building?.zip} ${building?.city} )`;
            tempBuildingOptions.push({ value: value, label: label });
          }
          setBuildingOptionsForSubject(tempBuildingOptions);
          setAllOptionsLoaded((prevOptions: any) => ({
            ...prevOptions,
            buildingInformationOptions: true,
          }));
        },
        failHandler: (error: any) => {
          setError(error);
        },
      });
    }
  }, [subject.value]);

  useEffect(() => {
    if (buildingInformation !== undefined) {
      setSelectedBuilding(buildingInformation.building);
      setFormValues({
        contact_name:
          buildingInformation.contact_name !== null
            ? buildingInformation.contact_name
            : formValues.contact_name,
        contact_phone:
          buildingInformation.contact_phone !== null
            ? buildingInformation.contact_phone
            : formValues.contact_phone,
        contact_email:
          buildingInformation.contact_email !== null
            ? buildingInformation.contact_email
            : formValues.contact_email,
        parking_situation:
          buildingInformation.parking_situation !== null
            ? buildingInformation.parking_situation
            : formValues.parking_situation,
        additional_information:
          buildingInformation.additional_information !== null
            ? buildingInformation.additional_information
            : formValues.additional_information,
      });
      setAllOptionsLoaded((prevOptions: any) => ({
        ...prevOptions,
        buildingInformation: true,
      }));
    } else {
      setAllOptionsLoaded((prevOptions: any) => ({
        ...prevOptions,
        buildingInformation: true,
      }));
    }
  }, [buildingInformation]);

  useEffect(() => {
    const areAllTrue = Object.values(allOptionsLoaded).every(
      (value) => value === true
    );
    if (areAllTrue) {
      setIsLoading(false);
    }
  }, [allOptionsLoaded]);

  const navigateToAppointmentlist = () => {
    if (
      /*eslint-disable */
      !confirm(t("confirm_go_back"))
      /*eslint-enable */
    ) {
      return;
    }

    navigate(`/appointmentlist/`);
  };

  const handleTeamChange = (selectedOption: any) => {
    setTeam(selectedOption);
  };

  const handleStatusChange = (selectedOption: any) => {
    setAppointmentStatus(selectedOption);
  };

  const handleSubjectChange = (selectedOption: any) => {
    if (
      selectedOption &&
      typeof selectedOption === "object" &&
      selectedOption.value
    ) {
      setSubject(selectedOption);
    }
  };

  const handleDateChange = (newDate: any) => {
    setDate(newDate);
  };
  const openEditModal = (row: any) => {
    setModalMode("edit");
    setModalPresetValues({
      status: "open",
      category: row.original.category,
      examination: row.original.examination,
    });
    setModalOpen(true);
    setRowToUpdate(row);
  };
  const openCreateModal = () => {
    setModalMode("insert");
    setModalPresetValues({
      status: "open",
    });
    setModalOpen(true);
  };
  const handleEditRow = (row: any) => {
    openEditModal(row);
  };
  const submitBuildingInformation = () => {
    const result = {
      building: selectedBuilding,
      contact_name: formValues.contact_name,
      contact_phone: formValues.contact_phone,
      contact_email: formValues.contact_email,
      parking_situation: formValues.parking_situation,
      additional_information: formValues.additional_information,
    };

    api.genericApiRequest({
      entity: "buildingInformation",
      method: "put",
      entityId: buildingInformation.id,
      // successHandler: () => {
      // },
      failHandler: (error: any) => {
        setError(error);
      },
      submitData: result,
    });
  };

  const handleSubmit = (event: any) => {
    if (
      currentAppointment &&
      currentAppointment.appointmentslot_set.length === 0
    ) {
      submitBuildingInformation();
    }
    event.preventDefault();
    const result = {
      status: appointmentStatus?.value,
      datetime: date.$d.toISOString(),
      subject: subject.value,
      team: team.value,
      buildingInformation: buildingInformation.id,
      executed_by: executedBy,
    };
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    api.genericApiRequest({
      entity: "appointment",
      method: "put",
      successHandler: () => {
        setNotificationMessage(t("successfully_saved"));
        setNotificationVariant("success");
        setShowNotification(true);
        navigate(`/appointmentlist/`);
      },
      failHandler: (error: any) => {
        setError(error);
      },
      entityId: String(appointmentId),
      submitData: result,
      config: config,
    });
  };
  const createNewRow = (values: any) => {
    const result = {
      category: values.category,
      examination_set: values.examination,
      appointment: appointmentId,
    };
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    api.genericApiRequest({
      entity: "categoryExamination",
      method: "post",
      successHandler: () => {
        resetTable();
        setModalOpen(false);
      },
      failHandler: (error: any) => {
        setError(error);
      },
      submitData: result,
      config: config,
    });
  };
  // eslint-disable-next-line
  const deleteRow = useCallback((row: any, skipQuestion = false) => {
    if (!skipQuestion) {
      if (
        /*eslint-disable */
        !confirm(t("confirm_delete_entry"))
      ) {
        return;
      }
    }
    const deleteCategoryExaminationRow = async () => {
      try {
        await api.genericApiRequest(
          /*eslint-enable */
          {
            entity: "categoryExamination",
            method: "delete",
            successHandler: () => {
              resetTable();
            },
            entityId: String(row.original.id),
            failHandler: (error: any) => {
              setError(error);
            },
          }
        );
      } catch (error) {
        console.log(error);
      }
    };

    deleteCategoryExaminationRow();
  }, []);

  const deleteFindingRow = useCallback((row: any, skipQuestion = false) => {
    if (!skipQuestion) {
      if (
        /*eslint-disable */
        !confirm(t("confirm_delete_entry"))
      ) {
        return;
      }
    }
    const deleteFindingRow = async () => {
      try {
        await api.genericApiRequest(
          /*eslint-enable */
          {
            entity: "finding",
            method: "delete",
            successHandler: () => {
              resetTable();
            },
            entityId: String(row.original.id),
            failHandler: (error: any) => {
              setError(error);
            },
          }
        );
      } catch (error) {
        console.log(error);
      }
    };

    deleteFindingRow();
  }, []);

  const saveEditedRow = (values: any) => {
    const result = {
      category: values.category,
      examination: values.examination,
      appointment: appointmentId,
    };

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    api.genericApiRequest({
      entity: "categoryExamination",
      method: "put",
      successHandler: () => {
        resetTable();
        setModalOpen(false);
      },
      failHandler: (error: any) => {
        setError(error);
      },
      entityId: String(rowToUpdate.original.id),
      submitData: result,
      config: config,
    });
  };

  const columns = useMemo(
    () => [
      {
        header: "Category",
        accessorKey: "category_name",
        id: "category",
        enableForCreate: true,
        entity: "mainCategory",
        required: true,
        errorMessage: t("field_required"),
      },
      {
        header: "Examination",
        accessorKey: "examination_name",
        id: "examination",
        enableForCreate: true,
        filterByCategory: true,
        entity: "examination",
        multiselect: true,

        required: true,
        errorMessage: t("field_required"),
      },
    ],
    [assignedCategoriesExaminations, examinationList, mainCategoryList]
  );

  const columnsFindings = useMemo(
    () => [
      {
        header: "id",
        accessorKey: "id",
        id: "id",
      },
      {
        header: "name",
        accessorKey: "probe_name",
        id: "probe_name",
      },
    ],
    [assignedFindings]
  );

  const handleInputChange = (event: any) => {
    const { name, value } = event.target;

    setFormValues((prevState: any) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const handleBuildingChange = (selectedOption: any) => {
    if (
      selectedOption &&
      typeof selectedOption === "object" &&
      selectedOption.value
    ) {
      setSelectedBuilding(selectedOption.value);
    }
  };
  const handleExecutedByChange = (selectedOption: any) => {
    if (
      selectedOption &&
      typeof selectedOption === "object" &&
      selectedOption.value
    ) {
      setExecutedBy(selectedOption.value);
    }
  };

  return (
    <Restricted permissionRequired="frontend_view_appointment">
      {isLoading ? (
        <DotLoader
          color="#8c1ec8"
          size={65}
          cssOverride={{ position: "absolute", top: "45vh", left: "50vw" }}
        />
      ) : (
        <form className={styles.form}>
          <DropDown
            name="appointmentStatus"
            label="Appointment Status"
            defaultValue={appointmentStatus}
            options={statusOptions}
            onChange={handleStatusChange}
            wrapperClassName={styles.dropDown__appointmentStatus}
          />

          <div className={styles.dateTimePickerWrapper}>
            <DateTimePicker
              label={t("date")}
              value={date}
              className={styles.dateTimePicker}
              onChange={(newDate: any) => handleDateChange(newDate)}
            />
          </div>
          <div>
            <DropDownSubjectSearch
              subdomain={subdomain}
              options={subjectOptions}
              onChange={handleSubjectChange}
              wrapperClassName={styles.dropDownSubjectSearch}
              preExistingValue={subject}
              disabled={appointmentStatus?.value !== "open"}
            />
            <DropDown
              defaultValue={team}
              name="team"
              label={t("team")}
              options={teamOptions}
              onChange={handleTeamChange}
              wrapperClassName={styles.dropDown__team}
            />
            <DropDown
              defaultValue={executedBy}
              name="executed_by"
              label={t("executed_by")}
              options={executedByOptions}
              onChange={handleExecutedByChange}
              wrapperClassName={styles.dropDown__executedBy}
            />

            <AppointmentBuildingInformation
              appointment={currentAppointment}
              handleInputChange={handleInputChange}
              formValues={formValues}
              buildingOptions={buildingOptionsForSubject}
              selectedBuilding={selectedBuilding}
              handleBuildingChange={handleBuildingChange}
            />
          </div>
          <div className={styles.tableWrapper}>
            <MaterialReactTable
              enableHiding={true}
              enableFullScreenToggle={false}
              state={assignedCategoriesExaminations}
              columns={columns}
              data={assignedCategoriesExaminations}
              enableEditing
              muiTableBodyRowProps={({ row }: any) =>
                row.index % 2 === 0
                  ? { sx: { backgroundColor: "#f5f5f5" } }
                  : { sx: { backgroundColor: "#FFF" } }
              }
              enableRowActions={true}
              renderRowActions={({ row }) => (
                <Box className={styles.tableRowActions}>
                  <Tooltip arrow placement="left" title="Edit">
                    <IconButton onClick={() => handleEditRow(row)}>
                      <Edit />
                    </IconButton>
                  </Tooltip>

                  <Tooltip arrow placement="right" title="Delete">
                    <IconButton color="error" onClick={() => deleteRow(row)}>
                      <Delete />
                    </IconButton>
                  </Tooltip>
                </Box>
              )}
              renderTopToolbarCustomActions={() => {
                return (
                  <div className={styles.tableTopToolbar}>
                    <Button
                      color="secondary"
                      onClick={() => openCreateModal()}
                      variant="contained"
                    >
                      {t("add")}
                    </Button>
                  </div>
                );
              }}
              onEditingRowSave={saveEditedRow}
            />
          </div>
          <div className={styles.tableWrapper}>
            <MaterialReactTable
              enableHiding={true}
              enableFullScreenToggle={false}
              state={assignedFindings}
              columns={columnsFindings}
              data={assignedFindings}
              muiTableBodyRowProps={({ row }: any) =>
                row.index % 2 === 0
                  ? { sx: { backgroundColor: "#f5f5f5" } }
                  : { sx: { backgroundColor: "#FFF" } }
              }
              enableRowActions={true}
              renderRowActions={({ row }) => (
                <Box className={styles.tableRowActions}>
                  <Tooltip arrow placement="right" title="Delete">
                    <IconButton
                      color="error"
                      onClick={() => deleteFindingRow(row)}
                    >
                      <Delete />
                    </IconButton>
                  </Tooltip>
                </Box>
              )}
              renderTopToolbarCustomActions={() => {
                const subject = "BEATUS";
                return (
                  <div className={styles.tableTopToolbar}>
                    <h3>{t("ongoing_individual_examinations", { subject })}</h3>
                  </div>
                );
              }}
            />
          </div>
          <GenericCrudTableModal
            open={modalOpen}
            columns={columns}
            presetValues={modalPresetValues}
            onClose={() => setModalOpen(false)}
            submitButtonText={(function () {
              if (modalMode === "edit") {
                return t("save");
              }
              return t("add");
            })()}
            onSubmit={(function () {
              if (modalMode === "edit") {
                return saveEditedRow;
              }
              return createNewRow;
            })()}
          />
          <div className={styles.buttonWrapper}>
            <Button
              type="button"
              color="primary"
              variant="contained"
              onClick={() => navigateToAppointmentlist()}
            >
              {t("back")}
            </Button>
            <Button
              color="secondary"
              variant="contained"
              type="button"
              onClick={(event: any) => {
                handleSubmit(event);
              }}
            >
              {t("save")}
            </Button>
          </div>
        </form>
      )}
      {error && error !== "" && (
        <GenericErrorMessageModal
          title={t("error_occurred")}
          error={error}
          onClosehandler={() => {
            setError("");
          }}
        />
      )}
      {showNotification && (
        <GenericNotification
          message={notificationMessage}
          variant={notificationVariant}
          handleCloseSnackbar={resetStateOnCloseNotification}
        />
      )}
    </Restricted>
  );
}

//TODO: edit or create and examination within an appointment leads to an error if the category_set already exists.
//      MUI also states an error regarding "out of range values"
