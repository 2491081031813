import { useState, useEffect } from "react";
import { formValues } from "../appointmentDashboard/appointmentTreeView";
import { useTranslation } from "react-i18next";

import DefaultForm from "./defaultForm";
import { api } from "../../helper/api";
import GenericErrorMessageModal from "./errorHandling/genericErrorMessageModal";
interface Props {
  findingId?: string;
  formId?: string;
  formValues?: formValues;
  onFormTitleChange?: (formTitle: string) => void;
  onSuccessfulFormSubmitHandler?: () => void;
  onCancelFormHandler: () => void;
  preSubmitConfirmMessage?: string;
  readOnly?: boolean;
  withPaper?: boolean;
}
export default function FindingForm(props: Props) {
  const { t } = useTranslation();
  const [formValues, setFormValues]: any = useState<formValues>({});
  const [formTitle, setFormTitle] = useState<string>("");
  const [formId, setFormId] = useState<string | undefined>(props.formId);
  const [formfieldsDefinition, setFormfieldsDefinition] = useState<string>("");
  const [formfieldsVisibility, setFormfieldsVisibility]: any = useState({});
  const [formfieldsVisibilityRulesets, setFormfieldsVisibilityRulesets]: any =
    useState({});
  const [error, setError] = useState<any>();

  const findingId = props.findingId;
  useEffect(() => {
    if (Array.isArray(props.formValues) && props.formValues.length > 0) {
      const formValues: any = {};
      for (const key in props.formValues) {
        const formValue = props.formValues[key];
        formValues[formValue.name] = formValue.value;
      }
      setFormValues(formValues);
    }
    if (findingId !== "") {
      const additionalUrlParameters: any = {};
      additionalUrlParameters["latest_version"] = "true";
      api.genericApiRequest({
        entity: "form",
        method: "get",
        parametersToRender: {
          additionalUrlParameters: additionalUrlParameters,
        },
        successHandler: (res: any) => {
          const forms = res.data;
          // const currentFormId = forms.id;
          // if (String(currentFormId) === String(formId)) {
          setFormTitle(forms.name);
          setFormfieldsDefinition(forms.measurementdefinitiontoform_set);
          setFormId(forms.id);
          // }
        },
        failHandler: (error: any) => {
          setError(error);
        },
        entityId: formId,
      });
    }
  }, []);

  useEffect(() => {
    recalculateFormfieldVisiblity();
  }, [formValues]);

  useEffect(() => {
    if (props.onFormTitleChange) {
      props.onFormTitleChange(formTitle);
    }
  }, [formTitle]);

  useEffect(() => {
    prepareVisibilityRulesets(formfieldsDefinition);
    recalculateFormfieldVisiblity();
  }, [formfieldsDefinition]);

  const prepareVisibilityRulesets = function (fields: any) {
    for (const field in fields) {
      const item = fields[field];
      const fieldname = item.measurement_definition.name;

      if (item.visibility_ruleset) {
        let visibility_ruleset = item.visibility_ruleset;
        if (
          typeof item.visibility_ruleset === "string" ||
          item.visibility_ruleset instanceof String
        ) {
          visibility_ruleset = JSON.parse(item.visibility_ruleset);
        }

        if (
          typeof visibility_ruleset === "object" &&
          Object.entries(visibility_ruleset).length > 0
        ) {
          formfieldsVisibilityRulesets[fieldname] = visibility_ruleset;
        }
      }
    }

    setFormfieldsVisibilityRulesets(formfieldsVisibilityRulesets);
  };

  const recalculateFormfieldVisiblity = function () {
    if (
      formfieldsVisibilityRulesets &&
      Object.keys(formfieldsVisibilityRulesets).length > 0
    ) {
      const newFormfieldsVisibility: any = {};
      for (const key of Object.keys(formfieldsVisibilityRulesets)) {
        const currentFieldRules = formfieldsVisibilityRulesets[key];
        newFormfieldsVisibility[key] =
          parseAndApplyVisibilityRules(currentFieldRules);
      }

      setFormfieldsVisibility(newFormfieldsVisibility);
    }
  };

  const parseAndApplyVisibilityRules = function (visibility_ruleset: any) {
    const visibleResults = [];

    for (const visibility_ruleset_item of Object.entries(visibility_ruleset)) {
      const visibility_ruleset_item_name = visibility_ruleset_item[0];
      const visibility_ruleset_item_value: any = visibility_ruleset_item[1];
      if (
        typeof visibility_ruleset_item_value === "object" &&
        Object.entries(visibility_ruleset_item_value).length > 0
      ) {
        for (const single_rule of Object.entries(
          visibility_ruleset_item_value
        )) {
          visibleResults.push(
            validateVisibilityRule(visibility_ruleset_item_name, single_rule)
          );
        }
      }
    }

    if (visibleResults.includes(false)) {
      return false;
    }

    return true;
  };

  const validateVisibilityRule = function (valueName: string, rule: any) {
    const rule_type = rule[0];
    const rule_value = rule[1];
    let valueToCheck = formValues[valueName];
    if (valueToCheck && valueToCheck.value) {
      valueToCheck = valueToCheck.value;
    }
    switch (rule_type) {
      case "is":
        if (valueToCheck == rule_value) {
          return true;
        }
        break;
      case "not":
        if (valueToCheck != rule_value) {
          return true;
        }
        break;
      case "lt":
        if (valueToCheck < rule_value) {
          return true;
        }
        break;
      case "gt":
        if (valueToCheck > rule_value) {
          return true;
        }
        break;
      case "in":
        if (Array.isArray(rule_value)) {
          if (rule_value.includes(valueToCheck)) {
            return true;
          }
        }
        break;
      case "nin":
        if (Array.isArray(rule_value) && !rule_value.includes(valueToCheck)) {
          return true;
        }
        break;
      case "contains":
        if (Array.isArray(valueToCheck)) {
          for (const key in valueToCheck) {
            if (
              valueToCheck[key].value &&
              valueToCheck[key].value == rule_value
            ) {
              return true;
            }
          }
        }
        break;
    }

    return false;
  };

  const handleFormItemChange = function (event: any) {
    if (event.target.name) {
      setFormValues({ ...formValues, [event.target.name]: event.target.value });
    }
  };

  // select doesnt have an event/id/target - save as label? / name?
  const handleDropDownChange = function (selectedOption: any, label: any) {
    let selectedValues: any = [];
    if (!Array.isArray(selectedOption)) {
      selectedValues = selectedOption?.value;
      if (
        label === undefined &&
        selectedOption.target &&
        selectedOption.target.name
      ) {
        label = selectedOption.target.name;
      }
      if (selectedValues === undefined) {
        if (selectedOption.target) {
          selectedValues = selectedOption.target.value;
        } else {
          selectedValues = selectedOption;
        }
      }
    } else {
      for (const key in selectedOption) {
        const value = selectedOption[key].value;
        selectedValues.push(value);
      }
    }

    if (selectedValues.length > 0 || selectedValues) {
      handleFormItemChange({
        target: { id: label, value: selectedValues, name: label },
      });
    }
  };

  const renderFormFields = function (fields: any) {
    const inputElements: any = [];
    for (const field in fields) {
      const item = fields[field];
      const fieldname = item.measurement_definition.name;
      const label = item.measurement_definition.label;
      const type = item.measurement_definition.type;

      let options: any = [];
      if (type === "choice" || type === "multiselect") {
        if (
          Array.isArray(item.measurement_definition.value_map) &&
          item.measurement_definition.value_map.length !== 0
        ) {
          options = item.measurement_definition.value_map.map((obj: any) => {
            const [label, value] = Object.entries(obj)[0];
            return { value: value, label: label };
          });
        }
      }

      inputElements.push({
        // preDefinedValues: formValues[fieldname],
        type: type,
        name: fieldname,
        label: label,
        options: options,
        visibility: formfieldsVisibility,
        required: item.mandatory,
        errorMessageRequired: "Bitte machen Sie eine Eingabe",
        errorMessageValidation:
          "Unzulässige Zeichen. Bitten passen Sie Ihre Eingabe an.",
        // fieldname: fieldname,
        onChange: handleFormItemChange,
        onDropdownChange: handleDropDownChange,
        readOnly: props.readOnly,
        order: item.order,
      });
    }

    return (
      <DefaultForm
        withPaper={props.withPaper}
        entity={"finding"}
        entityId={formId}
        targetEntity={"measurement/submit_measurements"}
        preDefinedData={formValues}
        input={inputElements}
        visibility={formfieldsVisibility}
        findingId={findingId}
        onSuccessHandler={props.onSuccessfulFormSubmitHandler}
        cancelButton={true}
        preSubmitConfirmMessage={props.preSubmitConfirmMessage}
        onCancelFormHandler={() => {
          const confirmed = confirm(t("confirm_cancel_form_input"));
          if (confirmed) {
            props.onCancelFormHandler();
          }
        }}
      />
    );
  };

  let formFields: any = <></>;

  if (formfieldsDefinition != "") {
    formFields = renderFormFields(formfieldsDefinition);
  }

  return (
    <>
      {formFields}
      {error && error !== "" && (
        <GenericErrorMessageModal
          title={t("error_occurred")}
          error={error}
          onClosehandler={() => {
            setError("");
          }}
        />
      )}
    </>
  );
}
