import { PayloadAction, createAction, createSlice } from "@reduxjs/toolkit";
const clearAction = createAction("clear");
const clearAppointmentDashboardStatusFilter = createAction(
  "clearAppointmentDashboardStatusFilter"
);

export interface statusFilterState {
  value: any;
}

const initialState: any = { value: "" };

const appointmentDashboardStatusFilterSlice = createSlice({
  name: "appointmentDashboardStatusFilter",
  initialState,
  reducers: {
    setAppointmentDashboardStatusFilter: (state, action: PayloadAction<any>) =>
      (state = { value: action.payload }),
  },
  extraReducers: (builder) => {
    builder.addCase(clearAction, () => initialState);
    builder.addCase(clearAppointmentDashboardStatusFilter, () => initialState);
  },
});

export const { setAppointmentDashboardStatusFilter } =
  appointmentDashboardStatusFilterSlice.actions;
export default appointmentDashboardStatusFilterSlice.reducer;
