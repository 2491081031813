import { useEffect, useRef, useState } from "react";
import Administration from "../components/userAdministration/administration";
import { IDropDownOption } from "../types/DropDown";
import { Grid, List, ListItem, ListItemText } from "@mui/material";
import Modal from "../components/modal";
import DropDownSubjectSearch from "../components/forms/inputs/dropDownSubjectSearch";
import { api } from "../helper/api";
import { t } from "i18next";
import { useSelector } from "react-redux";
import { RootState } from "../components/state/store";
import GenericErrorMessageModal from "../components/forms/errorHandling/genericErrorMessageModal";
import GenericNotification from "../components/notification/genericNotification";
import Restricted from "../consumer/restricted";
import styles from "./userAdministration.module.css";

export default function UserAdministration() {
  const [companySubjectId, setCompanySubjectId] = useState<string>("");
  const [companyId, setCompanyId] = useState<string>("");
  const [uploadSuccessful, setUploadSuccessful] = useState<boolean>(false);
  const [entries, setEntries] = useState<any>([]);
  const [entriesModal, setEntriesModal] = useState<boolean>(false);
  const [error, setError] = useState<any>();
  const [showNotification, setShowNotification] = useState<boolean>(false);
  const [notificationMessage, setNotificationMessage] = useState<any>("");
  const [notificationVariant, setNotificationVariant] = useState<
    "success" | "error" | "warning" | "info" | undefined
  >();

  /*eslint-disable */
  const [subjectOptions, setSubjectOptions] = useState<IDropDownOption[]>([]);
  /*eslint-enable */
  const hostname = window.location.hostname;
  const subdomain = hostname.split(".")[0];
  const selectedChildCompany = useSelector((state: RootState) => state.parent);

  const customerRef = useRef(null);

  const resetStateOnCloseNotification = () => {
    setShowNotification(false);
    setNotificationMessage("");
  };

  const handleCompanySelect = (selectedOption: any) => {
    if (
      selectedOption &&
      typeof selectedOption === "object" &&
      selectedOption.value
    ) {
      setCompanySubjectId(selectedOption.value);
      setCompanyId(selectedOption.companyId);
    }
  };

  const handleUploadSuccess = () => {
    setUploadSuccessful(true);
  };

  const handleEntries = (entries: []) => {
    setEntriesModal(true);
    setEntries(entries);
  };

  const handleCloseSkippedEntriesModal = () => {
    setEntriesModal(false);
    setUploadSuccessful(true);
  };

  const entriesModalContent = (
    <div className={styles.entriesModalContainer}>
      <section className={styles.entriesSection}>
        <p className={styles.boldText}>{t("created_employee_entries")}:</p>
        <span>{entries["created entries"]?.length}</span>
      </section>
      <section className={styles.entriesSection}>
        <p className={styles.boldText}>{t("updated_employees_entries")}:</p>
        <span>{entries["updated_entries"]?.length}</span>
      </section>
      <section className={styles.entriesSectionColumn}>
        <p className={styles.incorrectEntriesText}>
          {t("incorrect_entries")}:
        </p>
        <List>
          {entries["skipped entries"]?.map((entry: any) => (
            <ListItem
              key={entry.email}
              className={styles.errorListItem}
            >
              <ListItemText
                primary={entry?.email}
                secondary={entry?.fields?.department?.map(
                  (error: any) => error
                )}
                secondaryTypographyProps={{ color: "red" }}
              />
            </ListItem>
          ))}
        </List>
      </section>
    </div>
  );

  useEffect(() => {
    if (uploadSuccessful) {
      setUploadSuccessful(false);
    }
  }, [uploadSuccessful]);

  const handleSendRegistrationEmail = (
    row: any,
    skipQuestion?: false | undefined
  ) => {
    if (!skipQuestion) {
      if (
        /*eslint-disable */
        !confirm(
          row.original.is_registered
            ? t("send_passwordreset_email_question", {
                email: row.original.email,
              })
            : t("send_registration_email_question", {
                email: row.original.email,
              })
        )
      ) {
        return;
      }
    }

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const result = { impersonate_subject: selectedChildCompany.subjectId };
    const additionalRouteParts: any = {};
    additionalRouteParts["send_user_registration_email"] = "/";

    api.genericApiRequest({
      entity: "user",
      method: "put",
      successHandler: (res: any) => {
        setNotificationMessage(t("successfully_sent"));
        setNotificationVariant("success");
        setShowNotification(true);
      },
      failHandler: (error: any) => {
        setError(error);
      },
      entityId: row.original.id,
      shouldNotImpersonate: true,
      additionalRouteParts: additionalRouteParts,
      submitData: result,
    });
  };
  const handleUndeleteUser = (
    row: any,
    reset: () => void,
    skipQuestion?: false | undefined
  ) => {
    if (!skipQuestion) {
      if (
        /*eslint-disable */
        !confirm(
          t("undelete_user_question", {
            username: row.original.first_name + " " + row.original.last_name,
          })
        )
      ) {
        return;
      }
    }
    const result = { is_active: true, email: row.original.email };
    api.genericApiRequest({
      entity: "user",
      entityId: row.original.id,
      method: "put",
      successHandler: (res: any) => {
        setNotificationMessage(t("user_successfully_undeleted"));
        setNotificationVariant("success");
        setShowNotification(true);
        reset();
      },
      failHandler: (error: any) => {
        setError(error);
      },
      submitData: result,
    });
  };

  function Admin() {
    let content = <></>;
    if (subdomain === "admin" && companySubjectId) {
      content = (
        <Administration
          companySubjectId={companySubjectId}
          companyId={companyId}
          uploadSuccessful={uploadSuccessful}
          handleSendRegistrationEmail={handleSendRegistrationEmail}
          handleUndeleteUser={handleUndeleteUser}
          subdomain={subdomain}
          handleUploadSuccess={handleUploadSuccess}
          handleEntries={handleEntries}
          selectedChildCompany={selectedChildCompany}
          // handleShowAllMailsSentToUser={handleShowAllMailsSentToUser}
        />
      );
    } else if (subdomain === "manage") {
      content = (
        <Administration
          uploadSuccessful={uploadSuccessful}
          selectedChildCompany={selectedChildCompany}
          handleUploadSuccess={handleUploadSuccess}
          handleEntries={handleEntries}
          subdomain={subdomain}
          handleSendRegistrationEmail={handleSendRegistrationEmail}
        />
      );
    }
    return content;
  }

  return (
    <Restricted permissionRequired="frontend_view_user_administration">
      <h1>{t("customer")}</h1>
      <Grid container>
        {subdomain === "admin" && (
          <Grid item xs={12} className={styles.dropDownContainer}>
            <DropDownSubjectSearch
              subdomain={subdomain}
              options={subjectOptions}
              wrapperClassName={styles.dropDownSubjectSearch}
              onChange={handleCompanySelect}
              customLabel={t("company")}
              queryParameter="companies"
              autofocus={true}
              customRef={customerRef}
              useGlobalState={true}
              isClearable={true}
            />
          </Grid>
        )}
      </Grid>

      <Admin />

      <Modal
        open={entriesModal}
        cancelButtonText={t("confirm")}
        hideSubmitButton={true}
        onClose={handleCloseSkippedEntriesModal}
        title={t("overview")}
      >
        {entriesModalContent}
      </Modal>
      {error && error !== "" && (
        <GenericErrorMessageModal
          title={t("error_occurred")}
          error={error}
          onClosehandler={() => {
            setError("");
          }}
        />
      )}
      {showNotification && (
        <GenericNotification
          message={notificationMessage}
          variant={notificationVariant}
          handleCloseSnackbar={resetStateOnCloseNotification}
        />
      )}
    </Restricted>
  );
}
