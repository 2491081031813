import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { merge } from "lodash";
import generalTranslationsDE from "./de/common.json";
import dataProtectionTranslationsDE from "./de/data_protection.json";
import generalTranslationsEN from "./en/common.json";
import dataProtectionTranslationsEN from "./en/data_protection.json";

declare module "i18next" {
  interface CustomTypeOptions {
    returnNull: false;
  }
}

// Create a copy of the dataProtectionTranslations object to avoid modifying the original imported object
const dataProtectionTranslationsDECopy = { ...dataProtectionTranslationsDE };
const dataProtectionTranslationsENCopy = { ...dataProtectionTranslationsEN };

// Join the array of strings into a single string and cast the type
(dataProtectionTranslationsDECopy as any).data_protection_translations = (dataProtectionTranslationsDE.data_protection_translations as string[]).join('');
(dataProtectionTranslationsENCopy as any).data_protection_translations = (dataProtectionTranslationsEN.data_protection_translations as string[]).join('');

// Merge the translation files
const mergedTranslationsDE = merge({}, generalTranslationsDE, dataProtectionTranslationsDECopy);
const mergedTranslationsEN = merge({}, generalTranslationsEN, dataProtectionTranslationsENCopy);

const resources = {
  de: {
    translation: mergedTranslationsDE,
  },
  en: {
    translation: mergedTranslationsEN,
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "de", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    returnNull: false,
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;