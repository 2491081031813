import { PayloadAction, createAction, createSlice } from "@reduxjs/toolkit";
const clearAction = createAction("clear");
const clearAppointmentDashboardCategoryFilter = createAction(
  "clearAppointmentDashboardCategoryFilter"
);

export interface categoryFilterState {
  value: string;
}

const initialState: any = { value: "" };

const appointmentDashboardCategoryFilterSlice = createSlice({
  name: "appointmentDashboardCategoryFilter",
  initialState,
  reducers: {
    setAppointmentDashboardCategoryFilter: (
      state,
      action: PayloadAction<any>
    ) => (state = { value: action.payload }),
  },
  extraReducers: (builder) => {
    builder.addCase(clearAction, () => initialState);
    builder.addCase(
      clearAppointmentDashboardCategoryFilter,
      () => initialState
    );
  },
});

export const { setAppointmentDashboardCategoryFilter } =
  appointmentDashboardCategoryFilterSlice.actions;
export default appointmentDashboardCategoryFilterSlice.reducer;
