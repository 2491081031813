import { useTranslation } from "react-i18next";
import GenericCrudTable from "../../components/tables/genericCrudTable";
import Restricted from "../../consumer/restricted";

export default function AuditLog() {
  const { t } = useTranslation();

  const replaceDate = (id: any) => {
    const date = new Date(id).toLocaleString();
    return date;
  };
  return (
    <Restricted permissionRequired="frontend_view_auditlog">
      <GenericCrudTable
        allowColumnFiltering={true}
        showColumnFilterbyDefault={true}
        heading={t("audit log")}
        entity={"auditlog"}
        disallowDelete={true}
        readOnlyMode={true}
        columns={[
          { header: "ID", accessorKey: "id", id: "id" },
          {
            header: t("object_pk"),
            accessorKey: "object_pk",
            id: "object_pk",
          },
          {
            header: t("object_repr"),
            accessorKey: "object_repr",
            id: "object_repr",
          },
          {
            header: t("changes"),
            accessorKey: "changes",
            customColumnFilterId: "changes",
            customFilterParameter: "_icontains",
            id: "changes",
            Cell: ({ cell }: any) => {
              const source = cell.getValue();
              const items = [];
              for (const key in source) {
                const currentItem = source[key];
                items.push(
                  <p>
                    {key}: {String(currentItem[0])} {" ==> "}{" "}
                    {String(currentItem[1])}
                  </p>
                );
              }
              return <span>{items}</span>;
            },
          },
          {
            header: t("created_on"),
            accessorKey: "timestamp",
            id: "timestamp",
            filterVariant: "date",

            sortingFn: "datetime",
            Cell: ({ cell }: any) => {
              return <span>{replaceDate(cell.getValue())}</span>;
            },
          },
          {
            header: t("modified_by"),
            accessorKey: "actor",
            id: "actor",
            Cell: ({ cell }: any) => {
              return <span>{cell.getValue().email}</span>;
            },
          },
        ]}
      />
    </Restricted>
  );
}
