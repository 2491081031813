import { PayloadAction, createAction, createSlice } from "@reduxjs/toolkit";
const clearAction = createAction("clear");

export interface ParentState {
  subjectId: string | number;
  companyName: string;
  companyId: string | number;
}

const initialState: ParentState = {
  subjectId: "",
  companyName: "",
  companyId: "",
};

const parentSlice = createSlice({
  name: "parent",
  initialState,
  reducers: {
    setParent: (
      state,
      action: PayloadAction<{
        companyId: string | number;
        companyName: string;
        subjectId: string | number;
      }>
    ) => {
      state.companyId = action.payload.companyId;
      state.companyName = action.payload.companyName;
      state.subjectId = action.payload.subjectId;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(clearAction, () => initialState);
  },
});

export const { setParent } = parentSlice.actions;
export default parentSlice.reducer;
