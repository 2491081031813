import { Grid, IconButton } from "@mui/material";
import { useEffect, useState } from "react";
import DefaultInput from "../../components/forms/inputs/defaultInput";
import DropDown from "../../components/forms/inputs/dropDown";
import Modal from "../../components/modal";
import CreateNewFolderIcon from "@mui/icons-material/CreateNewFolder";
import Button from "../../components/forms/inputs/button";
import AddIcon from "@mui/icons-material/Add";
import FolderTree from "../../components/files/folderTree";
import { IDropDownOption } from "../../types/DropDown";
import { IFolderProps } from "../../types/FolderItem";
import { api } from "../../helper/api";
import { t } from "i18next";
import GenericErrorMessageModal from "../../components/forms/errorHandling/genericErrorMessageModal";
import Restricted from "../../consumer/restricted";
import styles from "./folderEditor.module.css";

interface IOptions extends IDropDownOption {
  treeId?: string;
}

export default function FolderEditor() {
  const [folderId, setFolderId] = useState<string>("");
  const [treeId, setTreeId] = useState<string>("");
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [modalPresetValues, setModalPresetValues] = useState<any>([]);
  const [modalMode, setModalMode] = useState<string>("editFolder");
  const [modalValue, setModalValue] = useState<string>("");
  const [options, setOptions] = useState<IOptions[]>([]);
  const [selected, setSelected] = useState<IOptions | any>();
  const [isTreeSelected, setIsTreeSelected] = useState<boolean>(false);
  const [count, setCount] = useState<number>(0);
  const [moveFolderSource, setMoveFolderSource] = useState<IFolderProps | any>(
    []
  );
  const [error, setError] = useState<any>();

  const hostname = window.location.hostname;
  const subdomain = hostname.split(".")[0];

  useEffect(() => {
    if (options.length === 0) {
      const additionalRouteParts: any = {};
      additionalRouteParts["trees"] = "/";
      api.genericApiRequest({
        entity: "folder",
        method: "get",

        successHandler: (res: any) => {
          const trees = [];
          const data = res.data.results;
          for (const key in data) {
            const label = data[key].name;
            const value = data[key].id;
            trees.push({ value: value, label: label, treeId: value });
            trees.sort((a: any, b: any) => a.label.localeCompare(b.label));
          }
          setOptions(trees);
        },
        failHandler: (error: any) => {
          setError(error);
        },
        additionalRouteParts: additionalRouteParts,
      });
    }
  }, [options]);

  useEffect(() => {
    setFolderId("");
  }, [treeId]);

  const addRootFolder = () => {
    const prepared_values = {
      name: modalValue,
      metadata: {},
      tree: modalPresetValues.tree,
      parent: null,
    };
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    api.genericApiRequest({
      entity: "folder",
      method: "post",
      successHandler: () => {
        setCount(count + 1);
        setModalOpen(false);
      },
      failHandler: (error: any) => {
        setError(error);
      },
      submitData: prepared_values,
      config: config,
    });
  };

  const addFolder = () => {
    const prepared_values = {
      name: modalValue,
      parent: String(modalPresetValues.id),
      tree: modalPresetValues.tree,
      metadata: {},
    };
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    api.genericApiRequest({
      entity: "folder",
      method: "post",
      successHandler: () => {
        setCount(count + 1);
      },
      failHandler: (error: any) => {
        setError(error);
      },
      submitData: prepared_values,
      config: config,
    });
    setModalOpen(false);
  };

  const deleteFolder = (item: any, skipQuestion = false) => {
    if (!skipQuestion) {
      if (
        /*eslint-disable */
        !confirm(t("confirm_delete_folder", { itemName: item.name }))
      ) {
        return;
      }
    }

    const fetchData = async () => {
      try {
        const res = await api.genericApiRequest({
          entity: "folder",
          method: "delete",
          successHandler: () => {
            /*eslint-enable */
            setCount(count + 1);
          },
          failHandler: (error: any) => {
            setError(error);
          },
          entityId: String(item.id),
        });
      } catch (error: any) {
        console.log(error.message);
      }
    };
    fetchData();
  };

  const editFolder = () => {
    const result = {
      name: modalValue,
      metadata: modalPresetValues.metadata,
      tree: modalPresetValues.tree.id,
      parent: modalPresetValues.parent,
    };
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    api.genericApiRequest({
      entity: "folder",
      method: "put",
      successHandler: () => {
        setCount(count + 1);
      },
      failHandler: (error: any) => {
        setError(error);
      },
      entityId: String(modalPresetValues.id),
      submitData: result,
      config: config,
    });

    setModalOpen(false);
  };
  const openEditModal = (item: any) => {
    setModalMode("editFolder");
    setModalValue(item.name);
    setModalPresetValues({
      name: item.name,
      id: item.id,
      metadata: item.metadata,
      parent: item.parent,
      tree: item.tree,
    });
    setModalOpen(true);
  };

  const openCreateSubFolderModal = (item: any) => {
    setModalMode("createSubfolder");
    setModalPresetValues({
      name: item.name,
      id: item.id,
      tree: item.tree.id,
    });
    setModalOpen(true);
  };
  const openCreateRootFolderModal = (item: any) => {
    setModalMode("createRootfolder");
    setModalPresetValues({
      tree: item,
    });
    setModalOpen(true);
  };

  const openNewTreeModal = () => {
    setModalMode("createTree");
    setModalOpen(true);
  };
  const openEditTreeModal = () => {
    setModalMode("editTree");
    setModalValue(selected?.label);
    setModalOpen(true);
  };

  const addTree = () => {
    const prepared_values = { name: modalValue };
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    api.genericApiRequest({
      entity: "tree",
      method: "post",
      successHandler: () => {
        setCount(count + 1);
        setOptions([]);
        setModalOpen(false);
      },
      failHandler: (error: any) => {
        setError(error);
      },
      submitData: prepared_values,
      config: config,
    });
  };

  const editTree = () => {
    const result = { name: modalValue };
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    api.genericApiRequest({
      entity: "tree",
      method: "put",
      successHandler: () => {
        setCount(count + 1);
        setOptions([]);
        setModalOpen(false);
      },
      failHandler: (error: any) => {
        setError(error);
      },
      entityId: String(selected?.value),
      submitData: result,
      config: config,
    });
  };

  const handleModalChange = (event: any) => {
    event.preventDefault();
    setModalValue(event.target.value);
  };

  const handleTreeSelectChange = (selectedOption: any) => {
    if (selectedOption) {
      setSelected(selectedOption);
      setTreeId(selectedOption.treeId);
      setIsTreeSelected(true);
    }
  };

  const handleMoveFolderSource = (item: any) => {
    setMoveFolderSource(item);
  };

  const handleMoveFolder = (item: any) => {
    const sourceResult = {
      name: moveFolderSource?.name,
      metadata: moveFolderSource?.metadata,
      tree: moveFolderSource?.tree.id,
      parent: item.id,
    };

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    api.genericApiRequest({
      entity: "folder",
      method: "put",
      successHandler: () => {
        setCount(count + 1);
        setMoveFolderSource([]);
      },
      failHandler: (error: any) => {
        setError(error);
      },
      entityId: String(moveFolderSource?.id),
      submitData: sourceResult,
      config: config,
    });
  };

  const cancelFolderMove = () => {
    setMoveFolderSource([]);
  };

  const handleMoveFolderRoot = () => {
    const sourceResult = {
      name: moveFolderSource?.name,
      metadata: moveFolderSource?.metadata,
      tree: moveFolderSource?.tree,
      parent: null,
    };
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    api.genericApiRequest({
      entity: "folder",
      method: "put",
      successHandler: () => {
        setCount(count + 1);
        setMoveFolderSource([]);
      },
      failHandler: (error: any) => {
        setError(error);
      },
      entityId: String(moveFolderSource?.id),
      submitData: sourceResult,
      config: config,
    });
  };

  return (
    <Restricted permissionRequired="frontend_view_folder">
      <div>
        <div className={styles.mainContainer}>
          <div className={styles.innerContainer}>
            <h1>{t("folder_editor")}</h1>
            <DropDown
              label="Tree"
              onChange={handleTreeSelectChange}
              options={options}
            />
            <div className={styles.buttonContainer}>
              <Button
                type="button"
                onClick={openNewTreeModal}
                title="Neuer Tree"
              />
              <Button
                disabled={selected?.length === 0}
                type="button"
                onClick={openEditTreeModal}
                title="Tree bearbeiten"
              />
            </div>
          </div>
          {treeId && (
            <Grid container>
              <Grid
                item
                component={"h5"}
                className={styles.sectionHeader}
                xs={12}
              >
                Ordner
              </Grid>
              <Grid
                container
                item
                component={"ul"}
                className={styles.listContainer}
              >
                <Grid
                  xs={12}
                  item
                  component={"li"}
                  className={styles.listItem}
                >
                  /
                  <IconButton
                    className={styles.createButton}
                    type="button"
                    disabled={!isTreeSelected}
                    onClick={() => openCreateRootFolderModal(selected?.value)}
                  >
                    <CreateNewFolderIcon />
                  </IconButton>
                  {moveFolderSource?.length !== 0 && (
                    <IconButton
                      className={styles.moveButton}
                      onClick={handleMoveFolderRoot}
                    >
                      <AddIcon fontSize="small" />
                    </IconButton>
                  )}
                </Grid>
              </Grid>
              <Grid item xs={10}>
                <FolderTree
                  treeId={treeId}
                  folderId={folderId}
                  source="folder"
                  adminMode={true}
                  handleTreeSelectChange={handleTreeSelectChange}
                  isTreeSelected={isTreeSelected}
                  selected={selected}
                  count={count}
                  options={options}
                  subdomain={subdomain}
                  openCreateSubFolderModal={openCreateSubFolderModal}
                  openCreateRootFolderModal={openCreateRootFolderModal}
                  openNewTreeModal={openNewTreeModal}
                  deleteFolder={deleteFolder}
                  openEditModal={openEditModal}
                  onClickHandler={(folderId: any) => setFolderId(folderId)}
                  moveFolderSource={moveFolderSource}
                  handleMoveFolderSource={handleMoveFolderSource}
                  handleMoveFolderTarget={handleMoveFolder}
                  handleMoveFolderRoot={handleMoveFolderRoot}
                  cancelFolderMove={cancelFolderMove}
                />
              </Grid>
            </Grid>
          )}
        </div>
        <Modal
          open={modalOpen}
          presetValues={modalPresetValues}
          onClose={() => setModalOpen(false)}
          title={(function () {
            if (modalMode === "editFolder") {
              return t("edit_folder");
            }
            if (modalMode === "createTree") {
              return t("add_tree");
            }
            if (modalMode === "editTree") {
              return t("edit_tree");
            } else return t("add_folder");
          })()}
          submitButtonText={(function () {
            if (modalMode === "editFolder" || modalMode === "editTree") {
              return t("save");
            }
            return t("add");
          })()}
          onSubmit={(function () {
            if (modalMode === "editFolder") {
              return editFolder;
            }
            if (modalMode === "createSubfolder") {
              return addFolder;
            }
            if (modalMode === "createRootfolder") {
              return addRootFolder;
            }
            if (modalMode === "createTree") {
              return addTree;
            }
            if (modalMode === "editTree") {
              return editTree;
            }
          })()}
        >
          <DefaultInput value={modalValue} onChange={handleModalChange} />
        </Modal>
        {error && error !== "" && (
          <GenericErrorMessageModal
            title={t("error_occurred")}
            error={error}
            onClosehandler={() => {
              setError("");
            }}
          />
        )}
      </div>
    </Restricted>
  );
}
