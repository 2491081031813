import { useEffect, useState } from "react";
import DropDown from "../forms/inputs/dropDown";
import Button from "../forms/inputs/button";
import { Link, useNavigate, useParams } from "react-router-dom";
import { IDropDownIds, IDropDownOption } from "../../types/DropDown";
import { useTranslation } from "react-i18next";
import DefaultInput from "../forms/inputs/defaultInput";
import {
  Breadcrumbs,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Button as MuiButton,
} from "@mui/material";
import { api } from "../../helper/api";
import DropDownSubjectSearch from "../forms/inputs/dropDownSubjectSearch";
import { DotLoader } from "react-spinners";
import GenericErrorMessageModal from "../forms/errorHandling/genericErrorMessageModal";
import PeopleIcon from "@mui/icons-material/People";
import CircleIcon from "@mui/icons-material/Circle";
import styles from "./createNewAppointmentRequest.module.css";

export function CreateNewAppointmentRequest() {
  const [title, setTitle] = useState<string>("");
  const [customTitel, setCustomTitle] = useState<string>("");
  /*eslint-disable */
  const [companyOptions, setCompanyOptions] = useState<IDropDownOption[]>([]);
  /*eslint-enable */
  const [selectedCompany, setSelectedCompany] = useState<IDropDownOption>();
  const [selectedCompanySubjectId, setSelectedCompanySubjectId] =
    useState<IDropDownOption>();

  const [departmentOptions, setDepartmentOptions] = useState<IDropDownOption[]>(
    []
  );
  const [selectedDepartmentIds, setSelectedDepartmentIds] = useState<
    IDropDownIds[]
  >([]);
  const [selectedSubjects, setSelectedSubjects] = useState<any>([]);
  const [inviteSelectedCompany, setInviteSelectedCompany] =
    useState<boolean>(false);
  const [preExistingInvitedSubjectsValue, setPreExistingInvitedSubjectsValue] =
    useState<any>([]);
  const [categories, setCategories] = useState<any>({});
  const [categoryOptions, setCategoryOptions] = useState<IDropDownOption[]>([]);
  const [selectedMainCategoriesId, setSelectedMainCategoriesId] =
    useState<string>("");
  const [examinationsForCategory, setExaminationsForCategory] = useState<any>(
    []
  );
  const [selectedExaminationIds, setSelectedExaminationIds] =
    useState<IDropDownIds[]>();
  const [examinationOptions, setExaminationOptions] = useState<
    IDropDownOption[]
  >([]);
  const [selectedParallelSlots, setSelectedParallelSlots] = useState<number>(1);
  const [selectedSlotDuration, setSelectedSlotDuration] = useState<number>();
  // eslint-disable-next-line
  const [daySet, setDaySet] = useState<any>();
  const [isLoading, setIsloading] = useState<boolean>(true);
  const [preExistingCompanyValue, setPreExistingCompanyValue] = useState<any>(
    []
  );
  const [appointmentRequestStatus, setAppointmentRequestStatus] =
    useState<string>("");
  const [error, setError] = useState<any>();
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [invitedSubjects, setInvitedSubjects] = useState<any>([]);
  const [executedBy, setExecutedBy] = useState<any>("");
  const [executedByOptions, setExecutedByOptions] = useState<any>([]);

  const navigate = useNavigate();
  const { appointmentrequestId } = useParams() as {
    appointmentrequestId: string;
  };

  const { t } = useTranslation();
  const hostname = window.location.hostname;
  const subdomain = hostname.split(".")[0];

  const parallelSlotsOptions: IDropDownOption[] = [];
  for (let value = 1; value <= 5; value += 1) {
    parallelSlotsOptions.push({ value, label: String(value) });
  }

  const slotDurationOptions: IDropDownOption[] = [];
  for (let value = 5; value <= 60; value += 5) {
    slotDurationOptions.push({ value, label: String(value) });
  }
  const specificDurations = [
    { value: 75, label: "75" },
    { value: 90, label: "90" },
    { value: 105, label: "105" },
    { value: 120, label: "120 (2h)" },
    { value: 150, label: "150" },
    { value: 180, label: "180 (3h)" },
    { value: 240, label: "240 (4h)" },
    { value: 320, label: "320 (5h)" },
    { value: 400, label: "400 (6h)" },
  ];
  slotDurationOptions.push(...specificDurations);

  useEffect(() => {
    const additionalUserParameters: any = {};
    additionalUserParameters["impersonate_subject"] =
      "11111111-1111-1111-1111-444444444444";
    additionalUserParameters["is_active"] = "true";

    api.genericApiRequest({
      entity: "user",
      method: "get",
      parametersToRender: {
        additionalUrlParameters: additionalUserParameters,
        depth: "0",
      },

      successHandler: (res: any) => {
        const tempUsers = [];
        for (const key in res.data.results) {
          const item = res.data.results[key];
          const allUsers = {
            value: item.id,
            label: item.last_name + ", " + item.first_name,
          };
          tempUsers.push(allUsers);
        }
        setExecutedByOptions(
          tempUsers.sort((a: any, b: any) => a.label.localeCompare(b.label))
        );
      },
      failHandler: (error: any) => {
        setError(error);
      },
    });
  }, []);

  useEffect(() => {
    if (appointmentrequestId) {
      api.genericApiRequest({
        entity: "appointmentRequest",
        method: "get",
        entityId: appointmentrequestId,
        successHandler: (res: any) => {
          if (res.status === 200) {
            const appointmentRequest = res.data;
            const departmentIds = [];
            const examinationIds = [];
            const subjectSet = [];
            for (const key in appointmentRequest.department_set) {
              const id = appointmentRequest.department_set[key].id;
              departmentIds.push(id);
            }
            for (const key in appointmentRequest.examination_set) {
              const id = appointmentRequest.examination_set[key].id;
              examinationIds.push(id);
            }
            for (const key in appointmentRequest.subject_set) {
              const item = appointmentRequest.subject_set[key];
              subjectSet.push({ value: item.id, label: item.name });
            }
            setAppointmentRequestStatus(appointmentRequest.status);
            setPreExistingCompanyValue({
              value: appointmentRequest.company.id,
              label: appointmentRequest.company.name,
            });
            const tempInvited = [];
            for (const key in res.data.subject_info_set) {
              const subject = res.data.subject_info_set[key];
              tempInvited.push(subject);
              tempInvited.sort((a: any, b: any) =>
                a.name.localeCompare(b.name)
              );
            }
            setInvitedSubjects(tempInvited);
            setPreExistingInvitedSubjectsValue(subjectSet);
            setSelectedCompany(appointmentRequest.company.id);
            setSelectedDepartmentIds(departmentIds);
            setSelectedMainCategoriesId(appointmentRequest.category.id);
            setSelectedExaminationIds(examinationIds);
            setCustomTitle(appointmentRequest.title);
            setSelectedSlotDuration(appointmentRequest.slot_duration);
            setSelectedParallelSlots(appointmentRequest.parallel_slots);
            setDaySet(appointmentRequest.day_set);
            setExecutedBy(appointmentRequest.executed_by?.id);

            if (
              departmentIds.length === 0 &&
              appointmentRequest.subject_info_set.length === 1 &&
              appointmentRequest.subject_info_set[0].company_id ===
                appointmentRequest.company.id
            ) {
              setInviteSelectedCompany(true);
            }
          }
        },
        failHandler: (error: any) => {
          setError(error);
        },
      });
    }
  }, [appointmentrequestId]);

  useEffect(() => {
    if (selectedCompany) {
      const additionalUrlParameters: any = {};
      additionalUrlParameters["company_id"] = selectedCompany;
      api.genericApiRequest({
        entity: "department",
        method: "get",

        successHandler: (res: any) => {
          const departments: any = [];
          for (const key in res.data.results) {
            const departmentLabel = res.data.results[key].name;
            const departmentId = res.data.results[key].id;
            departments.push({
              value: departmentId,
              label: departmentLabel,
            });
            departments.sort((a: any, b: any) =>
              a.label.localeCompare(b.label)
            );
          }
          setDepartmentOptions(departments);
        },
        failHandler: (error: any) => {
          setError(error);
        },
        parametersToRender: {
          depth: "0",
          additionalUrlParameters: additionalUrlParameters,
        },
      });
    }
  }, [selectedCompany]);

  useEffect(() => {
    api.genericApiRequest({
      entity: "examination",
      method: "get",

      successHandler: (res: any) => {
        const examinationsForCategory: any = {};
        const categories: any = {};
        for (const key in res.data.results) {
          const examination = res.data.results[key];
          const set = examination.category_set;
          //SET GOT CATEGORY - IDS listed in []
          delete examination.category_set;
          for (const categoryKey in set) {
            const category = set[categoryKey];
            categories[category.id] = category;

            if (!Object.hasOwn(examinationsForCategory, category.id))
              examinationsForCategory[category.id] = [];
            examinationsForCategory[category.id].push(examination);
          }
        }
        setCategories(categories);
        setExaminationsForCategory(examinationsForCategory);
      },
      failHandler: (error: any) => {
        setError(error);
      },
    });
  }, []);

  useEffect(() => {
    const tempExaminations: any = [];
    const mainCategoryId = selectedMainCategoriesId;
    for (const examinationKey in examinationsForCategory[mainCategoryId]) {
      const examination =
        examinationsForCategory[mainCategoryId][examinationKey];
      tempExaminations.push({
        value: examination.id,
        label: examination.name,
      });
    }
    tempExaminations.sort((a: any, b: any) => a.label.localeCompare(b.label));
    setExaminationOptions(tempExaminations);
  }, [selectedMainCategoriesId, examinationsForCategory]);

  useEffect(() => {
    for (const i in Object.keys(categories)) {
      const key = Object.keys(categories)[i];
      const category = categories[key];
      categoryOptions.push({
        value: category.id,
        label: category.name,
      });
    }
    categoryOptions.sort((a: any, b: any) => a.label.localeCompare(b.label));
    setCategoryOptions(categoryOptions);
  }, [categories]);

  // --------------------DropDown OnChangeHandler--------------------
  const saveDropDownOnChangeData = (saveMethod: any, data: any) => {
    if (data) {
      if (typeof data === "object" && data.value) {
        return saveMethod(data.value);
      }

      if (Array.isArray(data) && data.length > 0) {
        const tempList = data.map((item) => item.value);
        return saveMethod(tempList);
      } else saveMethod([]);
    }
  };
  const handleDepartmentChange = (selectedOption: any) => {
    saveDropDownOnChangeData(setSelectedDepartmentIds, selectedOption);
  };
  const handleSubjectSetChange = (selectedOption: any) => {
    saveDropDownOnChangeData(setSelectedSubjects, selectedOption);
  };

  const handleCategoryChange = (selectedOption: any) => {
    saveDropDownOnChangeData(setSelectedMainCategoriesId, selectedOption);
  };
  const handleCompanyChange = (selectedOption: any) => {
    if (
      selectedOption &&
      typeof selectedOption === "object" &&
      selectedOption.companyId
    ) {
      setSelectedCompany(selectedOption?.companyId);
      setSelectedCompanySubjectId(selectedOption?.value);
    }
  };
  const handleExaminationChange = (selectedOption: any) => {
    if (selectedOption && selectedOption.length > 0) {
      const examinationIds: any = [];
      const title = selectedOption[0].label;
      for (const key in selectedOption) {
        const id = selectedOption[key].value;
        examinationIds.push(id);
      }
      setSelectedExaminationIds(examinationIds);
      if (!appointmentrequestId) {
        setTitle(title);
        setCustomTitle(title);
      }
    }
  };
  const handleParallelSlotsChange = (selectedOption: any) => {
    saveDropDownOnChangeData(setSelectedParallelSlots, selectedOption);
  };
  const handleSlotDurationChange = (selectedOption: any) => {
    saveDropDownOnChangeData(setSelectedSlotDuration, selectedOption);
  };

  const handleTitleChange = (event: any) => {
    event.preventDefault();
    setCustomTitle(event.target.value);
  };

  const handleInviteCompanyChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setInviteSelectedCompany(event.target.checked);
  };
  const handleExecutedByChange = (selectedOption: any) => {
    if (
      selectedOption &&
      typeof selectedOption === "object" &&
      selectedOption.value
    ) {
      setExecutedBy(selectedOption.value);
    }
  };

  // --------------------Open AppointmentDateTime--------------------

  const createAppointmentDraft = () => {
    let result = {};
    if (appointmentrequestId) {
      if (appointmentRequestStatus === "draft") {
        result = {
          title: customTitel ? customTitel : title,
          category: selectedMainCategoriesId,
          department_set: selectedDepartmentIds,
          examination_set: selectedExaminationIds,
          slot_duration: selectedSlotDuration,
          parallel_slots: selectedParallelSlots,
          company: selectedCompany,
          subject_set: selectedSubjects,
          executed_by: executedBy,
        };
      } else
        result = {
          title: customTitel ? customTitel : title,
        };
    } else {
      result = {
        title: customTitel ? customTitel : title,
        category: selectedMainCategoriesId,
        department_set: selectedDepartmentIds,
        examination_set: selectedExaminationIds,
        slot_duration: selectedSlotDuration,
        parallel_slots: selectedParallelSlots,
        company: selectedCompany,
        subject_set: inviteSelectedCompany
          ? [selectedCompanySubjectId]
          : selectedSubjects,
        executed_by: executedBy,
      };
    }
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    if (appointmentrequestId) {
      api.genericApiRequest({
        entity: "appointmentRequest",
        method: "put",
        successHandler: () => {
          navigate(`/editappointmentrequest/${appointmentrequestId}/building`);
        },
        failHandler: (error: any) => {
          setError(error);
        },
        entityId: appointmentrequestId,
        submitData: result,
        config: config,
      });
    } else {
      api.genericApiRequest({
        entity: "appointmentRequest",
        method: "post",
        successHandler: (res: any) => {
          navigate(`/editappointmentrequest/${res.data.id}/building`);
        },
        failHandler: (error: any) => {
          setError(error);
        },
        submitData: result,
        config: config,
      });
    }
  };

  useEffect(() => {
    if (appointmentrequestId) {
      if (
        departmentOptions.length !== 0 &&
        categoryOptions.length !== 0 &&
        examinationOptions.length !== 0 &&
        slotDurationOptions.length !== 0 &&
        parallelSlotsOptions.length !== 0
      ) {
        setIsloading(false);
      }
    } else setIsloading(false);
  }, [
    departmentOptions,
    categoryOptions,
    examinationOptions,
    slotDurationOptions,
    parallelSlotsOptions,
    appointmentrequestId,
  ]);

  const disableButtonCheck =
    selectedMainCategoriesId === undefined ||
    selectedMainCategoriesId.length === 0 ||
    selectedExaminationIds === undefined
      ? true
      : false;

  const heading = appointmentrequestId
    ? t("appointment_creation") + " - " + t("edit")
    : t("appointment_creation");

  const buttonTitle = (
    <div className={styles.buttonTitle}>{<PeopleIcon />}</div>
  );

  return (
    <>
      {isLoading ? (
        <DotLoader
          color="#8c1ec8"
          size={65}
          cssOverride={{ position: "absolute", top: "45vh", left: "50vw" }}
        />
      ) : (
        <Grid container className={styles.container} spacing={2}>
          <Grid item xs={12}>
            {appointmentrequestId && appointmentRequestStatus !== "draft" && (
              <div role="presentation">
                <Breadcrumbs separator="|">
                  <Link
                    className={styles.linkUnderlined}
                    to={`/editappointmentrequest/${appointmentrequestId}/settings`}
                  >
                    {t("basic_information")}
                  </Link>
                  <Link
                    className={styles.linkNoUnderline}
                    to={`/editappointmentrequest/${appointmentrequestId}/building`}
                  >
                    {t("building_location")}
                  </Link>
                  <Link
                    className={styles.linkNoUnderline}
                    to={`/editappointmentrequest/${appointmentrequestId}/calendar`}
                  >
                    {t("period")}
                  </Link>
                </Breadcrumbs>
              </div>
            )}
            <h1 className={styles.heading}>
              {heading}
              <Button
                title={buttonTitle}
                variant="contained"
                color="secondary"
                tooltipTitle={t("show_invited")}
                withTooltip={true}
                onClick={() => setModalOpen(true)}
              />
            </h1>
          </Grid>
          {!isLoading && (
            <>
              <Grid item xs={12}>
                <DropDownSubjectSearch
                  subdomain={subdomain}
                  options={companyOptions}
                  onChange={handleCompanyChange}
                  queryParameter="companies"
                  customLabel={t("company")}
                  disabled={appointmentrequestId ? true : false}
                  preExistingValue={preExistingCompanyValue}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={inviteSelectedCompany}
                      onChange={handleInviteCompanyChange}
                      disabled={
                        !selectedCompany ||
                        selectedDepartmentIds.length !== 0 ||
                        (appointmentrequestId &&
                        appointmentRequestStatus !== "draft"
                          ? true
                          : false)
                      }
                    />
                  }
                  label={t("appointmentrequest_for_company")}
                />
              </Grid>
              <Grid item xs={12}>
                <DropDown
                  label={t("department")}
                  name={t("department")}
                  defaultValue={selectedDepartmentIds}
                  options={departmentOptions}
                  onChange={handleDepartmentChange}
                  disabled={
                    !selectedCompany ||
                    inviteSelectedCompany ||
                    (appointmentrequestId &&
                    appointmentRequestStatus !== "draft"
                      ? true
                      : false)
                  }
                  isMulti={true}
                />
              </Grid>
              <Grid item xs={12}>
                <DropDownSubjectSearch
                  subdomain={subdomain}
                  options={[]}
                  onChange={handleSubjectSetChange}
                  queryParameter="users"
                  companyId={selectedCompany}
                  customLabel={t("add_single_customer")}
                  isMulti={true}
                  isClearable={true}
                  disabled={
                    !selectedCompany ||
                    inviteSelectedCompany ||
                    (appointmentrequestId &&
                    appointmentRequestStatus !== "draft"
                      ? true
                      : false)
                  }
                  preExistingValue={preExistingInvitedSubjectsValue}
                />
              </Grid>
              <Grid item xs={12}>
                <DropDown
                  defaultValue={selectedMainCategoriesId}
                  label={t("category")}
                  name={t("category")}
                  options={categoryOptions}
                  onChange={handleCategoryChange}
                  disabled={
                    appointmentrequestId && appointmentRequestStatus !== "draft"
                      ? true
                      : false
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <DropDown
                  defaultValue={selectedExaminationIds}
                  label={t("examination")}
                  name={t("examination")}
                  options={examinationOptions}
                  onChange={handleExaminationChange}
                  isMulti={true}
                  disabled={
                    !selectedMainCategoriesId ||
                    (appointmentrequestId &&
                    appointmentRequestStatus !== "draft"
                      ? true
                      : false)
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <DefaultInput
                  name="name"
                  label={t("name")}
                  value={customTitel}
                  onChange={handleTitleChange}
                  disabled={customTitel ? false : true}
                />
              </Grid>
              <Grid item xs={12}>
                <DropDown
                  defaultValue={selectedSlotDuration}
                  label={t("duration")}
                  name={t("duration")}
                  options={slotDurationOptions}
                  onChange={handleSlotDurationChange}
                  disabled={
                    appointmentrequestId && appointmentRequestStatus !== "draft"
                      ? true
                      : false
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <DropDown
                  defaultValue={selectedParallelSlots}
                  label={t("parallel_slots")}
                  name={t("parallel_slots")}
                  options={parallelSlotsOptions}
                  onChange={handleParallelSlotsChange}
                  disabled={
                    appointmentrequestId && appointmentRequestStatus !== "draft"
                      ? true
                      : false
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <DropDown
                  defaultValue={executedBy}
                  name="executed_by"
                  label={t("executed_by")}
                  options={executedByOptions}
                  onChange={handleExecutedByChange}
                  wrapperClassName={styles.dropDown__executedBy}
                  disabled={
                    appointmentrequestId && appointmentRequestStatus !== "draft"
                      ? true
                      : false
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  title={t("back_to", { name: t("appointmentrequest_multi") })}
                  onClick={() => navigate(`/appointmentrequest`)}
                />
                {appointmentRequestStatus !== "open" && (
                  <Button
                    className={"float-end"}
                    variant="contained"
                    color="secondary"
                    title={t("continue_to_appointment_building_information")}
                    // title={
                    //   appointmentRequestStatus === "draft"
                    //     ? t("continue_to_appointment_building_information")
                    //     : t("save")
                    // }
                    disabled={disableButtonCheck}
                    onClick={() => createAppointmentDraft()}
                  />
                )}
              </Grid>
            </>
          )}
        </Grid>
      )}
      {error && error !== "" && (
        <GenericErrorMessageModal
          title={t("error_occurred")}
          error={error}
          onClosehandler={() => {
            setError("");
          }}
        />
      )}
      <Dialog
        open={modalOpen}
        scroll="paper"
        component={"div"}
        onClose={() => setModalOpen(false)}
      >
        <DialogTitle textAlign={"center"}>{t("invited_users")}</DialogTitle>
        <DialogContent dividers>
          <DialogContentText
            component={"div"}
            display={"flex"}
            flexDirection={"column"}
          >
            <List>
              {invitedSubjects.map((subject: any) => (
                <ListItem key={subject.id} disablePadding>
                  <ListItemIcon>
                    {subject.appointment_created ? (
                      <CircleIcon color="success" fontSize="small" />
                    ) : (
                      <CircleIcon color="error" fontSize="small" />
                    )}
                  </ListItemIcon>
                  <ListItemText primary={subject.name} />
                </ListItem>
              ))}
            </List>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <MuiButton
            onClick={() => {
              setModalOpen(false);
            }}
          >
            Ok
          </MuiButton>
        </DialogActions>
      </Dialog>
    </>
  );
}
