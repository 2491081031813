import { FC } from "react";
import GeneralListItem from "../general/generaListItem";
import { Grid, Tooltip } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import styles from "./coreDataProbes.module.css";

import React from "react";

interface Props {
  filteredProbes: any;
  handleSetFormId: (id: any, name: any, probeId: any) => void;
}

const CoreDataProbes: FC<Props> = ({ filteredProbes, handleSetFormId }) => {
  const replaceDate = (dateString: any) => {
    let date = "";

    date = new Date(dateString).toLocaleString("de-DE", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    });

    if (dateString === null || dateString === undefined) {
      return "-";
    } else return date;
  };

  const renderItems = () => {
    const items = [];

    for (const key in filteredProbes) {
      const probe = filteredProbes[key];

      items.push(
        <Grid item xs={12} key={probe.id}>
          <GeneralListItem
            key={probe.id}
            hideButton={true}
            allowHover={false}
            onClickElement="wrapper"
            wrapperClassName={styles.generalListItemWrapper__details}
          >
            <div>Name: {probe.name}</div>
            <div>Beschreibung: {probe.description}</div>
            <div>
              Zuletzt bearbeitet:{" "}
              <strong>{replaceDate(probe.modified_on)}</strong>
            </div>
          </GeneralListItem>
          <div className={styles.formWrapper}>
            {renderForms(probe.form_set, probe.id)}
          </div>
        </Grid>
      );
    }

    return <>{items.map((item: any) => item)}</>;
  };

  const renderForms = (formSet: any, probeId: any) => {
    return formSet.map(
      (form: any) =>
        !form.hidden && (
          <div key={form.id} className={styles.formWrapper}>
            <GeneralListItem
              allowHover={true}
              onClickElement="wrapper"
              onClick={() => handleSetFormId(form.id, form.name, probeId)}
              wrapperClassName={styles.generalListItemWrapper__probe}
              hideButton={true}
            >
              <Tooltip title={"Formular öffnen"} placement="top-end">
                <div className={styles.probeWrapper}
                >
                  <h6 className={styles.heading}>{form.name}</h6>
                  <ArrowForwardIcon />
                </div>
              </Tooltip>
            </GeneralListItem>
          </div>
        )
    );
  };

  return (
    <Grid container item xs={9}>
      {renderItems()}
    </Grid>
  );
};

export default CoreDataProbes;

// TODO: Add translations