import { MouseEventHandler } from "react";
import BigRoundButton from "../general/bigRoundButton";
import styles from "./generalListItem.module.css";
import { SizeProp } from "@fortawesome/fontawesome";

interface ButtonConfig {
  tooltip?: string;
  icon: string;
  onClick: MouseEventHandler;
  backgroundColor?: string;
  iconSize?: SizeProp;
}

interface Props {
  buttonsConfig?: ButtonConfig[];
  className?: string;
  wrapperClassName?: string;
  contentClassName?: string;
  minWidth?: string;
  justifyContent?: string;
  onClick?: MouseEventHandler;
  hideButton?: boolean;
  children?: React.ReactNode;
  allowHover?: boolean;
  itemBackgroundColor?: string | any;
  textAlign?: string;
  margin?: string;
  padding?: string;
  fontWeight?: string;
  color?: string;
  fontStyle?: string;
  textDecoration?: string;
  visibility?: string;
  wrapperStyle?: React.CSSProperties;
  onClickElement?: "button" | "wrapper";
  contentStyles?: React.CSSProperties;
  buttonWrapperStyle?: React.CSSProperties;
  buttonHeight?: string;
  buttonWidth?: string;
  borderRadius?: string;
}

export default function GeneralListItem(props: Props) {
  const buttons = [];

  if (props.onClick !== undefined && !props.hideButton) {
    if (props.onClickElement && props.onClickElement === "wrapper") {
      buttons.push(<BigRoundButton key={0} />);
    } else buttons.push(<BigRoundButton key={0} onClick={props.onClick} />);
  }
  const buttonVisibility = {
    display: props.hideButton ? "none" : "flex",
    ...props.buttonWrapperStyle,
  } as React.CSSProperties;

  let hover = true;
  if (props.allowHover !== undefined && props.allowHover === false) {
    hover = false;
  }

  const wrapperStyles = {
    margin: props.margin ? props.margin : "0.625rem",
    padding: props.padding ? props.padding : "0.75rem",
    width: "100%",
    backgroundColor: props.itemBackgroundColor
      ? props.itemBackgroundColor
      : "#FFF",
    visibility: props.visibility,
    position: "relative",
    ...props.wrapperStyle,
  } as React.CSSProperties;

  const contentStyle = {
    width: props.hideButton ? "100%" : "100%",
    textAlign: props.textAlign,
    fontWeight: props.fontWeight,
    color: props.color,
    fontStyle: props.fontStyle,
    textDecoration: props.textDecoration,
    ...props.contentStyles,
  } as React.CSSProperties;

  if (props.buttonsConfig != undefined) {
    let counter = 0;
    for (const key in props.buttonsConfig) {
      counter++;
      const button = props.buttonsConfig[key];
      buttons.push(
        <div key={counter} className={styles.generalListItemButton}>
          <BigRoundButton
            backgroundColor={button.backgroundColor}
            tooltip={button.tooltip}
            onClick={button.onClick}
            icon={button.icon}
            iconSize={button.iconSize}
            buttonHeight={props.buttonHeight}
            buttonWidth={props.buttonWidth}
            borderRadius={props.borderRadius}
          />
        </div>
      );
    }
  }

  return (
    <div
      className={`${hover ? styles.generalListItemWrapper : styles.generalListItemWrapperNoHover} ${props.wrapperClassName}`}
      style={wrapperStyles}
      onClick={props.onClickElement === "wrapper" ? props.onClick : undefined}
    >
      <div className={`${styles.generalListItemContent} ${props.contentClassName}`} style={contentStyle}>
        {props.children}
      </div>
      <div className={styles.buttonsContainer} style={buttonVisibility}>{buttons}</div>
    </div>
  );
}
