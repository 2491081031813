import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import React, { FC, useEffect, useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import styles from "./appointmentTreeItemWidgets.module.css";

interface Props {
  categoryExaminationSet?: any;
}

const AppointmentTreeItemWidgets: FC<Props> = ({ categoryExaminationSet }) => {
  const [widgetForms, setWidgetForms] = useState<any>([]);
  const [widgetMeasurements, setWidgetMeasurements] = useState<any>([]);

  useEffect(() => {
    // if (appointment) {
    const tempWidgetForms = [];
    const tempMeasurements = [];
    const formNames: any = [];

    for (const examination of categoryExaminationSet) {
      if (examination.finding_set) {
        for (const finding of examination.finding_set) {
          if (finding.measurement_set) {
            for (const measurement of finding.measurement_set) {
              tempMeasurements.push(measurement);
            }
          }
          if (finding.form_set) {
            for (const form of finding.form_set) {
              if (form.appointment_dashboard_widget === true) {
                if (!formNames.includes(form.name)) {
                  tempWidgetForms.push(form);
                  formNames.push(form.name);
                }
              }
            }
          }
        }
      }
    }

    setWidgetForms(tempWidgetForms);
    setWidgetMeasurements(tempMeasurements);
    // }
  }, []);

  const renderForms = () => {
    const sortedFormsByPriorityAndName = widgetForms.sort((a: any, b: any) => {
      const priorityDifference =
        Number(a.widget_priority) - Number(b.widget_priority);

      if (priorityDifference !== 0) {
        return priorityDifference;
      }

      return a.name.localeCompare(b.name);
    });
    return sortedFormsByPriorityAndName.map((form: any, index: any) => {
      const name =
        form.widget_title !== "" && form.widget_title !== null
          ? form.widget_title
          : form.name;
      return (
        <Grid
          key={form.id}
          item
          xs={12}
        // className={styles.container}
        >
          <Accordion className={styles.accordion} defaultExpanded={index === 0}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <h2 className={styles.accordionTitle}>{name}</h2>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container component={List} className={styles.noPadding}>
                {form.measurementdefinitiontoform_set.map(
                  (measurement: any) => {
                    const widgetMeasure = widgetMeasurements.find(
                      (wm: any) =>
                        wm.measurement_definition ===
                        measurement.measurement_definition.id
                    );
                    return (
                      <Grid
                        item
                        component={ListItem}
                        key={measurement.id}
                        xs={3}
                        className={styles.noPadding}
                      >
                        <ListItemText
                          primary={measurement?.measurement_definition?.label}
                          primaryTypographyProps={{ className: styles.primaryText }}
                          secondaryTypographyProps={{ className: styles.secondaryText }}
                          secondary={widgetMeasure ? widgetMeasure.value : "-"}
                        />
                      </Grid>
                    );
                  }
                )}
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>
      );
    });
  };
  return <>{renderForms()}</>;
};

export default AppointmentTreeItemWidgets;
