import { Typography } from "@mui/material";
import "../styles/headerBar.css";
interface Props {
  companyLogo?: any;
  companyName?: string;
}

export default function CompanyArea(props: Props) {
  return (
    <>
      <Typography variant="h5">{props.companyName}</Typography>
    </>
  );
}
