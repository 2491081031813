import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import Button from "./inputs/button";
import DefaultInput from "./inputs/defaultInput";
import { useNavigate, useParams } from "react-router-dom";
import { Modal } from "@mui/material";
import { renderToStaticMarkup } from "react-dom/server";
import Background from "../../icons/background";
import "../styles/login.css";
import { api } from "../../helper/api";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import GenericErrorMessageModal from "./errorHandling/genericErrorMessageModal";
import GenericNotification from "../notification/genericNotification";
import { useForm } from "react-hook-form";
import PasswordInput from "./inputs/passwordInput";
import styles from './forgottenPasswordForm.module.css';

interface Props {
  title?: string;
}

const ForgottenPasswordForm: FC<Props> = () => {
  const [userName, setUserName] = useState<string>("");
  const [expiredLinkError, setExpiredLinkError] = useState<boolean>(false);
  const [error, setError] = useState<any>();
  const [showNotification, setShowNotification] = useState<boolean>(false);
  const [notificationMessage, setNotificationMessage] = useState<any>("");
  const [notificationVariant, setNotificationVariant] = useState<
    "success" | "error" | "warning" | "info" | undefined
  >();

  const { t } = useTranslation();
  const theme = useTheme();
  const resetStateOnCloseNotification = () => {
    setShowNotification(false);
    setNotificationMessage("");
  };

  const { userId, passwordResetToken } = useParams() as {
    userId: string;
    passwordResetToken: string;
  };

  const { handleSubmit, control, watch, getValues } = useForm({
    mode: "onChange",
    defaultValues: {
      password: "",
      confirmedPassword: "",
    },
  });

  const navigate = useNavigate();
  const passwordRegex = new RegExp(
    /^(?=.*[0-9])(?=.*[!=?\-_+/&$#*.,:;]).{10,}$/
  );
  const newPassword = watch("password");

  const svgString = encodeURIComponent(renderToStaticMarkup(<Background />));

  const handleUsernameChange = (event: any) => {
    setUserName(event.target.value);
  };

  const handleMailSubmit = (event: any) => {
    event.preventDefault();

    const result = { email: userName };

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    api.genericApiRequest({
      entity: "user/reset_forgotten_password",
      method: "put",
      successHandler: () => {
        setNotificationMessage(t("emailPasswordreset_send"));
        setNotificationVariant("success");
        setShowNotification(true);
      },
      failHandler: (error: any) => {
        setError(error);
      },
      submitData: result,
      config: config,
    });

    navigate("/login/");
  };
  const onPasswordSubmit = (data: any) => {
    const result = {
      password: data.password,
      token: passwordResetToken,
    };

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const additionalRouteParts: any = {};
    additionalRouteParts["reset_password"] = "/";
    api.genericApiRequest({
      entity: "user",
      method: "put",
      successHandler: () => {
        setNotificationMessage(t("passwordSet_successful"));
        setNotificationVariant("success");
        setShowNotification(true);
        navigate("/login/");
      },
      failHandler: (error: any, status: any) => {
        setError(error);
        console.log("error", error);
        if (status === 401) {
          setExpiredLinkError(true);
        }
      },
      entityId: userId,
      additionalRouteParts: additionalRouteParts,
      submitData: result,
      config: config,
    });
  };

  const formWrapperClass = useMediaQuery(theme.breakpoints.up("sm"))
    ? `${styles.formWrapper} ${styles.wideForm}`
    : styles.formWrapper;

  const buttonStyleClass = useMediaQuery(theme.breakpoints.up("sm"))
    ? styles.autoWidthButton
    : styles.fullWidthButton;

  const centerStyleClass = useMediaQuery(theme.breakpoints.up("sm"))
    ? `${styles.centerStyle} ${styles.noWrap}`
    : styles.centerStyle;

  const expiredLinkErrorMessage = (
    <div className={styles.errorMessage}>
      <ErrorOutlineIcon />
      <div className={styles.messageWrapper}>
        <span className={styles.errorSpan}>
          {t("password_reset_expired_link_error_message")}
        </span>
        <span className={styles.errorSpan}>
          {t("password_reset_expired_link_contact_before_email_address")} {""}
          <a className={styles.emailLink} href="mailto:service@kaerhealth.com">
            {" "}
            service@kaerhealth.com
          </a>
          , {t("password_reset_expired_link_contact_after_email_address")}
        </span>
      </div>
    </div>
  );

  const additionalPasswordCheckWithAlert = (
    pwd: any,
    confirmedPwd: any,
    regEx: RegExp
  ) => {
    if (pwd !== confirmedPwd) {
      alert(t("password_dont_match"));
    } else if (!regEx.test(pwd)) {
      alert(t("password_requirements"));
    }
  };

  const renderContent = () => {
    if (!passwordResetToken) {
      return (
        <div className={formWrapperClass}>
          <section className="flexColumn alignItems">
            <h1 className="heading heading_Mb textAlignCenter">
              {t("reset_password")}
            </h1>
            <p className="passwordRestMessage textAlignCenter">
              {t("reset_password_description")}
            </p>
          </section>
          <form onSubmit={handleMailSubmit} className="emailForm">
            <DefaultInput
              label={t("login_email")}
              value={userName}
              onChange={handleUsernameChange}
              labelClassName={styles.defaultInputLabel}
              inputClassName="passwordResetInput"
            />

            <div className={centerStyleClass}>
              <Button
                onClick={() => navigate("/login/")}
                title={t("back")}
                variant="contained"
                className={`passwordReset_cancelButton ${buttonStyleClass}`}
              />
              <Button
                type="submit"
                title={t("send")}
                variant="contained"
                color="secondary"
                className={`passwordReset_emailSubmitButton ${buttonStyleClass}`}
              />
            </div>
          </form>
        </div>
      );
    } else if (passwordResetToken) {
      return (
        <>
          <div className={formWrapperClass}>
            <form
              onSubmit={handleSubmit(onPasswordSubmit)}
              className="registerForm"
              noValidate
            >
              <h1 className="heading textAlignCenter">{t("reset_password")}</h1>
              <PasswordInput
                label={
                  <div>
                    <span className={styles.passwordLabel}>
                      {t("new_password")}
                    </span>
                    <span>{t("password_requirements")}</span>
                  </div>
                }
                id={t("new_password")}
                title={t("new_password")}
                dynamicLabel={false}
                control={control}
                name={"password"}
                required={true}
                requiredErrorMessage={t("field_required")}
                pattern={passwordRegex}
                patternErrorMessage={t("password_requirements")}
                errorFontColor={"#FFF"}
              />
              <PasswordInput
                id={t("confirm_password")}
                title={t("confirm_password")}
                label={t("confirm_password")}
                dynamicLabel={false}
                control={control}
                name={"confirmedPassword"}
                required={true}
                requiredErrorMessage={t("field_required")}
                errorFontColor={"#FFF"}
                validationRule={newPassword}
                validationRuleErrorMessage={t("password_dont_match")}
              />
              <div className={centerStyleClass}>
                <Button
                  type="submit"
                  title={t("send")}
                  variant="contained"
                  color="secondary"
                  className="passwordReset_submitButton"
                  onClick={() => {
                    additionalPasswordCheckWithAlert(
                      getValues("password"),
                      getValues("confirmedPassword"),
                      passwordRegex
                    );
                  }}
                />
              </div>
            </form>
          </div>

          <Modal
            open={expiredLinkError}
            onClose={() => setExpiredLinkError(false)}
            className={styles.expiredLinkModal}
          >
            {expiredLinkErrorMessage}
          </Modal>
          {error && error !== "" && (
            <GenericErrorMessageModal
              title={t("error_occurred")}
              error={error}
              onClosehandler={() => {
                setError("");
              }}
            />
          )}
          {showNotification && (
            <GenericNotification
              message={notificationMessage}
              variant={notificationVariant}
              handleCloseSnackbar={resetStateOnCloseNotification}
            />
          )}
        </>
      );
    }
  };

  return (
    <div className={styles.loginWrapper} style={{ background: `url('data:image/svg+xml;utf8, ${svgString}')` }}>
      {renderContent()}
    </div>
  );
};

export default ForgottenPasswordForm;
