import React, { useContext } from "react";
import PermissionContext from "../context/permissionContext";

type Props = {
  permissionRequired: string;
  children: any;
};

// This component is meant to be used everywhere a restriction based on user permission is needed
const Restricted: React.FunctionComponent<Props> = ({
  permissionRequired,
  children,
}) => {
  // We "connect" to the provider thanks to the PermissionContext
  const { isAllowedTo } = useContext(PermissionContext);

  // If the user has that permission, render the children
  if (isAllowedTo(permissionRequired)) {
    return <>{children}</>;
  }

  // Otherwise, do not render anything
  return null;
};

export default Restricted;
