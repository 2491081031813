import { useTranslation } from "react-i18next";
import GenericCrudTable from "../tables/genericCrudTable";
import { DEFAULTPICKERDATE } from "../../types/Entities";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { useEffect, useState } from "react";
import { api } from "../../helper/api";
import { Department } from "./administration";
import "./userAdministration.css";
import DropDownSubjectSearch from "../forms/inputs/dropDownSubjectSearch";
import GenericErrorMessageModal from "../forms/errorHandling/genericErrorMessageModal";
import GenericNotification from "../notification/genericNotification";
import styles from "./userTable.module.css";

interface Props {
  companySubjectId?: string;
  uploadSuccessful?: boolean;
  handleSendRegistrationEmail?: (
    row: any,
    skipQuestion?: false | undefined
  ) => void;
  companyId?: string;
  department?: Department[];
  handleDeleteDepartment?: () => void;
  handleUndeleteUser?: (
    row: any,
    reset: () => void,
    skipQuestion?: false | undefined
  ) => void;
  expanded?: any;
  handleUploadSuccess?: () => void;
}

export default function UserTable(props: Props) {
  const [allMailsToUserModal, setAllMailsToUserModal] =
    useState<boolean>(false);
  const [allSentMails, setAllSentMails] = useState<any>([]);
  const [userInfoForMailModal, setUserInfoForMailModal] = useState<any>({
    email: "",
    firstName: "",
    lastName: "",
  });
  const [userColumns, setUserColumns] = useState<any>([]);
  const [userToMove, setUserToMove] = useState<any>([]);
  const [moveUserModalOpen, setMoveUserModalOpen] = useState<boolean>(false);
  const [selectedCompanyToMoveUser, setSelectedCompanyToMoveUser] =
    useState<any>([]);
  const [error, setError] = useState<any>();
  const [showNotification, setShowNotification] = useState<boolean>(false);
  const [notificationMessage, setNotificationMessage] = useState<any>("");
  const [notificationVariant, setNotificationVariant] = useState<
    "success" | "error" | "warning" | "info" | undefined
  >();

  const hostname = window.location.hostname;
  const subdomain = hostname.split(".")[0];
  let isAdmin = false;
  const { t } = useTranslation();
  const resetStateOnCloseNotification = () => {
    setShowNotification(false);
    setNotificationMessage("");
  };

  if (subdomain === "admin") {
    isAdmin = true;
  } else {
    isAdmin = false;
  }

  const hideOnCreate = () => {
    if (subdomain === "admin" || subdomain === "manage") {
      return true;
    } else {
      return false;
    }
  };

  const getReadableDateTime = (datetimeString: any) => {
    const timeObject = new Date(datetimeString);

    if (timeObject) {
      return (
        String(timeObject.getDate()).padStart(2, "0") +
        "." +
        String(timeObject.getMonth() + 1).padStart(2, "0") +
        "." +
        String(timeObject.getFullYear()) +
        " - " +
        String(timeObject.getHours()).padStart(2, "0") +
        ":" +
        String(timeObject.getMinutes()).padStart(2, "0") +
        ":" +
        String(timeObject.getSeconds()).padStart(2, "0")
      );
    } else {
      return datetimeString;
    }
  };

  const handleGetPersonToMove = (row: any) => {
    setUserToMove(row.original);
    setMoveUserModalOpen(true);
  };

  const handleMoveUser = (skipQuestion = false) => {
    const userName = userToMove.first_name + ", " + userToMove.last_name;
    const companyName = selectedCompanyToMoveUser.label.split("(")[0];
    if (!skipQuestion) {
      if (
        /*eslint-disable */
        !confirm(
          t("confirm_move_employee_to_another_company", {
            userName,
            companyName,
          })
        )
      ) {
        return;
      }
    }
    const result = {
      company: selectedCompanyToMoveUser.companyId,
      department_set: [],
    };
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    api.genericApiRequest({
      entity: "subject",
      method: "put",
      successHandler: () => {
        setNotificationMessage(t("customer_successfully_moved"));
        setNotificationVariant("success");
        setShowNotification(true);
        setMoveUserModalOpen(false);
        props.handleUploadSuccess?.();
      },
      failHandler: (error: any) => {
        let errorMessage = error.message;
        for (const key in error.response.data) {
          const item = error.response.data[key];
          if (item !== undefined) {
            errorMessage = key + " - " + item[0];
          }
        }
        setError(error);
      },
      submitData: result,
      config: config,
      entityId: userToMove.person.subject,
    });
  };

  const replaceDate = (id: any, dateTime = false) => {
    let date = "";
    if (dateTime === true) {
      date = new Date(id).toLocaleString("de-DE", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
      });
    } else {
      date = new Date(id).toLocaleString("de-DE", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      });
    }

    if (id === null) {
      return "-";
    } else return date;
  };
  const handleShowAllMailsSentToUser = (row: any) => {
    setUserInfoForMailModal({
      email: row.original.email,
      firstName: row.original.first_name,
      lastName: row.original.last_name,
    });
    const additionalUrlParameters: any = {};
    additionalUrlParameters["user"] = row.original.id;
    api.genericApiRequest({
      entity: "mailLog",
      method: "get",

      successHandler: (res: any) => {
        {
          const sentItems = [];
          for (const key in res.data.results) {
            const item = res.data.results[key];
            const template = item.template;
            let status_color = "#C00000";
            if (item.status === "delivered") {
              status_color = "#82C000";
            } else if (
              item.status === "commited" ||
              item.status === "submitted"
            ) {
              status_color = "#FFB000";
            }
            sentItems.push({
              sent_on: item.sent_on,
              status: t(item.status),
              status_color: status_color,
              template_id: template.id,
              template_name: template.name,
            });
          }
          setAllSentMails(sentItems);
          setAllMailsToUserModal(true);
        }
      },
      failHandler: (error: any) => {
        setError(error);
      },
      parametersToRender: {
        depth: "0",
        additionalUrlParameters: additionalUrlParameters,
      },
    });
  };

  useEffect(() => {
    const columns = [
      {
        header: "ID",
        accessorKey: "id",
        id: "id",
        hideField: true,
        enableGlobalFilter: false,
      },

      {
        header: t("first_name"),
        accessorKey: "first_name",
        id: "first_name",
        enableForCreate: true,
        required: true,
        accessorFn: (row: any) => {
          if (row.is_deleted) {
            return `${row.first_name} (t("deleted"))`;
          } else {
            return row.first_name;
          }
        },
      },
      {
        header: t("last_name"),
        accessorKey: "last_name",
        id: "last_name",
        required: true,
        enableForCreate: true,
        accessorFn: (row: any) => {
          if (row.is_deleted) {
            return `${row.last_name} (t("deleted"))`;
          } else {
            return row.last_name;
          }
        },
      },
      {
        header: t("gender"),
        accessorKey: "person.sex",
        childEntityName: "person",
        customColumnFilterId: "person__sex",
        filterVariant: "select",
        filterSelectOptions: [
          { value: "male", label: t("male") },
          { value: "female", label: t("female") },

          { value: "diverse", label: t("diverse") },
        ],
        id: "sex",
        enableForCreate: true,
        Cell: ({ cell }: any) => {
          const cellValue = cell.getValue();
          let cellLabel;
          switch (cellValue) {
            case "male":
              cellLabel = t("male");
              break;
            case "female":
              cellLabel = t("female");
              break;
            case "diverse":
              cellLabel = t("diverse");
              break;
            default:
              cellLabel = "-";
          }
          return <span>{cellLabel}</span>;
        },
        selectOptions: [
          {
            value: "male",
            label: t("male"),
            id: "male",
          },
          {
            value: "female",
            label: t("female"),
            id: "female",
          },
          {
            value: "diverse",
            label: t("diverse"),
            id: "diverse",
          },
        ],
      },
      // {
      //   header: t("highest_group"),
      //   enableSorting: false,
      //   accessorKey: "group_set",
      //   customColumnFilterId: "groups__name",
      //   customFilterParameter: "__icontains",
      //   enableForCreate: hideOnCreate(),
      //   filterVariant: "select",
      //   filterSelectOptions: [
      //     { value: "customer", label: t("customer") },
      //     { value: "manager", label: t("manager") },
      //   ],

      //   multiselect: true,
      //   entity: "group",
      //   additionalUrlParameters:
      //     props.companyId === "11111111-1111-1111-1111-333333333333"
      //       ? { include_kaer_internal: true }
      //       : null,
      //   accessorFn: (row: any) => {
      //     const assignedGroups: any = [];
      //     if (row.group_set) {
      //       for (const key in row.group_set) {
      //         if (row.group_set[key].name) {
      //           assignedGroups.push(t(row.group_set[key].name));
      //         }
      //       }
      //     }
      //     if (subdomain === "admin") {
      //       return assignedGroups.join(", ");
      //     }
      //     if (subdomain === "manage") {
      //       if (assignedGroups.includes("Manager")) {
      //         return t("manager");
      //       } else return t("customer");
      //     }
      //   },
      // },
      {
        header: t("email"),
        accessorKey: "email",
        id: "email",
        enableForCreate: true,
        customFilterParameter: "_icontains",
        required: true,
        errorMessage: t("field_required"),
        validate: true,
        errorMessageValidation: t("email_already_in_use"),
      },
      {
        header: t("phone"),
        accessorKey: "person.phone",
        childEntityName: "person",
        customColumnFilterId: "person__phone",
        id: "phone",
        enableForCreate: true,
        customFilterParameter: "_icontains",
      },
      {
        header: t("departments"),
        accessorKey: "department_set",
        customColumnFilterId: "department_name",
        customFilterParameter: "_icontains",
        enableSorting: false,
        id: "department_set",
        accessorFn: (row: any) => {
          const departments: any = [];
          if (row.department_set) {
            for (const key in row.department_set) {
              if (row.department_set[key].name) {
                departments.push(row.department_set[key].name);
              }
            }
          }
          return departments.join(", ");
        },
        enableForCreate: true,
        multiselect: true,
        entity: "department",
      },
      {
        header: t("birth_date"),
        accessorKey: "person.birth_date",
        childEntityName: "person",
        id: "birth_date",
        enableGlobalFilter: false,
        enableForCreate: true,
        datePicker: true,
        required: true,
        errorMessage: t("field_required"),
        validate: true,
        errorMessageValidation: t("birthday_not_today"),
        defaultDate: DEFAULTPICKERDATE.UNSET,
        sortingFn: "datetime",
        filterVariant: "date",
        customColumnFilterId: "birth_date",
        customFilterParameter: "_icontains",
        enableColumnFilter: false,

        Cell: ({ cell }: any) => {
          return <span>{replaceDate(cell.getValue())}</span>;
        },
      },
      {
        header: t("analog_user"),
        id: "is_analog",
        enableForCreate: true,
        accessorKey: "is_analog",
        accessorFn: (row: any) => {
          if (row.is_analog) {
            return t("yes");
          } else return t("no");
        },
        selectOptions: [
          { value: "false", label: t("no"), id: "false" },
          { value: "true", label: t("yes"), id: "true" },
        ],
      },
      {
        header: t("worker_id"),
        accessorKey: "person.worker_id",
        childEntityName: "person",
        id: "worker_id",
        customColumnFilterId: "person__worker_id",
        enableForCreate: true,
        enableGlobalFilter: false,
      },
      {
        header: t("user_is_registered"),
        accessorKey: "is_registered",
        id: "is_registered",
        enableForCreate: true,
        customColumnFilterId: "agreed_to_data_collection_on",
        customFilterParameter: "__isnull",
        filterVariant: "select",
        filterSelectOptions: [
          { value: "false", label: t("yes") },
          { value: "true", label: t("no") },
        ],
        hideField: true,
        enableGlobalFilter: false,
        Cell: ({ cell }: any) => {
          return <span>{cell.getValue() ? t("yes") : t("no")}</span>;
        },
      },
    ];
    let newColumns;
    if (isAdmin === true) {
      newColumns = [
        ...columns.slice(0, columns.length - 2),
        {
          header: t("last_login"),
          accessorKey: "last_login",
          id: "last_login",
          filterVariant: "date",
          enableColumnFilter: false,

          enableGlobalFilter: false,
          Cell: ({ cell }: any) => {
            return <span>{replaceDate(cell.getValue(), true)}</span>;
          },
        },
        {
          header: t("is_deleted"),
          accessorKey: "is_deleted",
          id: "is_deleted",
          accessorFn: (row: any) => {
            if (row.is_deleted) {
              return t("deleted");
            }
          },
        },
        ...columns.slice(columns.length - 2),
      ];
    } else newColumns = columns;

    if (props.companyId === "11111111-1111-1111-1111-333333333333") {
      const kaerColumns = [
        ...newColumns.slice(0, 1),
        {
          header: t("title"),
          accessorKey: "person.title",
          childEntityName: "person",
          id: "title",

          enableForCreate: true,
          maxLength: 255,
          maxLengthMessage: t("string_maxLength_error", { number: 255 }),
        },
        ...newColumns.slice(1, 3),
        {
          header: t("highest_group"),
          enableSorting: false,
          accessorKey: "group_set",
          customColumnFilterId: "groups__name",
          customFilterParameter: "__icontains",
          enableForCreate: hideOnCreate(),
          filterVariant: "select",
          filterSelectOptions: [
            { value: "customer", label: t("customer") },
            { value: "manager", label: t("manager") },
          ],

          multiselect: true,
          entity: "group",
          additionalUrlParameters:
            props.companyId === "11111111-1111-1111-1111-333333333333"
              ? { include_kaer_internal: true }
              : null,
          accessorFn: (row: any) => {
            const assignedGroups: any = [];
            if (row.group_set) {
              for (const key in row.group_set) {
                if (row.group_set[key].name) {
                  assignedGroups.push(t(row.group_set[key].name));
                }
              }
            }
            if (subdomain === "admin") {
              return assignedGroups.join(", ");
            }
            if (subdomain === "manage") {
              if (assignedGroups.includes("Manager")) {
                return t("manager");
              } else return t("customer");
            }
          },
        },
        ...newColumns.slice(3, 6),
        {
          header: t("team"),
          accessorKey: "team.name",
          id: "team",

          enableForCreate: true,
          entity: "team",
          accessorFn: (row: any) => {
            if (row.team !== null) {
              return ` ${row.team.name}`;
            } else {
              return "";
            }
          },
        },
        ...newColumns.slice(6, 7),
        {
          header: t("person_additional_description"),
          accessorKey: "person.additional_description",
          childEntityName: "person",
          id: "additional_description",
          enableForCreate: true,
          textarea: true,
        },
        ...newColumns.slice(7),
      ];
      newColumns = kaerColumns;
    }
    setUserColumns(newColumns);
  }, []);

  return (
    <>
      <>
        <GenericCrudTable
          allowColumnFiltering={true}
          showColumnFilterbyDefault={true}
          entity={"user"}
          columns={userColumns}
          createUser={true}
          companySubjectId={props.companySubjectId}
          companyId={props.companyId}
          uploadSuccessful={props.uploadSuccessful}
          additionalUrlParameter={{ departments: true }}
          handleSendRegistrationEmail={props.handleSendRegistrationEmail}
          handleShowAllMailsSentToUser={handleShowAllMailsSentToUser}
          department={props.department}
          handleUndeleteUser={props.handleUndeleteUser}
          handleMovePerson={handleGetPersonToMove}
        />
        <Dialog open={allMailsToUserModal} scroll="paper">
          <DialogTitle textAlign={"center"}>
            {t("sent_mails_to_user", {
              userInfo:
                userInfoForMailModal.firstName +
                " " +
                userInfoForMailModal.lastName +
                ", " +
                userInfoForMailModal.email,
            })}
          </DialogTitle>
          <DialogContent dividers>
            <DialogContentText
              display={"flex"}
              flexDirection={"column"}
              component={"div"}
            >
              <table>
                <thead>
                  <tr>
                    <td className={styles.tableHeaderCell}>Zeitpunkt</td>
                    <td className={styles.tableCell}>Mail Template</td>
                    <td className={styles.tableCell}>Status</td>
                  </tr>
                </thead>
                <tbody>
                  {allSentMails.length > 0 ? (
                    allSentMails
                      .sort((a: any, b: any) =>
                        b.sent_on.localeCompare(a.sent_on)
                      )
                      .map((mail: any) => (
                        <tr key={mail.sent_on}>
                          <td className={styles.tableCell}>
                            {getReadableDateTime(mail.sent_on)}
                          </td>
                          <td className={styles.tableCell}>
                            {mail.template_name}
                          </td>
                          <td
                            className={styles.statusCell}
                            style={{ color: mail.status_color }}
                          >
                            {mail.status}
                          </td>
                        </tr>
                      ))
                  ) : (
                    <tr>
                      <td>{t("no_mails_sent_to_user")}</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setAllMailsToUserModal(false);
              }}
            >
              Ok
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={moveUserModalOpen}
          onClose={() => setMoveUserModalOpen(false)}
          fullWidth={true}
        >
          <DialogTitle textAlign={"center"}>{t("move_customer")}</DialogTitle>
          <DialogContent>
            <DropDownSubjectSearch
              subdomain={subdomain}
              onChange={(options: any) => setSelectedCompanyToMoveUser(options)}
              queryParameter="companies"
              customLabel={t("company")}
            />
            <DialogActions>
              <Button
                onClick={() => {
                  setMoveUserModalOpen(false);
                }}
              >
                {t("cancel")}
              </Button>
              <Button onClick={() => handleMoveUser(false)}>{t("move")}</Button>
            </DialogActions>
          </DialogContent>
        </Dialog>
      </>
      {error && error !== "" && (
        <GenericErrorMessageModal
          title={t("error_occurred")}
          error={error}
          onClosehandler={() => {
            setError("");
          }}
        />
      )}
      {showNotification && (
        <GenericNotification
          message={notificationMessage}
          variant={notificationVariant}
          handleCloseSnackbar={resetStateOnCloseNotification}
        />
      )}
    </>
  );
}
