import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import React from "react";
import { ItemList } from "./dndItemList";
import { t } from "i18next";
import styles from "./dndColumn.module.css";

interface Props {
  column?: any;
  index?: any;
  preExistingFormElements?: any;
  onHandleCheckboxChange?: (id: string) => void;
  onHandleRulesetChange?: (event: any, id: any) => void;
  onShowConfirmationModal?: (id: string) => void;
  handleLeftColumnFilter: (value: any) => void;
  selectOption?: any;
}

export const Column = React.memo(function Column({
  column,
  index,
  preExistingFormElements,
  onHandleCheckboxChange,
  onHandleRulesetChange,
  onShowConfirmationModal,
  handleLeftColumnFilter,
}: Props) {
  const columnWrapperStyle = {} as React.CSSProperties;

  return (
    <div style={columnWrapperStyle}>
      {column.id === "column-0" && (
        <div className={styles.columnHeader}>
          <h5 className={styles.columnTitle}>{column.title}</h5>

          <FormControl className={styles.formControl}>
            <InputLabel id="Eingabeelemente" />
            <Select
              label="Ein"
              labelId="Eingabeelemente"
              id="Eingabeelemente"
              defaultValue={"all"}
              sx={{
                boxShadow: "none",
                ".MuiOutlinedInput-notchedOutline": { border: "none" },
              }}
              onChange={(e) => handleLeftColumnFilter(e.target.value)}
            >
              <MenuItem value="all">{t("all_MD")}</MenuItem>
              <MenuItem value="unused">{t("new_MD")}</MenuItem>
              <MenuItem value="used">{t("used_MD")}</MenuItem>
            </Select>
          </FormControl>
        </div>
      )}
      <ItemList
        column={column}
        index={index}
        onShowConfirmationModal={onShowConfirmationModal}
        preExistingFormElements={preExistingFormElements}
        onHandleCheckboxChange={onHandleCheckboxChange}
        onHandleRulesetChange={onHandleRulesetChange}
      />
    </div>
  );
});
