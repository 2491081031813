import { FC } from "react";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { Tooltip } from "@mui/material";

export type placement =
  | "bottom-end"
  | "bottom-start"
  | "bottom"
  | "left-end"
  | "left-start"
  | "left"
  | "right-end"
  | "right-start"
  | "right"
  | "top-end"
  | "top-start"
  | "top"
  | undefined;

interface Props {
  tooltipMessage: string;
  placement: placement;
  iconSize?: string;
}

const InputTooltip: FC<Props> = ({ tooltipMessage, placement, iconSize }) => {
  const iconStyle = {
    fontSize: iconSize ? iconSize : "medium",
  } as React.CSSProperties;

  return (
    <Tooltip title={tooltipMessage} placement={placement}>
      <HelpOutlineIcon style={iconStyle} />
    </Tooltip>
  );
};

export default InputTooltip;
