import { useState, useEffect, useCallback, ReactNode } from "react";
import {
  MRT_PaginationState,
  MRT_SortingState,
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import { Delete, Edit } from "@mui/icons-material";
import { Backdrop, Box, Button, IconButton, Tooltip } from "@mui/material";
import GenericCrudTableModal from "./genericCrudTableModal";
import { MRT_Localization_DE } from "material-react-table/locales/de";
import CheckIcon from "@mui/icons-material/Check";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import PeopleIcon from "@mui/icons-material/People";
import EmailIcon from "@mui/icons-material/Email";
import RestoreFromTrashIcon from "@mui/icons-material/RestoreFromTrash";
import AllInboxIcon from "@mui/icons-material/AllInbox";
import GridViewIcon from "@mui/icons-material/GridView";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { DotLoader } from "react-spinners";
import { api } from "../../helper/api";
import { useSelector } from "react-redux";
import { RootState } from "../state/store";
import { Department } from "../userAdministration/administration";
import PersonRemoveIcon from "@mui/icons-material/PersonRemove";
import GenericErrorMessageModal from "../forms/errorHandling/genericErrorMessageModal";
import GenericNotification from "../notification/genericNotification";
import MapsHomeWorkIcon from "@mui/icons-material/MapsHomeWork";
import { useDispatch } from "react-redux";
import { setTableSearch } from "../state/tableSearch/tableSearchSlice";
import styles from "./genericCrudTable.module.css";

interface Props {
  entity: string;
  customEditHandler?: (row: any) => void;
  customDeleteHandler?: (row: any) => void;
  customCreateHandler?: () => void;
  handleTimeslotEditHandler?: (row: any) => void;
  handleSendRegistrationEmail?: (
    row: any,
    skipQuestion?: false | undefined
  ) => void;
  handleShowAllMailsSentToUser?: (row: any) => void;
  uploadSuccessful?: boolean;
  createUser?: boolean;
  companySubjectId?: string;
  companyId?: string;
  columns: any;
  disallowDelete?: boolean;
  setAppointmentRequestStatusOpen?: (
    row: any,
    skipQuestion?: false | undefined
  ) => void;
  handleReinviteSubjects?: (row: any, skipQuestion?: false | undefined) => void;
  isUsedInMeasurement?: (row: any) => boolean;
  resetDemoCompany?: any;
  additionalUrlParameter?: any;
  additionalRoutePartsForDelete?: any;
  handleShowInvitedPersons?: (row: any) => void;
  handleMovePerson?: (row: any) => void;
  moveUserSuccessful?: boolean;
  handleUndeleteUser?: (row: any, reset: () => void) => void;
  searchId?: string;
  department?: Department[];
  hasDepartments?: any;
  heading?: string;
  withSubjectSearch?: boolean; // must be given from the table if a subjectSearch is used for calculation of height
  handleShowCoredata?: (row: any) => void;
  showSubjectOnCompany?: boolean;
  additionalColumnsForCreate?: any;
  additionalColumnsForEdit?: any;
  handleNavigateToBuildings?: (row: any) => void;
  subjectIdForBuildings?: any;
  handleSetSubjectIdForBuildings?: any;
  handleGetMailTemplateId?: any;
  setMainBuildingOnManager?: (
    row: any,
    skipQuestion?: false | undefined
  ) => void;
  refreshTable?: boolean;
  readOnlyMode?: boolean;
  additionalComponentForModal?: ReactNode;
  expandedDepth?: boolean;
  customColumnVisibility?: any;
  customTopToolBarAction?: ReactNode | undefined;
  customGlobalFilterPosition?: any;
  customDensity?: "spacious" | "compact" | "comfortable";
  customPageSize?: any;
  showRowActions?: boolean;
  customRowActions?: any;
  entityType?: any;
  tableCollapseWidth?: any;
  getOnlyUsersFor?: string;
  allowColumnFiltering?: boolean;
  showColumnFilterbyDefault?: boolean;
  customCreatePresetValues?: any;
  additionalSubmitDataForCreate?: any;
  additionalOnSuccessfulCreateFunction?: any;
  hideDeleteButtonForRows?: boolean;
  disableSorting?: boolean;
  disableGlobalFilter?: boolean;
}

export default function GenericCrudTable(props: Props) {
  const [tableRows, setTableRows] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [modalMode, setModalMode] = useState<string>("insert");
  const [modalPresetValues, setModalPresetValues] = useState<any>([]);
  const [initialLoading, setInitialLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>();
  const [successForNotification, setSuccessForNotification] =
    useState<boolean>(false);
  const [notificationMessage, setNotificationMessage] = useState<any>("");
  const [notificationVariant, setNotificationVariant] = useState<
    "success" | "error" | "warning" | "info" | undefined
  >();
  const [userEmails, setUserEmails] = useState<string[]>([]);
  const [rowCount, setRowCount] = useState(0);
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: props.customPageSize ? props.customPageSize : 50,
  });
  const [isRefetching, setIsRefetching] = useState(false);
  const [globalFilter, setGlobalFilter] = useState("");
  const [columnFilter, setColumnFilter] = useState([]);
  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  const [columnSorting, setColumnSorting] = useState<string>("");
  const [defaultSortingLoaded, setDefaultSortingLoaded] =
    useState<boolean>(false);
  const [showRefetchLoader, setShowRefetchLoader] = useState<boolean>(false);

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const selectedChildCompany: any = useSelector(
    (state: RootState) => state.parent
  );
  const tableSearchState = useSelector(
    (state: RootState) => state.tableSearch[props.entity]
  );

  const hostname = window.location.hostname;
  const subdomain = hostname.split(".")[0];

  const data = tableRows;
  const columns = props.columns;
  const table = useMaterialReactTable({
    data,
    columns,
    state: { columnFilters: columnFilter },
  });

  useEffect(() => {
    if (tableSearchState && tableSearchState !== undefined) {
      setGlobalFilter(tableSearchState);
    } else setGlobalFilter("");
  }, []);

  const resetStateOnCloseNotification = () => {
    setSuccessForNotification(false);
  };

  useEffect(() => {
    let descending; // conversion to boolean for tableState
    let sort; // sortingValue for URL
    let tableSort; // sortingValue for tableState
    switch (props.entity) {
      case "user":
        sort = "last_name";
        tableSort = "last_name";
        descending = false;
        break;
      case "department":
        sort = "id";
        tableSort = "id";
        descending = false;
        break;
      case "company":
        sort = "name";
        tableSort = "name";
        descending = false;
        break;
      case "permission":
        sort = "content_type";
        tableSort = "content_type";
        descending = false;
        break;
      case "group":
        sort = "name";
        tableSort = "name";
        descending = false;
        break;
      default:
        sort = "-created_on";
        tableSort = "created_on";
        descending = true;
    }
    setColumnSorting(sort);
    setSorting([{ id: tableSort, desc: descending }]);
  }, []);

  useEffect(() => {
    if (sorting.length !== 0) {
      let id = sorting[0].id;
      if (id === "category" && props.entity === "appointmentRequest") {
        id = "category_name";
      }
      let descending = "";
      if (sorting[0].desc === true) {
        descending = "-";
      }
      setInitialLoading(false);
      setColumnSorting(`${descending}${id}`);
      setDefaultSortingLoaded(true);
      setShowRefetchLoader(true);
    } else if (sorting.length === 0) {
      setInitialLoading(false);
      setColumnSorting("-created_on");
      setDefaultSortingLoaded(true);
      setShowRefetchLoader(true);
    }
  }, [
    sorting,
    props.searchId,
    pagination,
    columnSorting,
    globalFilter,
    props.department,
    props.refreshTable,
    columnFilter,
    props.additionalUrlParameter,
  ]);

  const resetTable = function () {
    setTableRows([]);
  };

  const handleOpenEdit = (row: any) => {
    if (props.customEditHandler) {
      props.customEditHandler(row);
    } else {
      openEditModal(row);
    }
  };
  const handleOpenCreate = () => {
    if (props.customCreateHandler) {
      props.customCreateHandler();
    } else openCreateModal();
  };
  useEffect(() => {
    resetTable();
    setInitialLoading(false);
  }, [
    props.companySubjectId,
    props.uploadSuccessful,
    props.moveUserSuccessful,
  ]);

  const handleResetOnUndelete = () => {
    resetTable();
    setInitialLoading(false);
    setDefaultSortingLoaded(true);
  };

  useEffect(() => {
    if (
      (!initialLoading ||
        props.searchId ||
        props.searchId === "" ||
        props.department?.length !== 0) &&
      defaultSortingLoaded
    ) {
      const fetchData = async () => {
        // if (tableRows.length === 0) {
        //   setIsLoading(true);
        // } else {
        //   setIsRefetching(true);
        // }

        /*eslint-disable */
        let additionalUrlParameters: any = {};

        if (props.companySubjectId) {
          additionalUrlParameters["impersonate_subject"] =
            props.companySubjectId;
          // "?impersonate_subject=" + props.companySubjectId;
        }
        if (props.searchId && props.searchId !== "") {
          switch (props.entity) {
            case "appointmentRequest":
              additionalUrlParameters["company"] = props.searchId;
              break;
            case "note":
              additionalUrlParameters["entity_id"] = props.searchId;
              break;
            default:
              additionalUrlParameters["subject"] = props.searchId;
          }
        }
        // departments set?
        if (
          props.department &&
          props.department.length !== 0 &&
          props.entity === "user"
        ) {
          additionalUrlParameters["department_id"] =
            props.department[0].departmentId;
        }

        if (props.showSubjectOnCompany) {
          additionalUrlParameters["subject"] = "true";
        }

        // additionalParameters set within columns?
        if (props.additionalUrlParameter) {
          for (const key in props.additionalUrlParameter) {
            additionalUrlParameters[key] = props.additionalUrlParameter[key];
          }
        }

        if (columnFilter.length !== 0) {
          for (const key in columnFilter) {
            const singleColumnValues: any = columnFilter[key];
            const column = singleColumnValues.id;
            let columnSearchValue = singleColumnValues.value;
            const columnDef: any = table.getColumn(column).columnDef;
            let customFilterId = "";
            let filterVariant = "";
            let customFilterParameters = "";
            if (columnDef?.customColumnFilterId) {
              customFilterId = columnDef?.customColumnFilterId;
            }
            if (columnDef?.filterVariant) {
              filterVariant = columnDef?.filterVariant;
            }
            if (columnDef?.customFilterParameter) {
              customFilterParameters = columnDef?.customFilterParameter;
            }

            if (filterVariant === "date") {
              const tempDateString = new Date(
                singleColumnValues.value
              ).toISOString();
              columnSearchValue = dayjs(tempDateString).format("YYYY-MM-DD");
              if (customFilterId !== "") {
                additionalUrlParameters[column + customFilterParameters] =
                  columnSearchValue;
              } else
                additionalUrlParameters[column + "__date"] = columnSearchValue;
            } else if (customFilterId !== "") {
              if (customFilterParameters) {
                additionalUrlParameters[
                  customFilterId + customFilterParameters
                ] = columnSearchValue;
              } else
                additionalUrlParameters[customFilterId + "__icontains"] =
                  columnSearchValue;
            } else if (customFilterParameters) {
              additionalUrlParameters[column + "_icontains"] =
                columnSearchValue;
            } else
              additionalUrlParameters[column + "__icontains"] =
                columnSearchValue;
          }
        }

        try {
          const res = await api.genericApiRequest({
            method: "get",
            entity: props.entity,
            parametersToRender: {
              depth: props.expandedDepth ? "1" : "0",
              ordering: columnSorting,
              limit: pagination.pageSize,
              offset: pagination.pageIndex * pagination.pageSize,
              search: globalFilter,
              additionalUrlParameters: additionalUrlParameters,
            },

            successHandler: (res: any) => {
              /*eslint-enable */

              const data = res.data;

              setTableRows(data.results);

              setRowCount(data.count);
              setIsLoading(false);
              setInitialLoading(true);
              setDefaultSortingLoaded(false);
              setShowRefetchLoader(false);
              // setIsRefetching(false);

              if (
                props.entity === "department" &&
                props.hasDepartments &&
                res.data.results.length === 0
              ) {
                props.hasDepartments();
              }
              if (props.entity === "user") {
                const allEmails = [];
                for (const key in res.data.results) {
                  const item = res.data.results[key];
                  const email = item.email;
                  allEmails.push(email);
                }
                setUserEmails(allEmails);
              }
            },
          });
        } catch (error) {
          setError(error);
          return;
        }
        setIsRefetching(false);
        // setDefaultSortingLoaded(false);
      };
      fetchData();
    }
  }, [
    tableRows.length,
    props.entity,
    initialLoading,
    columnSorting,
    pagination.pageSize,
    pagination.pageIndex,
    globalFilter,
    props.searchId,
    defaultSortingLoaded,
    props.department,
    columnFilter,
  ]);

  const handleDeleteRow = useCallback(
    (row: any, skipQuestion = false) => {
      if (!skipQuestion) {
        if (
          /*eslint-disable */
          !confirm(t("delete_entry_question"))
          /*eslint-enable */
        ) {
          return;
        }
      }
      row.toggleSelected(false);

      const additionalUrlParameters: any = {};
      const additionalRouteParts: any = {};

      const id = row.getValue("id");

      if (props.companySubjectId) {
        additionalUrlParameters["impersonate_subject"] = props.companySubjectId;
      }

      const fetchData = async () => {
        try {
          /*eslint-disable */
          const res = await api.genericApiRequest({
            method: "delete",
            entity: props.entity,
            entityId: id,
            parametersToRender: {
              additionalUrlParameters: additionalUrlParameters,
              depth: "0",
            },
            additionalRouteParts: additionalRouteParts,
            successHandler: () => {
              /*eslint-enable */
              setNotificationMessage(t("delete_successful"));
              setNotificationVariant("success");
              setSuccessForNotification(true);
              resetTable();
              setInitialLoading(false);
              setDefaultSortingLoaded(true);
            },
          });
        } catch (error: any) {
          setError(error);
        }
      };

      fetchData();
    },
    [props.entity]
  );

  const sanitizeValuesBeforeSendingToApi = (values: any) => {
    //Check each value for it's column config. If it's configured with "isJson = True" and if the value is a string, then convert it to a full JSON object before saving
    if (values && typeof values == "object") {
      for (const [key, value] of Object.entries(values)) {
        for (const columnKey in props.columns) {
          const column = props.columns[columnKey];
          if (column.accessorKey === key) {
            if (
              column.isJson === true &&
              (typeof value === "string" || value instanceof String)
            ) {
              values[key] = JSON.parse(values[key]);
            }
          }
        }
      }
    }
    return values;
  };
  const handleCreateNewRow = (values: any, resetFunction?: () => void) => {
    let result = sanitizeValuesBeforeSendingToApi(values);
    // let result = values;

    if (
      (selectedChildCompany.subjectId || props.companySubjectId) &&
      props.entity === "department"
    ) {
      result = {
        ...values,
        company: props.companyId,
        impersonate_subject: selectedChildCompany.subjectId
          ? selectedChildCompany.subjectId
          : props.companySubjectId,
      };
    }

    //Add user on Admin
    if (props.companySubjectId && props.entity === "user") {
      result = {
        ...values,
        impersonate_subject: props.companySubjectId,
        birth_date: dayjs(values.birth_date).format("DD.MM.YYYY"),
        team_id: values.team,
      };
    }
    //Add user on Manager
    if (
      !props.companySubjectId &&
      !props.companyId &&
      props.entity === "user"
    ) {
      result = {
        ...values,
        impersonate_subject: selectedChildCompany.subjectId,
        birth_date: dayjs(values.birth_date).format("DD.MM.YYYY"),
      };
    }
    if (props.entity === "company") {
      result = {
        ...values,
        team_id: values.team,
        main_building: {
          name: values.building_name,
          zip: values.zip,
          street_house: values.street_house,
          city: values.city,
          contact_name: values.contact_name,
          contact_email: values.contact_email,
          contact_phone: values.contact_phone,
        },
      };
    }
    if (
      props.entity === "building" &&
      props.companySubjectId &&
      props.companySubjectId !== undefined
    ) {
      result = { ...values, subject: props.companySubjectId };
    }

    if (props.entity === "note") {
      result = {
        payload: values.payload === undefined ? "" : values.payload,
        entity_id: props.searchId,
        entity_type: props.entityType,
      };
    }

    if (props.additionalSubmitDataForCreate) {
      for (const [key, value] of Object.entries(
        props.additionalSubmitDataForCreate
      )) {
        result[key] = value;
      }
    }
    if (props.entity === "user" && result.is_analog === "true") {
      if (result.email === undefined) {
        delete result.email;
        result = { ...result };
      }
    }
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    api.genericApiRequest({
      method: "post",
      entity: props.createUser ? "user/create_worker" : props.entity,
      successHandler: () => {
        setNotificationMessage(t("create_successful"));
        setNotificationVariant("success");
        setSuccessForNotification(true);
        setModalPresetValues([]);
        resetTable();
        setInitialLoading(false);
        setDefaultSortingLoaded(true);
        setModalOpen(false);
        if (resetFunction) {
          resetFunction();
        }
      },
      failHandler: (error: any) => {
        setError(error);
      },
      submitData: result,
      config: config,
    });
  };

  const handleSaveRow = (values: any, resetFunction?: () => void) => {
    let result = sanitizeValuesBeforeSendingToApi(values);

    if (
      (selectedChildCompany.subjectId || props.companySubjectId) &&
      props.entity === "department"
    ) {
      result = {
        ...values,
        company: props.companyId,
        impersonate_subject: selectedChildCompany.subjectId
          ? selectedChildCompany.subjectId
          : props.companySubjectId,
      };
    }
    if (props.entity === "permission") {
      result = {
        ...values,
        codename: modalPresetValues.codename,
        content_type: modalPresetValues.content_type,
      };
    }

    //Edit user on admin
    if (props.companySubjectId && props.entity === "user") {
      result = {
        ...values,
        company_id: props.companySubjectId,
        birth_date: dayjs(values.birth_date).format("DD.MM.YYYY"),
        team_id: values.team,
      };
    }
    //Edit user on manager
    if (
      !props.companySubjectId &&
      !props.companyId &&
      props.entity === "user"
    ) {
      result = {
        ...values,
        impersonate_subject: selectedChildCompany.subjectId,
        birth_date: dayjs(values.birth_date).format("DD.MM.YYYY"),
      };
    }
    if (props.entity === "company") {
      result = {
        ...values,
        parent: values.parent === undefined ? null : values.parent,
        team_id: values.team,
      };
    }
    if (props.entity === "note") {
      result = {
        payload: values.payload === undefined ? "" : values.payload,
        entity_id: props.searchId,
        entity_type: props.entityType,
      };
    }
    if (props.entity === "user" && result.is_analog === "true") {
      if (result.email === undefined) {
        delete result.email;
        result = { ...result };
      }
    }
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    api.genericApiRequest({
      entity: props.entity,
      method: "put",
      successHandler: (res: any) => {
        setNotificationMessage(t("save_successful"));
        setNotificationVariant("success");
        setSuccessForNotification(true);
        setModalPresetValues([]);
        resetTable();
        setInitialLoading(false);
        setDefaultSortingLoaded(true);
        setModalOpen(false);
        if (resetFunction) {
          resetFunction();
        }
        if (props.additionalOnSuccessfulCreateFunction) {
          props.additionalOnSuccessfulCreateFunction(res.data);
        }
      },
      failHandler: (error: any) => {
        setError(error);
      },

      entityId: String(modalPresetValues.id),
      submitData: result,
      config: config,
    });
  };

  const openCreateModal = () => {
    setModalMode("insert");
    if (props.customCreatePresetValues) {
      setModalPresetValues(props.customCreatePresetValues);
    } else {
      setModalPresetValues([]);
    }
    setModalOpen(true);
  };

  const openEditModal = (row: any) => {
    const tempPresetValues = row.original;

    // if the entity to edit has childEntities, check if we need to get properties of this child one lvl up
    for (const columnKey in props.columns) {
      const column = props.columns[columnKey];
      if (column.childEntityName) {
        tempPresetValues[column.id] =
          tempPresetValues[column.childEntityName][column.id];
      }
    }
    setModalPresetValues(tempPresetValues);
    setModalMode("edit");
    setModalOpen(true);
  };

  const columnVisibility = {
    id: false,
    created_by: false,
    modified_by: false,
    modified_on: false,
  };
  if (props.entity === "measurementDefinition")
    Object.assign(columnVisibility, { type: false, unit: false, reuse: false });
  if (props.customColumnVisibility) {
    Object.assign(columnVisibility, props.customColumnVisibility);
  }

  const isDeletionAllowed = (row: any) => {
    if (
      (props.disallowDelete && props.disallowDelete === true) ||
      props.isUsedInMeasurement?.(row) ||
      row.original.can_be_deleted === false
    ) {
      return true;
    }

    return false;
  };

  const checkForManagerRole = (value: any) => {
    if (value.find((item: any) => item.name === "manager")) {
      return true;
    } else return false;
  };

  let enableEditing = true;
  let enableRowActions = true;

  if (
    (props.readOnlyMode && props.readOnlyMode == true) ||
    props.showRowActions === false
  ) {
    enableEditing = false;
    enableRowActions = false;
  }
  const handleSetGlobalTableSearch = (searchValue: string, entity: string) => {
    dispatch(setTableSearch({ searchValue: searchValue, entity: entity }));
  };

  const handleColumnFilter = (searchValues: any) => {
    setColumnFilter(searchValues);
  };

  let enableSorting = true;
  if (props.disableSorting) {
    enableSorting = false;
  }
  let enableGlobalFilter = true;
  if (props.disableGlobalFilter) {
    enableGlobalFilter = false;
  }
  return (
    <>
      {isLoading ? (
        <DotLoader
          color="#8c1ec8"
          size={65}
          cssOverride={{ position: "absolute", top: "45vh", left: "50vw" }}
        />
      ) : (
        <MaterialReactTable
          columns={props.columns}
          data={tableRows}
          editDisplayMode="modal"
          localization={MRT_Localization_DE}
          enableSorting={enableSorting}
          // enableStickyFooter={true}
          enableStickyHeader={true}
          layoutMode="semantic"
          displayColumnDefOptions={{
            "mrt-row-actions": {
              size: 40,
              muiTableHeadCellProps: {
                align: "center",
              },
            },
          }}
          positionGlobalFilter={
            props.customGlobalFilterPosition && props.customGlobalFilterPosition
            // : "right"
          }
          muiTableContainerProps={{
            sx: {
              maxHeight: props.withSubjectSearch
                ? `clamp(350px, calc(90vh - 16.5rem), 9999px)`
                : `clamp(350px, calc(90vh - 10.5rem), 9999px)`,
            },
          }}
          enableEditing={enableEditing}
          enableHiding
          enableColumnResizing={true}
          enableFullScreenToggle={false}
          enableRowActions={enableRowActions}
          manualFiltering={true}
          enableGlobalFilter={enableGlobalFilter}
          onGlobalFilterChange={(e: any) => {
            setGlobalFilter(e);
            setInitialLoading(false);
            handleSetGlobalTableSearch(e, props.entity);
          }}
          onColumnFiltersChange={(e: any) => {
            // setColumnFilter(e);
            handleColumnFilter(e);
          }}
          manualPagination={true}
          manualSorting={true}
          onPaginationChange={(e: any) => {
            setPagination(e);
            setInitialLoading(false);
          }}
          rowCount={rowCount}
          onSortingChange={(e) => {
            setSorting(e), setInitialLoading(false);
          }}
          enableColumnFilters={
            subdomain === "admin" ? props.allowColumnFiltering : false
          }
          muiFilterTextFieldProps={
            {
              // onClick: () => {
              //   setColumnFilter([]);
              // },
              // placeholder: "",
            }
          }
          muiSearchTextFieldProps={{
            onClick: () => {
              setGlobalFilter("");
            },
            autoFocus:
              props.entity === "appointmentRequest" ||
              props.entity === "appointment" ||
              props.entity === "user" ||
              props.entity === "todo" ||
              props.entity === "note"
                ? false
                : true,
          }}
          muiTableBodyRowProps={({ row }: any) =>
            row.index % 2 === 0
              ? {
                  sx: {
                    backgroundColor: "#f5f5f5",
                  },
                }
              : { sx: { backgroundColor: "#FFF" } }
          }
          muiTablePaperProps={{
            sx: {
              marginBottom: "2rem",
              "& .MuiTextField-root": {
                backgroundColor: "#f4f2f2",
              },
              "&&  .MuiCollapse-root, && .MuiCollapse-wrapperInner": {
                width: props.tableCollapseWidth && props.tableCollapseWidth,
              },
              "&& .MuiTableCell-root": {
                whiteSpace: props.entity === "note" ? "break-spaces" : "",
              },
              // "&& .MuiSvgIcon-root": { fill: "#8c1ec8" },
              "&& .MuiTextField-root": {
                backgroundColor: "white",
              },
              "&& .MuiBox-root": {
                zIndex: "unset",
              },
            },
          }}
          muiTableBodyCellProps={({ row }: any) =>
            //Manager Bold on delete and active disabled for now

            props.entity === "user" &&
            checkForManagerRole(row.original.group_set) &&
            row.original.is_deleted === false
              ? {
                  sx: {
                    fontWeight: "bold",
                    letterSpacing: "0px",
                    borderRight: "1px solid #e0e0e0",
                  },
                }
              : props.entity === "user" &&
                  checkForManagerRole(row.original.group_set) &&
                  row.original.is_deleted === true
                ? {
                    sx: {
                      fontWeight: "bold",
                      letterSpacing: "0px",
                      borderRight: "1px solid #e0e0e0",
                      color: "lightgray",
                    },
                  }
                : props.entity === "user" && row.original.is_deleted === true
                  ? {
                      sx: {
                        borderRight: "1px solid #e0e0e0",
                        color: "lightgray",
                      },
                    }
                  : { sx: { borderRight: "1px solid #e0e0e0" } }
          }
          initialState={{
            columnVisibility: columnVisibility,
            showGlobalFilter: true,
            showColumnFilters:
              subdomain === "admin" ? props.showColumnFilterbyDefault : false,
            density: props.customDensity ? props.customDensity : "comfortable",
          }}
          state={{
            isLoading: isLoading,
            showProgressBars: isRefetching,
            pagination: pagination,
            globalFilter: globalFilter,
            columnFilters: columnFilter,
            sorting: sorting,
          }}
          renderRowActions={({ row }: any) => (
            <Box className={styles.rowActionsWrapper}>
              <Tooltip arrow placement="left" title={t("edit")}>
                <IconButton
                  onClick={() => {
                    {
                      handleOpenEdit(row);
                      if (props.handleSetSubjectIdForBuildings) {
                        props.handleSetSubjectIdForBuildings?.(row.original);
                      }
                      if (props.handleGetMailTemplateId) {
                        props.handleGetMailTemplateId?.(row.original);
                      }
                    }
                  }}
                >
                  <Edit />
                </IconButton>
              </Tooltip>

              <Tooltip arrow placement="right" title={t("delete")}>
                <span>
                  <IconButton
                    disabled={isDeletionAllowed(row)}
                    color="error"
                    onClick={() =>
                      props.customDeleteHandler
                        ? props.customDeleteHandler(row)
                        : handleDeleteRow(row)
                    }
                  >
                    <Delete />
                  </IconButton>
                </span>
              </Tooltip>
              {props.entity === "appointmentRequest" && (
                <>
                  <Tooltip
                    arrow
                    placement="right"
                    title={
                      row.original.status === "draft" &&
                      row.original.day_set.length !== 0
                        ? t("status_change")
                        : row.original.status === "draft" &&
                            row.original.day_set.length === 0
                          ? t("appointmentRequest_day_required")
                          : t("reinvite_subjects")
                    }
                  >
                    <span>
                      <IconButton
                        disabled={
                          row.original.status === "draft" &&
                          row.original.day_set.length === 0
                        }
                        onClick={() => {
                          row.original.status === "draft"
                            ? props?.setAppointmentRequestStatusOpen?.(row)
                            : props?.handleReinviteSubjects?.(row);
                        }}
                      >
                        {row.original.status === "draft" ? (
                          <CheckIcon />
                        ) : (
                          <GroupAddIcon />
                        )}
                      </IconButton>
                    </span>
                  </Tooltip>
                  {row.original.status === "open" && (
                    <Tooltip arrow placement="right" title={t("show_invited")}>
                      <IconButton
                        onClick={() => props.handleShowInvitedPersons?.(row)}
                      >
                        <PeopleIcon />
                      </IconButton>
                    </Tooltip>
                  )}
                  {row.original.status === "open" && (
                    <Tooltip
                      arrow
                      placement="right"
                      title={t("edit_available_timeslots")}
                    >
                      <IconButton
                        onClick={() => {
                          props.handleTimeslotEditHandler?.(row.original.id);
                        }}
                      >
                        <GridViewIcon />
                      </IconButton>
                    </Tooltip>
                  )}
                </>
              )}
              {props.entity === "user" && (
                <Tooltip
                  arrow
                  placement="right"
                  title={t("send_registration_email")}
                >
                  <IconButton
                    onClick={() => {
                      props.handleSendRegistrationEmail?.(row);
                    }}
                  >
                    <EmailIcon />
                  </IconButton>
                </Tooltip>
              )}
              {props.entity === "user" && props.companySubjectId && (
                <>
                  {row.original.is_deleted && (
                    <Tooltip
                      arrow
                      placement="right"
                      title={t("undelete", {
                        user:
                          row.original.first_name +
                          " " +
                          row.original.last_name,
                      })}
                    >
                      <IconButton
                        onClick={() =>
                          props.handleUndeleteUser?.(row, handleResetOnUndelete)
                        }
                      >
                        <RestoreFromTrashIcon />
                      </IconButton>
                    </Tooltip>
                  )}

                  <Tooltip
                    arrow
                    placement="right"
                    title={t("show_all_mails_sent_to_user", {
                      user:
                        row.original.first_name + " " + row.original.last_name,
                    })}
                  >
                    <IconButton
                      onClick={() => props.handleShowAllMailsSentToUser?.(row)}
                    >
                      <AllInboxIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip
                    arrow
                    placement="right"
                    title={t("move_customer_to_company", {
                      user:
                        row.original.first_name + " " + row.original.last_name,
                    })}
                  >
                    <IconButton onClick={() => props.handleMovePerson?.(row)}>
                      <PersonRemoveIcon />
                    </IconButton>
                  </Tooltip>
                </>
              )}
              {props.entity === "building" &&
                !props.companySubjectId &&
                !row.original.is_main_building && (
                  <Tooltip
                    arrow
                    placement="right"
                    title={t("building_set_main_building", {
                      building: row.original.name,
                    })}
                  >
                    <IconButton
                      onClick={() =>
                        props.setMainBuildingOnManager?.(row.original)
                      }
                    >
                      <MapsHomeWorkIcon />
                    </IconButton>
                  </Tooltip>
                )}
              {props.customRowActions && props.customRowActions(row)}
            </Box>
          )}
          // enableRowSelection={true}
          onEditingRowSave={handleSaveRow}
          renderTopToolbarCustomActions={({ table }) => {
            const handleDelete = () => {
              const rows: any = [];
              const itemNames: string[] = [];
              table.getSelectedRowModel().flatRows.map((row) => {
                rows.push(row);
                if (props.entity === "user") {
                  itemNames.push(
                    String(row.getValue("last_name")) +
                      " " +
                      String(row.getValue("first_name"))
                  );
                } else if (props.entity === "appointmentRequest") {
                  itemNames.push(String(row.getValue("title")));
                } else itemNames.push(String(row.getValue("name")));
                return true;
              });
              const itemNamesString = itemNames.join(", ");
              if (
                /*eslint-disable */
                !confirm(
                  `Möchtest du die Einträge (${itemNamesString}) wirklich löschen?`
                )
                /*eslint-enable */
              ) {
                return;
              }
              for (const rowKey in rows) {
                handleDeleteRow(rows[rowKey], true);
              }
            };
            if (props.readOnlyMode && props.readOnlyMode == true) {
              return (
                <>
                  {props.heading && props.heading !== "" && (
                    <h1>{props.heading}</h1>
                  )}
                </>
              );
            }

            if (props.customTopToolBarAction) {
              return (
                <div className={styles.topToolBarWrapper}>
                  <h1>{props.heading}</h1>
                  {props.customTopToolBarAction}
                </div>
              );
            }

            return (
              <div>
                {props.heading && props.heading !== "" && (
                  <h1>{props.heading}</h1>
                )}
                {props.entity !== "permission" && (
                  <div className={styles.flexContainer}>
                    <Button
                      color="secondary"
                      onClick={() => handleOpenCreate()}
                      variant="contained"
                    >
                      {t("add")}
                    </Button>
                    {Object.keys(table.getState().rowSelection).length > 0 && (
                      <span>
                        <Button
                          type="button"
                          color="error"
                          disabled={
                            !table.getIsSomeRowsSelected() &&
                            !table.getIsAllPageRowsSelected()
                          }
                          onClick={handleDelete}
                          variant="contained"
                        >
                          {t("delete")}
                        </Button>
                      </span>
                    )}
                    {props.entity === "company" && (
                      <Button
                        color="warning"
                        onClick={() => props.resetDemoCompany()}
                        variant="outlined"
                      >
                        {t("reset_demo")}
                      </Button>
                    )}
                  </div>
                )}
              </div>
            );
          }}
        />
      )}
      <>
        <GenericCrudTableModal
          columns={props.columns}
          additionalComponentForModal={props.additionalComponentForModal}
          subjectIdForBuildings={props.subjectIdForBuildings}
          additionalColumnsForCreate={props.additionalColumnsForCreate}
          additionalColumnsForEdit={props.additionalColumnsForEdit}
          companySubjectId={props.companySubjectId}
          companyId={props.companyId}
          open={modalOpen}
          presetValues={modalPresetValues}
          modalMode={modalMode}
          userEmails={userEmails}
          onClose={() => {
            setModalPresetValues([]);
            setModalOpen(false);
          }}
          title={(function () {
            if (modalMode === "edit") {
              return t("edit_element");
            }
            return t("add_new_element");
          })()}
          submitButtonText={(function () {
            if (modalMode === "edit") {
              return t("save");
            }
            return t("add");
          })()}
          onSubmit={(function () {
            if (modalMode === "edit") {
              return handleSaveRow;
            }
            return handleCreateNewRow;
          })()}
        />

        {error && error !== "" && (
          <GenericErrorMessageModal
            title={t("error_occurred")}
            error={error}
            onClosehandler={() => {
              setError("");
            }}
          />
        )}
      </>
      {showRefetchLoader && !isLoading && (
        <Backdrop className={styles.backdrop} open={true}>
          <div className={styles.loaderWrapper}>
            <DotLoader
              color="#8c1ec8"
              size={65}
              cssOverride={{ position: "absolute", top: "45vh", left: "50vw" }}
            />
          </div>
        </Backdrop>
      )}
      {successForNotification && (
        <GenericNotification
          message={notificationMessage}
          variant={notificationVariant}
          handleCloseSnackbar={resetStateOnCloseNotification}
        />
      )}
    </>
  );
}
