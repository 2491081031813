import React from "react";
import InputTooltip, { placement } from "../../general/tooltip";
import styles from './textAreaWithRef.module.css';

interface Props {
  style?: React.CSSProperties;
  mandatory?: boolean;
  label?: string;
  name?: string;
  readonly?: boolean;
  required?: boolean;
  rows?: number;
  cols?: number;
  value?: string;
  defaultValue?: string;
  onChange?: (event: any) => void;
  tooltip?: boolean;
  placement?: placement;
  tooltipMessage?: any;
}

export const TextAreaWithRef = React.forwardRef<HTMLTextAreaElement, Props>(
  function TextAreaWithRef(props, ref) {

    return (
      <div>
        <label className={styles.label}>
          {props.label}
          {props.required && (
            <span className={styles.labelRequired}>{"\u{002A}"}</span>
          )}
          {props.tooltip && (
            <InputTooltip
              tooltipMessage={props.tooltipMessage}
              placement={props.placement}
            />
          )}
        </label>
        <textarea
          ref={ref}
          name={props.name}
          id={props.name}
          readOnly={props.readonly}
          // required={props.required}
          rows={props.rows !== undefined ? props.rows : 4}
          cols={props.cols}
          value={props.value}
          defaultValue={props.defaultValue}
          onChange={props.onChange}
          className={`${styles.textArea} ${props.cols !== undefined ? styles.textAreaWithCustomWidth : ''
            }`}
        />
      </div>
    );
  }
);
