import { Grid, Tooltip } from "@mui/material";
import React, { FC, useEffect, useState } from "react";
import GeneralListItem from "../general/generaListItem";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { t } from "i18next";
import Modal from "../modal";
import FileUploadInput from "../forms/inputs/upload";
import EmailIcon from "@mui/icons-material/Email";
import Button from "../forms/inputs/button";
import { api } from "../../helper/api";
import GenericNotification from "../notification/genericNotification";
import GenericErrorMessageModal from "../forms/errorHandling/genericErrorMessageModal";
import styles from "./appointmentTreeItemUploadButton.module.css";

interface Props {
  appointment: any;
  useCase: "formOverview" | "documentWidget";
}

const AppointmentTreeItemUploadButton: FC<Props> = ({
  appointment,
  useCase,
}) => {
  const [subject, setSubject] = useState<any>();
  const [modalUploadOpen, setModalUploadOpen] = useState<boolean>(false);
  const [showNotification, setShowNotification] = useState<boolean>(false);
  const [notificationMessage, setNotificationMessage] = useState<any>("");
  const [notificationVariant, setNotificationVariant] = useState<
    "success" | "error" | "warning" | "info" | undefined
  >();
  const [error, setError] = useState<any>();

  const resetStateOnCloseNotification = () => {
    setShowNotification(false);
    setNotificationMessage("");
  };

  useEffect(() => {
    if (appointment) {
      setSubject(appointment?.subject);
    }
  }, []);

  let buttonTitle = "";

  //ohne function
  if (!subject?.is_registered) {
    buttonTitle = "Achtung! Proband ist noch nicht registriert!";
  }

  if (subject?.is_analog) {
    buttonTitle = "EVK fehlt! Bitte hochladen!";
  }

  const title = (
    <div className={styles.flexContainer}>
      <FileUploadIcon />
    </div>
  );

  const handleSendRegistrationEmail = (skipQuestion?: false | undefined) => {
    if (!skipQuestion) {
      if (
        /*eslint-disable */
        !confirm(
          t("send_registration_email_question", {
            email: subject?.email,
          })
        )
      ) {
        return;
      }
    }

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const additionalRouteParts: any = {};
    additionalRouteParts["send_user_registration_email"] = "/";

    api.genericApiRequest({
      entity: "user",
      method: "put",
      successHandler: (res: any) => {
        setNotificationMessage(t("successfully_sent"));
        setNotificationVariant("success");
        setShowNotification(true);
      },
      failHandler: (error: any) => {
        setError(error);
      },
      entityId: subject?.person?.user_id,
      additionalRouteParts: additionalRouteParts,
    });
  };

  return (
    <>
      {useCase === "formOverview" ? (
        <>
          {!subject?.is_registered && (
            <Grid item xs={12}>
              <GeneralListItem
                allowHover={true}
                onClickElement="wrapper"
                hideButton={true}
                wrapperClassName={styles.generalListItemWrapper__sendEmailBtn}
                onClick={() => handleSendRegistrationEmail(false)}
              >
                <Tooltip
                  title={t("send_registration_email")}
                  placement="top-end"
                >
                  <div className={styles.buttonContainer}>
                    <h6 className={styles.noMargin}>
                      {t("send_registration_email")}
                    </h6>
                    <EmailIcon />
                  </div>
                </Tooltip>
              </GeneralListItem>
            </Grid>
          )}
          <Grid item xs={12}>
            <GeneralListItem
              allowHover={true}
              onClickElement="wrapper"
              hideButton={true}
              wrapperClassName={styles.generalListItemWrapper__uploadBtn}
              onClick={() => setModalUploadOpen(true)}
            >
              <Tooltip title={t("upload")} placement="top-end">
                <div className={styles.buttonContainer}>
                  <h6 className={styles.noMargin}>{buttonTitle}</h6>
                  <FileUploadIcon />
                </div>
              </Tooltip>
            </GeneralListItem>
          </Grid>
        </>
      ) : (
        <Button
          title={title}
          variant="text"
          color="secondary"
          tooltipTitle={t("upload")}
          withTooltip={true}
          tooltipPlacement="top"
          className={styles.uploadButton}
          onClick={() => setModalUploadOpen(true)}
        />
      )}

      <Modal
        open={modalUploadOpen}
        onClose={() => setModalUploadOpen(false)}
        title={t("upload_file")}
        hideDialogActions={true}
      >
        <FileUploadInput
          multiple={true}
          subjectId={subject?.id}
          folderId={"11111111-1111-1111-1111-888888888888"}
          name="file"
          evkUpload={true}
          onCancel={() => {
            setModalUploadOpen(false);
          }}
          handleUploadSuccess={(modalOpen: boolean) => {
            if (modalOpen) {
              setModalUploadOpen(false);
            }
          }}
        />
      </Modal>
      {error && error !== "" && (
        <GenericErrorMessageModal
          title={t("error_occurred")}
          error={error}
          onClosehandler={() => {
            setError("");
          }}
        />
      )}
      {showNotification && (
        <GenericNotification
          message={notificationMessage}
          variant={notificationVariant}
          handleCloseSnackbar={resetStateOnCloseNotification}
        />
      )}
    </>
  );
};

export default AppointmentTreeItemUploadButton;
